export default {
  inject: ['canvas', 'fabric', 'event'],
  data() {
    return {
      mSelectMode: '', // one | multiple
      mSelectOneType: '', // i-text | group ...
      mSelectId: '', // 选择id
      mSelectIds: [], // 选择id
      previouslySelectedObjects: [], // 用于跟踪之前被选中的对象
    };
  },
  created() {
    this.event.on('selectOne', (e) => {
      this.mSelectMode = 'one';
      this.mSelectId = e[0].id;
      this.mSelectOneType = e[0].type;
      this.mSelectIds = e.map((item) => item.id);

      // 清除之前选中的对象的描边
      this._clearStroke(this.previouslySelectedObjects);
      // 应用新选中对象的描边
      this._applyStroke(e[0]);
      // 更新之前选中的对象列表
      this.previouslySelectedObjects = [e[0]];
    });

    this.event.on('selectMultiple', (e) => {
      this.mSelectMode = 'multiple';
      this.mSelectId = '';
      this.mSelectIds = e.map((item) => item.id);

      // 清除之前选中的对象的描边
      this._clearStroke(this.previouslySelectedObjects);
      // 应用新选中对象的描边
      e.forEach((item) => this._applyStroke(item));

      // 更新之前选中的对象列表
      this.previouslySelectedObjects = e;
    });

    this.event.on('selectCancel', () => {
      this.mSelectId = '';
      this.mSelectIds = [];
      this.mSelectMode = '';
      this.mSelectOneType = '';
      // 清除所有对象的描边
      this._clearStroke(this.previouslySelectedObjects);

      // 清空之前选中的对象列表
      this.previouslySelectedObjects = [];
    });
  },
  methods: {
    /**
     * @description: 为选中的对象添加描边
     * @param {Object} object 选中的对象
     */
    _applyStroke(object) {
      // 设置描边颜色和宽度
      if (object.fill !== null && object.name !== 'line') {
        object.set({
          stroke: 'blue', // 可以更改颜色
          strokeWidth: 1, // 可以更改宽度
        });
      }

      // 更新画布来渲染新的描边样式
      this.canvas.c.renderAll();
    },

    /**
     * @description: 清除指定对象的描边
     * @param {Array<Object>} objects 要清除描边的对象数组
     */
    _clearStroke(objects) {
      objects.forEach((object) => {
        if (object.fill !== null && object.name !== 'line') {
          object.set({
            stroke: null, // 清除描边
            strokeWidth: 0, // 清除描边宽度
          });
        }
      });

      // 更新画布来渲染更改
      this.canvas.c.renderAll();
    },
    /**
     * @description: 保存data数据
     * @param {Object} data 房间详情数据
     */
    _mixinSelected({ selected }) {
      if (selected.length === 1) {
        const selectItem = selected[0];
        this.mSelectMode = 'one';
        this.mSelectOneType = selectItem.type;
        this.mSelectId = [selectItem.id];
        this.mSelectActive = [selectItem];
      } else if (selected.length > 1) {
        this.mSelectMode = 'multiple';
        this.mSelectActive = selected;
        this.mSelectId = selected.map((item) => item.id);
      } else {
        this._mixinCancel();
      }
    },
    /**
     * @description: 保存data数据
     * @param {Object} data 房间详情数据
     */
    _mixinCancel() {
      this.mSelectMode = '';
      this.mSelectId = [];
      this.mSelectActive = [];
      this.mSelectOneType = '';
    },
    /**
     * @description: 复制到剪切板
     * @param {String} clipboardText 复制内容
     */
    _mixinClipboard(clipboardText) {
      this.$copyText(clipboardText).then(
        () => {
          this.$Message.success('复制成功');
        },
        () => {
          this.$Message.error('复制失败');
        }
      );
    },
  },
};
