const cnList = [
  {
    name: 'Arapey',
    fontFamily: 'Arapey',
  },
  {
    name: 'FallingSky',
    fontFamily: 'FallingSky',
  },
  {
    name: 'Arial',
    fontFamily: 'Arial',
  },
  {
    name: 'Cour',
    fontFamily: 'Cour',
  },
  {
    name: 'FamiliarPro',
    fontFamily: 'FamiliarPro',
  },
  {
    name: 'FoobarPro',
    fontFamily: 'FoobarPro',
  },
  {
    name: 'GaldienRounded',
    fontFamily: 'GaldienRounded',
  },
  {
    name: 'GeBody',
    fontFamily: 'GeBody',
  },
  {
    name: 'MilfordHollowr',
    fontFamily: 'MilfordHollowr',
  },
  {
    name: 'OrgreaveExtended',
    fontFamily: 'OrgreaveExtended',
  },
  {
    name: 'PathwayGothicOne',
    fontFamily: 'PathwayGothicOne',
  },
  {
    name: 'Swansea',
    fontFamily: 'Swansea',
  },
  {
    name: 'Trueno',
    fontFamily: 'Trueno',
  },
  {
    name: 'WarsawGothic',
    fontFamily: 'WarsawGothic',
  },
  {
    name: 'Yoxall8ey',
    fontFamily: 'Yoxall8ey',
  },
];

const enList = [];

export default [...cnList, ...enList];
