// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  bitDiameter: null,
};
const mutations = {
  setBitDiameter(aaa, sss) {
    aaa.bitDiameter = sss;
  },
};
const actions = {
  setBitDiameter({ commit }, diameter) {
    // const socket = new WebSocket(`ws://10.10.10.94:${port}/ws`);
    commit('setBitDiameter', diameter);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
