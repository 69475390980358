import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import settings from './modules/settings';
import showTab from './modules/showTab';
import webSocket from './modules/webSocket';
import material from './modules/material';
import materialBits from './modules/materialBits';
import machineSize from './modules/machineSize';
import bit from './modules/bit';
import progress from './modules/progress';
/* eslint-disable import/no-cycle */
import user from './modules/user';
/* eslint-disable import/no-cycle */
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    showTab,
    webSocket,
    material,
    materialBits,
    machineSize,
    bit,
    progress,
  },
  getters,
});

export default store;
