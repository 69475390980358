export default function formatSVG(svgString) {
  // Parse the SVG string into a DOM element
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
  const svgElement = svgDoc.documentElement;

  // Helper function to recursively format elements
  function formatElement(element, indentLevel = 0) {
    const indent = ' '.repeat(indentLevel * 2); // Indentation for each level
    let formattedString = `${indent}<${element.tagName}`;

    // Add attributes
    Array.from(element.attributes).forEach((attr) => {
      formattedString += ` ${attr.name}="${attr.value}"`;
    });

    // Handle child nodes
    if (element.childNodes.length === 0) {
      // Self-closing tag
      formattedString += ' />\n';
    } else {
      formattedString += '>\n';

      // Process child nodes
      Array.from(element.childNodes).forEach((child) => {
        if (child.nodeType === Node.ELEMENT_NODE) {
          // Recursive call for child elements
          formattedString += formatElement(child, indentLevel + 1);
        } else if (child.nodeType === Node.TEXT_NODE && child.textContent.trim() !== '') {
          // Preserve non-empty text content
          formattedString += `${indent}  ${child.textContent.trim()}\n`;
        }
      });

      // Closing tag
      formattedString += `${indent}</${element.tagName}>\n`;
    }

    return formattedString;
  }

  // Format the SVG element
  const formattedSVG = formatElement(svgElement);

  // Wrap with XML declaration and return
  return `<?xml version="1.0" encoding="UTF-8"?>\n${formattedSVG}`;
}
