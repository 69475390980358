<template>
  <div class="home">
    <Layout>
      <Userdialog
        :dialogVisible.sync="dialogVisible"
        @close-visible="closeVisable"
        @project-data="updateProject"
        class="child-element"
        style="z-index: 99999"
      ></Userdialog>

      <Header v-if="show" class="el-onheader">
        <div style="display: flex; align-items: center">
          <Questiondialog
            v-if="firstLogin"
            @update-first-login="handleFirstLoginChange"
            :show-tools="showTools"
          ></Questiondialog>
          <!-- logo -->
          <img
            src="@/assets/logo/logo-blank.png"
            alt="Logo"
            style="width: 55px"
            @click="imgClick"
          />
          <div style="font-size: 35px; margin: 0 20px">LaCraft(TBD)</div>
          <!-- 上方菜单 -->
          <div>
            <el-dropdown @command="handleCommand" trigger="click">
              <el-button
                id="step5"
                type="primary"
                @mouseover="changeBackgroundColor(true)"
                @mouseleave="changeBackgroundColor(false)"
                class="menu-button"
              >
                File
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="New">New</el-dropdown-item>
                <el-dropdown-item command="Open">Open</el-dropdown-item>
                <el-dropdown-item command="save">Save</el-dropdown-item>
                <el-dropdown-item command="Import">Import Project</el-dropdown-item>
                <el-dropdown-item command="Export">Export Project</el-dropdown-item>
                <!-- <el-dropdown-item command="Share">Share</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>
            <el-dropdown @command="handleCommand" trigger="click" id="step6">
              <el-button
                type="primary"
                @mouseover="changeBackgroundColor(true)"
                @mouseleave="changeBackgroundColor(false)"
                class="menu-button"
              >
                Setup
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="NewMaschine">New Machine</el-dropdown-item>
                <!-- <el-dropdown-item command="General Settings">General Settings</el-dropdown-item> -->
                <el-dropdown-item command="Driver">Download Driver</el-dropdown-item>
                <el-dropdown-item command="Config">Configuration</el-dropdown-item>
                <!-- <el-dropdown-item command="Marterials">Marterials</el-dropdown-item>
              <el-dropdown-item command="Cut Settings">Cut Settings</el-dropdown-item>
              <el-dropdown-item command="Machines">Machines</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div>
            <el-dropdown @command="handleCommand" trigger="click" id="step7">
              <el-button
                type="primary"
                @mouseover="changeBackgroundColor(true)"
                @mouseleave="changeBackgroundColor(false)"
                class="menu-button"
              >
                Help
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Learning">Learning</el-dropdown-item>
                <el-dropdown-item command="OnLineStore">Online Store</el-dropdown-item>
                <el-dropdown-item command="Youtube">Youtube</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 导入 -->
        <!-- <import-JSON></import-JSON>
        <Divider type="vertical" />
        <import-file></import-file>
        <Divider type="vertical" /> -->
        <!-- 颜色开关 -->
        <!-- <Tooltip :content="$t('grid')">
          <iSwitch v-model="ruler" size="small" class="switch"></iSwitch>
        </Tooltip> -->
        <!-- <Divider type="vertical" /> -->
        <!-- <history></history> -->

        <!-- <div style="float: right">
          <save></save>
          <lang></lang>
        </div> -->
        <div style="display: flex">
          <set-size @update:dept="deptssss" @setSizeLoaded="onSetSizeLoaded"></set-size>
          <!-- <div style="margin-left: 20px; display: flex">
            <div style="margin: 0 20px">
              <span>Connect:</span>
              <span>{{ join ? 'Yes' : 'No' }}</span>
            </div>
          </div> -->
          <!-- <div style="margin-right: 20px">
            <el-select v-model="selectValue" placeholder="Choose" class="select-com">
              <el-option
                v-for="item in comOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-button style="margin-left: 5px" @click="connectLaser" size="small">
              Connect
            </el-button>
          </div> -->
          <div>
            <!-- <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="right"> -->
            <!-- </el-tooltip> -->
            <el-button type="success" @click="startGuide" size="small" id="step1">
              Tutorial
            </el-button>
            <el-button type="success" @click="sendGcode" size="small">Preview</el-button>
            <el-button type="success" @click="carva" size="small">Craft</el-button>
          </div>
        </div>

        <!-- <setThreeSize></setThreeSize> -->
      </Header>
      <Content style="display: flex; height: calc(100vh - 64px)">
        <div style="width: 100%">
          <el-header class="Controls" height="70px" id="step3">
            <div style="width: 100%; margin: 0" class="full-height">
              <div class="col" style="width: 120px; border-left: 1px solid #ccc">
                <div class="bg-purple">Undo</div>
                <div class="half-height">
                  <Button
                    @click="undo"
                    type="text"
                    size="small"
                    :disabled="true"
                    style="padding-left: 0; height: 90%; width: 50%"
                  >
                    <Icon type="ios-undo" size="40" />
                  </Button>
                  <Button
                    @click="redo"
                    type="text"
                    size="small"
                    style="height: 90%; width: 50%"
                    :disabled="true"
                  >
                    <Icon type="ios-redo" size="40" />
                  </Button>
                </div>
              </div>
              <div class="col" style="width: 190px">
                <div class="half-height bg-purple">Position(mm)</div>
                <div class="half-height" style="display: flex">
                  <el-input
                    v-model="baseAttr.left"
                    style="margin-right: 10px"
                    @change="(value) => changeCommon('left', value)"
                    :disabled="isLock"
                  >
                    <span slot="prefix" class="el-input__icon">X</span>
                  </el-input>
                  <el-input
                    v-model="baseAttr.top"
                    @change="(value) => changeCommon('top', value)"
                    :disabled="isLock"
                  >
                    <span slot="prefix" class="el-input__icon">Y</span>
                  </el-input>
                </div>
              </div>
              <div class="col" style="width: 190px">
                <div class="half-height bg-purple">Size(mm)</div>
                <div class="half-height" style="display: flex">
                  <el-input
                    v-model="baseAttr.width"
                    style="margin-right: 10px"
                    @change="(value) => changeCommon('width', value)"
                    :disabled="isLock"
                  >
                    <span slot="prefix" class="el-input__icon">W</span>
                  </el-input>
                  <el-input
                    v-model="baseAttr.height"
                    @change="(value) => changeCommon('height', value)"
                    :disabled="isLock"
                  >
                    <span slot="prefix" class="el-input__icon">H</span>
                  </el-input>
                </div>
              </div>
              <div class="col" style="width: 150px">
                <div class="half-height bg-purple">Rotate</div>
                <div class="half-height" style="padding: 0 10px">
                  <el-slider
                    v-model="baseAttr.angle"
                    :max="360"
                    @input="(value) => changeCommon('angle', value)"
                    :disabled="isLock"
                  ></el-slider>
                </div>
              </div>
              <div class="col" style="width: 160px">
                <div class="half-height bg-purple">Radius(mm)</div>
                <div class="half-height" style="display: flex">
                  <el-input
                    v-model="baseAttr.rx"
                    style="margin-right: 10px"
                    :disabled="isRx || isLock"
                    @change="(value) => changeCommon('rx', value)"
                  >
                    <span slot="prefix" class="el-input__icon">X</span>
                  </el-input>
                  <el-input
                    v-model="baseAttr.ry"
                    @change="(value) => changeCommon('ry', value)"
                    :disabled="isRx || isLock"
                  >
                    <span slot="prefix" class="el-input__icon">Y</span>
                  </el-input>
                </div>
              </div>
              <div class="col" style="width: 60px">
                <div class="half-height bg-purple">Lock</div>
                <div class="half-height">
                  <Button
                    v-if="isLock"
                    @click="doLock(false)"
                    icon="md-lock"
                    type="text"
                    style="width: 100%; height: 90%"
                  ></Button>
                  <Button
                    v-else
                    @click="doLock(true)"
                    icon="md-unlock"
                    type="text"
                    style="width: 100%; height: 90%"
                  ></Button>
                </div>
              </div>
              <div class="col" style="width: 120px">
                <div class="half-height bg-purple">Mirror</div>
                <div class="half-height" style="display: flex">
                  <Button
                    @click="unpDownFlip"
                    type="text"
                    style="height: 90%; width: 50%; padding: 0"
                  >
                    <svg-icon icon-class="mirror2" />
                  </Button>
                  <Button @click="sideFilp" type="text" style="height: 90%; width: 50%; padding: 0">
                    <svg-icon icon-class="mirror1" />
                  </Button>
                </div>
              </div>
              <div class="col" style="width: 120px">
                <div class="half-height bg-purple">Group</div>
                <div class="half-height" style="display: flex">
                  <Button
                    @click="group"
                    type="text"
                    style="height: 90%; width: 50%; padding: 0"
                    :disabled="isgroup"
                  >
                    <svg-icon icon-class="group" />
                  </Button>
                  <Button
                    @click="ungroup"
                    type="text"
                    style="height: 90%; width: 50%; padding: 0"
                    :disabled="isungroup"
                  >
                    <svg-icon icon-class="ungroup" />
                  </Button>
                </div>
              </div>
              <div class="col" style="width: 60px">
                <div class="half-height bg-purple">Image</div>
                <Button
                  @click="handlePhoto"
                  type="text"
                  style="height: 66%; width: 100%; padding: 0; margin-top: 2px"
                  :disabled="isImage"
                >
                  <svg-icon icon-class="preview" />
                </Button>
              </div>
              <div class="col" style="width: 245px">
                <div class="half-height bg-purple">Material Dimensions(mm)</div>
                <div class="half-height" style="display: flex">
                  <el-input
                    v-model="baseAttr.materialX"
                    @change="(value) => changeCommon('x', value)"
                  >
                    <span slot="prefix" class="el-input__icon">X</span>
                  </el-input>
                  <el-input
                    v-model="baseAttr.materialY"
                    @change="(value) => changeCommon('y', value)"
                    style="margin: 0 10px"
                  >
                    <span slot="prefix" class="el-input__icon">Y</span>
                  </el-input>
                  <el-input
                    v-model="baseAttr.materialZ"
                    @change="(value) => changeCommon('z', value)"
                  >
                    <span slot="prefix" class="el-input__icon">Z</span>
                  </el-input>
                </div>
              </div>
              <div class="col" style="width: 90px">
                <div class="half-height bg-purple">CNC/Laser</div>
                <div style="height: 50px; text-align: center; line-height: 50px">
                  <el-switch
                    v-model="cncorLaser"
                    active-color="#13ce66"
                    inactive-color="#13ce66"
                    @change="cncorLaserChange"
                  ></el-switch>
                </div>
              </div>
            </div>
          </el-header>
          <div style="width: 100%; height: calc(100% - 70px); display: flex">
            <div style="width: 60px; position: relative; height: 100%">
              <tools
                ref="toolsComponent"
                :save-project="projectData"
                v-if="showTools"
                @canvas-url="getCanvasUrl"
                :update-dept="dept"
                :update-socket="getSocket"
                @open-three="openThree"
              ></tools>
            </div>
            <!-- 画布区域 -->
            <div
              id="workspace"
              style="position: relative; background: #f1f1f1; flex: 3; height: 100%"
            >
              <div class="canvas-box">
                <div class="inside-shadow"></div>
                <div
                  v-if="ruler"
                  class="coordinates-bar coordinates-bar-top"
                  style="width: 50%"
                ></div>
                <div
                  v-if="ruler"
                  class="coordinates-bar coordinates-bar-left"
                  style="height: 100%"
                ></div>
                <!-- class design-stage-point 点状  design-stage-grid 棋盘 -->
                <canvas id="canvas" :class="ruler ? 'design-stage-grid' : ''"></canvas>
                <zoom></zoom>
                <mouseMenu></mouseMenu>
              </div>

              <!-- <el-tabs type="border-card">
              <el-tab-pane label="Shape">
                <attribute v-if="show"></attribute>
                <set-size></set-size>
                <lock></lock>
              </el-tab-pane>
              <el-tab-pane label="Cut">Cut</el-tab-pane>
              <el-tab-pane label="Laser">
                <el-table :data="tableData" border style="width: 100%">
                  <el-table-column
                    prop="layer"
                    label="Layer"
                    width="60"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="mode"
                    label="Mode"
                    width="60"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="speed"
                    label="Speed/Power"
                    align="center"
                  ></el-table-column>
                </el-table>
                <div>
                  <ul>
                    <li>speed:5000m/s</li>
                    <li>Power:80%</li>
                    <li>Pass:1</li>
                    <li>Interval:0.1mm</li>
                  </ul>
                </div>
              </el-tab-pane>
            </el-tabs> -->
              <!-- <bg-bar></bg-bar> -->
              <!-- <group></group> -->
              <!-- <div class="attr-item"> -->
              <!-- <lock></lock> -->
              <!-- <dele></dele> -->
              <!-- <clone></clone> -->
              <!-- </div> -->
              <!-- 组对齐方式 -->
              <!-- <align></align> -->
              <!-- 居中对齐 -->
              <!-- <center-align></center-align> -->
              <!-- 翻转 -->
              <!-- <flip></flip> -->
              <div
                style="
                  /* padding: 10px; */
                  overflow-y: auto;
                  position: absolute;
                  z-index: 999;
                  left: 5px;
                  bottom: 5px;
                "
              >
                <layerssss></layerssss>
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-if="show" style="width: 380px; height: 100%; background: #fff; display: flex"> -->
        <!-- <Menu
            :active-name="menuActive"
            accordion
            @on-select="(activeIndex) => (menuActive = activeIndex)"
            width="65px"
          >
            <MenuItem :name="1" class="menu-item">
              <Icon type="md-book" size="24" />
              <div>{{ $t('templates') }}</div>
            </MenuItem>
            <MenuItem :name="2" class="menu-item">
              <Icon type="md-images" size="24" />
              <div>{{ $t('elements') }}</div>
            </MenuItem>
            <MenuItem :name="3" class="menu-item">
              <Icon type="md-reorder" size="24" />
              <div>{{ $t('layers') }}</div>
            </MenuItem>
          </Menu> -->
        <!-- <div class="content"> -->
        <!-- 生成模板 -->
        <!-- <div v-show="menuActive === 1" class="left-panel"> -->
        <!-- <import-tmpl></import-tmpl> -->
        <!-- </div> -->
        <!-- 常用元素 -->
        <!-- <div v-show="menuActive === 2" class="left-panel">
              <tools></tools>
              <svgEl></svgEl>
            </div> -->
        <!-- 背景设置 -->
        <!-- <div v-show="menuActive === 3" class="left-panel">
              <layer></layer>
            </div>
          </div>
        </div> -->

        <div id="three" style="width: 330px; position: relative">
          <Shape :update-dept="dept"></Shape>
          <!-- <div id="threejs-container" ref="threejsContainer"></div> -->
          <!-- <iframe
            src="https://ncviewer.com/"
            frameborder="0"
            width="100%"
            height="100%"
            title="Example Website Embed"
          ></iframe> -->
        </div>
        <!-- 属性区域 380-->
      </Content>
    </Layout>
    <el-dialog
      title="Start carving"
      :visible.sync="engraveDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="1000px"
      :modal="true"
      @open="handleDialogOpen"
    >
      <Engrave ref="engraveComponent" @start-carving="openThree"></Engrave>
    </el-dialog>

    <el-dialog
      :visible.sync="configDialog"
      :before-close="closeConfig"
      width="40%"
      :modal="true"
      class="config"
    >
      <Configdialog></Configdialog>
      <!-- @child-event="getMachineList" -->
    </el-dialog>
    <el-dialog title="Be carving" :visible.sync="progressDia" width="1000px" :modal="true">
      <!-- :before-close="closeprogress" -->

      <!-- 进度条 -->
      <div style="width: 150px; margin-left: 5px">
        <el-progress :percentage="progress"></el-progress>
      </div>
      <div style="width: 50px">
        <i
          class="el-icon-video-play"
          style="font-size: 25px; line-height: 60px"
          @click="resume"
          v-show="!showIcon"
        ></i>
        <i
          class="el-icon-video-pause"
          style="font-size: 25px; line-height: 60px"
          @click="pause"
          v-show="showIcon"
        ></i>
      </div>
      <div>
        <el-button type="primary" @click="cancelRun">Cancel</el-button>
      </div>
      <div>
        <span>Remaining</span>
        <span>{{ formattedTimeLeft }}</span>
      </div>
      <div>
        <span>Engrave</span>
        <span>{{ engTime }}</span>
      </div>
    </el-dialog>
    <!-- Gcode展示 -->
    <el-dialog
      title="GCode"
      :visible.sync="gcodeDia"
      :before-close="closeGcode"
      width="600px"
      :modal="true"
    >
      <textarea id="gcodePreview" rows="20" cols="50" readonly style="width: 100%"></textarea>
    </el-dialog>
    <el-drawer
      :visible.sync="drawer"
      :with-header="true"
      size="70%"
      :wrapperClosable="false"
      :show-close="true"
      :title="'File Name: ' + 'Project' + proName"
    >
      <!--       
      :show-close="true" -->
      <div>
        <Three :gcodeData="gcodeData" />
      </div>
    </el-drawer>

    <!-- 图片处理 -->
    <el-dialog
      title="Image processing"
      :visible.sync="showImage"
      width="60%"
      :before-close="closeImage"
      :modal="false"
    >
      <div class="dialog-content">
        <!-- 左边：SVG 展示区域 -->
        <div class="svg-display" id="imagePreview">
          <el-image
            :src="imageSrc"
            alt="Selected Image"
            fit="contain"
            style="width: 100%; height: 100%"
          />
        </div>
        <!-- v-if="imageSrc" -->
        <div class="svg-display" id="imagePreview" v-loading="imgLoading">
          <el-image
            :src="imageEffect"
            alt="Selected Image"
            fit="contain"
            style="width: 100%; height: 100%"
          />
        </div>
        <!-- 右边：控制面板 -->
        <div class="control-panel">
          <el-form label-width="145px">
            <el-form-item label="Interval">
              <el-input-number
                v-model="controls.interval"
                controls-position="right"
                :min="0.1"
                :max="10"
                :step="0.1"
                @blur="debounceMethod()"
                @change="debounceMethod()"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Smoothing">
              <el-select v-model="controls.effect" placeholder="请选择" @change="effectChange">
                <el-option
                  v-for="item in effectOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-button type="primary" @click="viewImage">View</el-button>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Download Driver"
      :visible.sync="showDriver"
      width="800px"
      :before-close="closeDriver"
      :modal="false"
    >
      <div style="display: flex; justify-content: space-around; align-items: center">
        <div>
          <div
            style="
              width: 200px;
              height: 56px;
              background-color: #cfcfcf;
              padding: 3px 10px;
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
          >
            <el-image
              style="width: 50px; height: 50px"
              :src="windowsImage"
              fit="fill"
              @click="windowsClick"
            ></el-image>
            <div style="font-size: 20px; width: 85px">Windows</div>
          </div>
          <div>Windows 7 (64-bit) or above</div>
        </div>
        <div>
          <div
            style="
              width: 200px;
              height: 56px;
              background-color: #cfcfcf;
              padding: 3px 10px;
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
          >
            <el-image
              style="width: 50px; height: 50px"
              :src="macImage"
              fit="fill"
              @click="macClick"
            ></el-image>
            <div style="font-size: 20px; width: 85px; text-align: center">Mac</div>
          </div>
          <div>MacOS 10.15 or above</div>
        </div>
      </div>
      <div style="font-size: 16px; margin: 50px 0 20px 0; text-align: center">
        <span>For more details and tutorials, please visit our</span>
        <a href="http://wiki.517788.xyz/software/driver" style="color: #2d8cf0" target="_blank">
          support page
        </a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { importPro, svgo, adjust, getadjusetGcode } from '@/api/import';
import { exportPro } from '@/api/export';
/* eslint-disable import/no-cycle */
import Driver from 'driver.js';
/* eslint-disable import/no-cycle */
import 'driver.js/dist/driver.min.css';
/* eslint-disable import/no-cycle */
import { selectFiles } from '@/utils/utils';
// import processArray from '@/utils/arrayProcessor';
// import flattenSVG from '@/utils/svgTransformer';
import formatSVG from '@/utils/changeSvg';
import { perMillimeter, millimeterToPx } from '@/utils/pixelConversion';
// import { v4 as uuid } from 'uuid';

// 导入元素
// import importJSON from '@/components/importJSON.vue';
// import importFile from '@/components/importFile.vue';

// 顶部组件
import Shape from '@/components/Shape.vue';
import layerssss from '@/components/layerssss.vue';
// import align from '@/components/align.vue';
// import centerAlign from '@/components/centerAlign.vue';
// import flip from '@/components/flip.vue';
// import save from '@/components/save.vue';
// import lang from '@/components/lang.vue';
// import clone from '@/components/clone.vue';
// import group from '@/components/group.vue';
import zoom from '@/components/zoom.vue';
// import lock from '@/components/lock.vue';
// import dele from '@/components/del.vue';

// 左侧组件
// import importTmpl from '@/components/importTmpl.vue';
import tools from '@/components/tools.vue';
// import svgEl from '@/components/svgEl.vue';
// import bgBar from '@/components/bgBar.vue';
import setSize from '@/components/setSize.vue';

// 右侧组件
// import history from '@/components/history.vue';
// import layer from '@/components/layer.vue';
// import attribute from '@/components/attribute.vue';

// 右键菜单
import mouseMenu from '@/components/contextMenu/index.vue';

// 功能组件
import EventHandle from '@/utils/eventHandler';
// import addTemplate from '@/core/addTemplate';

import { fabric } from 'fabric';
import * as THREE from 'three';
import Editor from '@/core';
// import setThreeSize from '@/components/setThreeSize.vue';

import Questiondialog from './components/Questiondialog/index.vue';
import Userdialog from './components/Userdialog/index.vue';
import Three from './components/Three/index.vue';
// 调试机器
import Engrave from './components/Engrave/index.vue';
// 材料刀头
import Configdialog from './components/Configdialog/index.vue';

const event = new EventHandle();
const canvas = {};
const renderer = {};
const allSocket = {};
export default {
  name: 'HomeView',
  provide: {
    canvas,
    fabric,
    event,
    renderer,
    THREE,
    allSocket,
  },
  data() {
    return {
      cncorLaser: true,
      driver: null,
      showDriver: false,
      windowsImage: require('@/assets/windows.png'),
      macImage: require('@/assets/macos.png'),
      isChange: 0,
      currentProcessingImage: null,
      processedImagesMap: new Map(),
      filename: null,
      imgLoading: true,
      controls: {
        width: 0,
        height: 0,
        interval: 1,
        effect: 'Ordered',
      },
      effectOptions: [
        {
          value: 'Ordered',
          label: 'Ordered',
        },
        {
          value: 'Atkinson',
          label: 'Atkinson',
        },
        {
          value: 'Dither',
          label: 'Dither',
        },
        {
          value: 'Newsprint',
          label: 'Newsprint',
        },
        {
          value: 'Halftone',
          label: 'Halftone',
        },
        {
          value: 'Sketch',
          label: 'Sketch',
        },
      ],
      imageSrc: null,
      imageEffect: null,
      showImage: false,
      gcodeData: null,
      drawer: false,
      isgroup: true,
      isungroup: true,
      isImage: true,
      undoStack: [], // 撤销栈
      redoStack: [],
      isRx: true,
      isLock: false,
      useSvgo: null,
      unCraft: '',
      gcodeDia: false,
      comOptions: [],
      // selectValue: '',
      canvasUrl: '',
      showTools: false,
      projectData: {},
      tableData: [
        {
          layer: 1,
          mode: 2,
          speed: 3,
        },
        {
          layer: 1,
          mode: 2,
          speed: 3,
        },
      ],
      menuActive: 1,
      show: false,
      select: null,
      ruler: false,
      dialogVisible: true,
      engraveDialog: false,
      configDialog: false,
      firstLogin: false,
      dept: 0,
      maschineName: null,
      maschineId: null,
      defaultMachine: {},
      join: false,
      progress: 0,
      showIcon: true,
      socket: null,
      action: null,
      connected: false,
      intervalId: null,
      remainingTime: null,
      engravingtime: null,
      progressDia: false,
      // 通用属性
      baseAttr: {
        opacity: 0,
        angle: 0,
        fill: '#fff',
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        rx: 0,
        ry: 0,
        strokeWidth: 0,
        strokeDashArray: [],
        stroke: '#fff',
        shadow: {
          color: '#fff',
          blur: 0,
          offsetX: 0,
          offsetY: 0,
        },
        clickActive: null,
        activeObject: null,
        materialX: 0,
        materialY: 0,
        materialZ: 0,
        proName: '',
      },
    };
  },
  watch: {
    deptssss(newVal) {
      // console.log(oldVal, newVal, '111111111111111');
      this.dept = newVal;
    },
  },
  computed: {
    // 监听 Vuex 中的 socket 状态
    getSocket() {
      // console.log(this.$store.state.webSocket.showLaser, '333333333333333333333333');
      return this.$store.state.webSocket.showLaser;
    },
    // 是否继续查找端口
    unFind() {
      // console.log(this.$store.state.webSocket.find, '333333333333333333333333');
      return this.$store.state.webSocket.find;
    },
    sendType() {
      return this.$store.state.webSocket.sendType;
    },
    formattedTimeLeft() {
      if (this.remainingTime) {
        const hours = Math.floor(this.remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor((this.remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((this.remainingTime % (1000 * 60)) / 1000);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
          seconds
        ).padStart(2, '0')}`;
      }
      return '00:00:00';
    },
    engTime() {
      if (this.engravingtime) {
        const hours = Math.floor(this.engravingtime / (1000 * 60 * 60));
        const minutes = Math.floor((this.engravingtime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((this.engravingtime % (1000 * 60)) / 1000);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
          seconds
        ).padStart(2, '0')}`;
      }
      return '00:00:00';
    },
    firBit() {
      return this.$store.state.materialBits.firBit;
    },
    secBit() {
      return this.$store.state.materialBits.secBit;
    },
    feedRate() {
      return this.$store.state.materialBits.feedRate;
    },
    zFeedRate() {
      return this.$store.state.materialBits.zFeedRate;
    },
    spindleSpeed() {
      return this.$store.state.materialBits.spindleSpeed;
    },
    depthPerPass() {
      return this.$store.state.materialBits.depthPerPass;
    },
    getGCode() {
      return this.$store.state.webSocket.gCode;
    },
    getmaschirWidth() {
      return this.$store.state.machineSize.maschirWidth;
    },
    getmaschirHeight() {
      return this.$store.state.machineSize.maschirHeight;
    },
    getWidth() {
      return this.$store.state.material.width;
    },
    getHeight() {
      return this.$store.state.material.height;
    },
    getDept() {
      return this.$store.state.material.dept;
    },
  },
  components: {
    setSize,
    // setThreeSize,
    tools,
    Shape,
    layerssss,
    // bgBar,
    // lock,
    // layer,
    // align,
    // attribute,
    // dele,
    // importFile,
    // save,
    // lang,
    // importJSON,
    // clone,
    // flip,
    // importTmpl,
    // centerAlign,
    // group,
    zoom,
    // svgEl,
    // history,
    mouseMenu,
    Questiondialog,
    Userdialog,
    Engrave,
    Configdialog,
    Three,
  },

  created() {
    this.$store.dispatch('webSocket/setSendType', '');
    // this.getMachineList();
    this.$Spin.show();
    this.connectToHardware();
    event.on('selectCancel', () => {
      this.isgroup = true;
      this.isungroup = true;
      this.isImage = true;
      this.baseAttr.fill = '';
      this.$forceUpdate();
    });
    event.on('selectOne', () => {
      this.bindEventListeners();
      const objects = canvas.c.getObjects();
      const workSpace = objects.find((item) => item.id === 'workspace');
      const [clickActive] = canvas.c.getActiveObjects();
      this.clickActive = clickActive;
      // this.color = this.clickActive.fill;
      this.sliderValue = this.clickActive.dept ? this.clickActive.dept : 0;
      const activeObject = canvas.c.getActiveObjects()[0];
      this.activeObject = activeObject;
      // console.log(activeObject._objects.length > 0, '222222222222222222222');
      if (activeObject.type === 'image') {
        this.isImage = false;
      }
      if (activeObject._objects && activeObject._objects.length > 0) {
        this.isungroup = false;
      } else {
        this.isungroup = true;
      }
      if (activeObject.zIndex === '00') {
        this.$store.dispatch('showTab/toggleShowLaser', 0);
      } else {
        this.$store.dispatch('showTab/toggleShowLaser', 1);
      }
      if (activeObject) {
        // base
        this.baseAttr.opacity = activeObject.get('opacity') * 100;
        this.baseAttr.fill = activeObject.get('fill');
        this.baseAttr.left = `${perMillimeter(
          Number(
            (
              activeObject.get('left') -
              workSpace.left -
              (activeObject.get('width') * activeObject.get('scaleX')) / 2
            ).toFixed(2)
          )
        ).toFixed(1)}`;
        this.baseAttr.top = `${perMillimeter(
          Number(
            (
              workSpace.height +
              workSpace.top -
              activeObject.get('top') -
              (activeObject.get('height') * activeObject.get('scaleY')) / 2
            ).toFixed(2)
          )
        ).toFixed(1)}`;
        this.baseAttr.width = `${perMillimeter(
          Number(activeObject.get('width') * activeObject.get('scaleX')).toFixed(2)
        ).toFixed(1)}`;
        this.baseAttr.height = `${perMillimeter(
          Number(activeObject.get('height') * activeObject.get('scaleY')).toFixed(2)
        ).toFixed(1)}`;
        this.baseAttr.stroke = activeObject.get('stroke');
        this.baseAttr.strokeWidth = activeObject.get('strokeWidth');
        this.baseAttr.shadow = activeObject.get('shadow') || {};
        this.baseAttr.angle = activeObject.get('angle') || 0;
        if (activeObject.type === 'rect') {
          this.baseAttr.rx = `${perMillimeter(Number(activeObject.get('rx').toFixed(2))).toFixed(
            1
          )}`;
          this.baseAttr.ry = `${perMillimeter(Number(activeObject.get('ry').toFixed(2))).toFixed(
            1
          )}`;
          this.isLock = activeObject.get('isLock');
          this.isRx = false;
        } else {
          this.isRx = true;
        }
        this.getColor();
        if (this.baseAttr.fill) {
          if (this.clickActive.zIndex === '00') {
            this.clickActive.set('fill', this.getColor());
          } else {
            this.clickActive.set('fill', this.baseAttr.fill);
          }
        } else {
          return null;
        }
        canvas.c.renderAll();
        // this.sliderInput();
        // 图片滤镜
        // if (activeObject.type === 'image') {
        //   this.imgAttr.blur = activeObject.filters[0] ? activeObject.filters[0].blur : 0;
        // }
      }
    });
    event.on('selectMultiple', () => {
      const ssss = canvas.c.getActiveObjects();
      ssss.forEach((item) => {
        if (item.type === 'image') {
          this.isgroup = true;
        } else {
          this.isgroup = false;
        }
      });
      console.log(ssss, 'ssssssssss');
    });
    // canvas.on('object:added', () => {
    //   console.log('11122');
    // });
    // canvas.c.canvas.on('object:modified', () => {
    //   console.log('22233');
    // });
    // canvas.c.canvas.on('object:removed', () => {
    //   console.log('33344');
    // });
  },
  mounted() {
    this.proName = localStorage.getItem('canvasID');
    // this.getMaterSize();
    this.canvas = new fabric.Canvas('canvas', {
      fireRightClick: true, // 启用右键，button的数字为3
      stopContextMenu: true, // 禁止默认右键菜单
      controlsAboveOverlay: true, // 超出clipPath后仍然展示控制条
    });
    canvas.c = this.canvas;
    // canvas.d = canvas.c.d;
    event.init(canvas.c);
    canvas.editor = new Editor(canvas.c);
    canvas.c.renderAll();
    this.show = true;
    this.$Spin.hide();
    this.initDriver();
    if (localStorage.getItem('firstLogin') === 'true') {
      this.$nextTick(() => {
        setTimeout(() => {
          this.startGuide();
        }, 500); // 延迟 500ms
      });
    }

    // this.startGuide();
    // 3D
    // const container = this.$refs.threejsContainer;
    // 设置 Three.js 渲染器尺寸
    // container.style.width = '100%';
    // container.style.height = '100%';
    // this.canvas.on('after:render', () => {
    //   // 注意：此事件可能会频繁触发，请根据需求使用
    //   this.logAllObjects();
    // });
    // // console.log(container.clientHeight, 'container.clientHeight');
  },
  beforeDestroy() {
    // 清除定时器
    if (this.timerId) {
      clearInterval(this.timerId);
    }
    // 关闭WebSocket连接
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close(1000, 'Component is being destroyed');
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    cncorLaserChange() {
      this.$store.dispatch('webSocket/setCncLaser', this.cncorLaser);
    },
    windowsClick() {
      window.location.href = 'https://lacraft.oss-us-west-1.aliyuncs.com/system/driver/Driver.zip';
    },
    macClick() {
      window.location.href = 'https://lacraft.oss-us-west-1.aliyuncs.com/system/driver/Driver.zip';
    },
    openThree() {
      console.log('11221');
      this.engraveDialog = false;
      this.drawer = true;
    },
    handleDialogOpen() {
      console.log('雕刻弹窗已打开，刷新数据');
      // 使用 $nextTick 确保子组件已经渲染完成
      this.$nextTick(() => {
        if (this.$refs.engraveComponent) {
          this.$refs.engraveComponent.refreshData();
        } else {
          console.warn('找不到 Engrave 组件引用');
        }
      });
    },
    onSetSizeLoaded() {
      // setSize 组件加载完成后执行 getMaterSize
      this.getMaterSize();
    },
    // 获取默认材料大小
    getMaterSize() {
      console.log(this.getWidth, 'this.getWidththis.getWidth');

      this.baseAttr.materialX = this.getWidth;
      this.baseAttr.materialY = this.getHeight;
      this.baseAttr.materialZ = this.getDept;
    },
    getColor() {
      // console.log('getColorgetColorgetColorgetColor');

      // 计算颜色深度
      if (this.clickActive.fill === null && this.sliderValue === this.maxDept) {
        this.$store.dispatch('showTab/showAddTabs', true);
        // this.addBreakpoints();
      } else {
        this.$store.dispatch('showTab/showAddTabs', false);
      }
      const color =
        this.clickActive.fill === null ? this.clickActive.stroke : this.clickActive.fill;
      // const grayValue = Math.round((this.maxDept - this.sliderValue) * 2.55);
      if (this.clickActive.zIndex === '00') {
        const a = 255 / this.maxDept;
        const grayValue = (this.maxDept - this.sliderValue) * a;

        // 计算颜色深度
        // const grayValue = 100 - mappedValue;
        return `rgb(${grayValue}, ${grayValue}, ${grayValue})`;
      }
      if (
        this.clickActive.stroke === null ||
        this.clickActive.stroke === 'blue' ||
        this.clickActive.strokeWidth === 1
      ) {
        // console.log(2);
        // console.log(this.clickActive.fill, 'this.clickActive.fill');

        return color;
      }
    },
    // 反转
    flip(type) {
      const activeObject = canvas.c.getActiveObject();
      activeObject.set(`flip${type}`, !activeObject[`flip${type}`]).setCoords();
      canvas.c.requestRenderAll();
    },
    deptssss(e) {
      this.dept = e;
      // console.log(e, 'eeeeeeeeeee');
    },
    // logAllObjects() {
    //   const objects = this.canvas.getObjects();
    //   const filteredObjects = objects.filter(
    //     (obj) => obj.id !== 'workspace' && (!obj.fill || obj.fill !== '#F1F1F1')
    //   );
    //   // console.log('Current objects on canvas:', filteredObjects);
    //   // 如果你需要对每个对象进行更详细的处理，可以遍历 objects 数组
    //   // objects.forEach((obj) => {
    //   //   // console.log('ssssssssssssssssssssss', obj);
    //   //   // 在这里可以对每个对象执行特定的操作
    //   // });
    // },
    getCanvasUrl(canvasUrl) {
      this.canvasUrl = canvasUrl;
    },
    // 双击project获取project数据
    updateProject(newData) {
      this.projectData = newData;
    },
    closeVisable() {
      this.dialogVisible = false;
      this.showTools = true;
    },
    imgClick() {
      this.showTools = false;
      this.dialogVisible = true;
    },
    handleFirstLoginChange(value) {
      this.firstLogin = value;
      // 更新localStorage
      localStorage.setItem('firstLogin', this.firstLogin);
    },
    changeBackgroundColor(isHovered) {
      if (isHovered) {
        this.buttonColor = '#3a8ee6'; // 鼠标悬停时的背景色
      } else {
        this.buttonColor = '#409EFF'; // 默认背景色
      }
    },
    handleCommand(command) {
      if (command === 'New') {
        this.$confirm(
          'Would you like to open a new one without save current project?',
          'Create a new canvas',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
        )
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Created successfully',
            });
            const objects = this.canvas.getObjects();
            objects.forEach((obj) => {
              if (obj.id !== 'workspace' && obj.id !== 'photo' && obj.id !== 'coordinate') {
                this.canvas.remove(obj);
              }
            });
            this.canvas.renderAll();
            localStorage.setItem('canvasID', null);
            this.$emit('close-visible');
            const project = {
              id: 0,
            };
            this.updateProject(project);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: 'Cancelled',
            });
          });
      } else if (command === 'Open') {
        this.imgClick();
      } else if (command === 'Import') {
        selectFiles({ accept: '.lac', multiple: true }).then((fileList) => {
          if (fileList && fileList.length > 0) {
            // const promises = Array.fom(fileList).map((file) => );
            const data = fileList[0];
            const formData = new FormData();
            formData.append('file', data);
            // console.log(fileList, 'fileList');
            // console.log(fileList[0].name, 'fileList');
            importPro(formData).then((res) => {
              const trueSvg = res.data.replace(/^[^<]+/, '').trim();

              // 解析SVG文档
              const parser = new DOMParser();
              const svgDoc = parser.parseFromString(trueSvg, 'image/svg+xml');

              // 直接从SVG中提取所有图形元素及其desc数据
              const elementsWithDesc = [];

              // 查找所有可能的图形元素
              const graphicElements = svgDoc.querySelectorAll(
                'circle, path, rect, polygon, polyline, ellipse'
              );

              graphicElements.forEach((element) => {
                const descElement = element.querySelector('desc');
                if (descElement) {
                  try {
                    const descContent = descElement.textContent.trim();
                    const descData = JSON.parse(descContent);

                    // 获取元素的变换信息
                    const transform = element.getAttribute('transform');
                    let tx = 0;
                    let ty = 0;

                    if (transform && transform.includes('translate')) {
                      const matches = transform.match(/translate\(([^)]+)\)/);
                      if (matches && matches[1]) {
                        [tx, ty] = matches[1].split(/\s+|,/).map(parseFloat);
                      }
                    }

                    elementsWithDesc.push({
                      element,
                      descData,
                      transform: { tx, ty },
                      tagName: element.tagName.toLowerCase(),
                    });
                  } catch (e) {
                    console.error('解析desc数据失败:', e);
                  }
                }
              });

              // 加载SVG到Fabric
              fabric.loadSVGFromString(trueSvg, (objects) => {
                const filteredObjects = objects.filter(
                  (obj) => obj.id !== 'workspace' && (!obj.fill || obj.fill !== '#F1F1F1')
                );

                // 为每个Fabric对象匹配正确的desc数据
                filteredObjects.forEach((obj, index) => {
                  // 尝试找到匹配的元素
                  let matchedElement = null;

                  // 使用索引直接匹配（假设顺序一致）
                  if (index < elementsWithDesc.length) {
                    matchedElement = elementsWithDesc[index];
                  }

                  obj.desc = matchedElement.descData;

                  // 设置其他属性
                  if (matchedElement.descData.targetDepth !== undefined)
                    obj.targetDepth = matchedElement.descData.targetDepth;
                  if (matchedElement.descData.startDepth !== undefined)
                    obj.startDepth = matchedElement.descData.startDepth;
                  if (matchedElement.descData.zIndex !== undefined)
                    obj.zIndex = matchedElement.descData.zIndex;
                  if (matchedElement.descData.cutType !== undefined)
                    obj.cutType = matchedElement.descData.cutType;

                  // 保存原始位置
                  const originalLeft = matchedElement.descData.left;
                  const originalTop = matchedElement.descData.top;

                  // 设置中心原点
                  obj.set('originX', 'center');
                  obj.set('originY', 'center');
                  obj.set('zIndex', matchedElement.descData.zIndex);
                  obj.set('dept', matchedElement.descData.targetDepth);
                  obj.set('power', matchedElement.descData.feedRate);
                  obj.set('speed', matchedElement.descData.spindleSpeed);
                  obj.set('interval', matchedElement.descData.interval);
                  obj.set('pass', matchedElement.descData.passes);
                  obj.set('cutType', matchedElement.descData.cutType);
                  // 根据原点变化调整位置
                  console.log(obj, 'objobjobjobjobjobjobj');

                  if (obj.width && obj.height) {
                    obj.set({
                      left: originalLeft,
                      top: originalTop,
                    });
                  }

                  this.canvas.add(obj);
                });

                this.canvas.renderAll();
              });
            });
          } else {
            // console.log('没有选择文件');
          }
        });
      } else if (command === 'Export') {
        this.exportProject();
      } else if (command === 'save') {
        this.$message.success('Save Success');
        localStorage.setItem('double', false);
        this.$refs.toolsComponent.init();
        console.log('save');
      } else if (command === 'NewMaschine') {
        this.$router.push({ path: '/newmaschine' });
        // console.log(11);
      } else if (command === 'Driver') {
        this.showDriver = true;
      } else if (command === 'Config') {
        this.configDialog = true;
        // console.log(111);
      } else if (command === 'Learning') {
        window.open('http://wiki.517788.xyz');
      } else if (command === 'OnLineStore') {
        window.open('https://www.lunyeecnc.com/');
      } else if (command === 'Youtube') {
        window.open('https://www.youtube.com/@lunyeeofficial');
      }
      // console.log(`选择了: ${command}`);
    },
    downFile(fileStr, fileType) {
      const anchorEl = document.createElement('a');
      anchorEl.href = fileStr;
      anchorEl.download = `${fileType}`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    },

    // 获取GCode
    async sendGcode() {
      this.$store.dispatch('webSocket/setSendType', 'SVG');
      const This = this;
      // / 初始化处理图像的映射（如果不存在）
      if (!this.processedImagesMap) {
        this.processedImagesMap = new Map();
      }

      // 获取画布上所有的图片对象
      const imageObjects = canvas.c.getObjects().filter((obj) => obj.type === 'image');
      const unprocessedImages = imageObjects.filter(
        (img) =>
          !this.processedImagesMap.has(img.id) ||
          (this.currentProcessingImage === img && this.imageEffect)
      );
      // 如果有未处理过的图片，先处理这些图片
      if (unprocessedImages.length > 0) {
        this.imgLoading = true;

        // 处理所有未处理过的图片
        try {
          await Promise.all(
            unprocessedImages.map(async (imageObj) => {
              // 保存原始图像的位置和尺寸信息
              const originalProps = {
                left: imageObj.left,
                top: imageObj.top,
                width: imageObj.width,
                height: imageObj.height,
                scaleX: imageObj.scaleX,
                scaleY: imageObj.scaleY,
                angle: imageObj.angle,
                zIndex: imageObj.zIndex,
                clipPath: imageObj.clipPath,
                // 保存其他可能需要的属性
              };

              // 如果是当前正在处理的图片且已有处理结果，直接使用
              if (this.currentProcessingImage === imageObj && this.imageEffect) {
                this.processedImagesMap.set(imageObj.id, {
                  originalObject: imageObj,
                  processedImageUrl: this.imageEffect,
                  originalProps, // 保存原始属性
                });
                return;
              }

              // 获取图片数据
              const imgElement = imageObj._element;
              const base64Data = imgElement.src;
              const byteString = atob(base64Data.split(',')[1]);
              const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
              const ab = new ArrayBuffer(byteString.length);
              const ia = new Uint8Array(ab);
              for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }
              const blob = new Blob([ab], { type: mimeString });
              const file = new File([blob], 'image.jpg', { type: mimeString });

              // 创建 FormData 对象并添加所需参数
              const formData = new FormData();
              formData.append('file', file);
              formData.append('width', perMillimeter(imageObj.width * imageObj.scaleX));
              formData.append('height', perMillimeter(imageObj.height * imageObj.scaleY));
              formData.append('effect', 'Ordered'); // 根据实际情况设置效果
              formData.append('interval', imageObj.interval);
              formData.append('angle', imageObj.angle);

              // 调用 adjust 接口
              const res = await adjust(formData);
              const blobs = new Blob([res.data], { type: 'image/jpeg' });
              const imageUrl = URL.createObjectURL(blobs);

              // 将处理后的图像保存到映射中
              this.processedImagesMap.set(imageObj.id, {
                originalObject: imageObj,
                processedImageUrl: imageUrl,
                originalProps, // 保存原始属性
              });
            })
          );
        } catch (error) {
          console.error('处理图像时出错:', error);
          this.$message.error('处理图像失败，请重试');
          this.imgLoading = false;
          return;
        }

        this.imgLoading = false;
      }
      const tempCanvas = new fabric.StaticCanvas(null, {
        width: canvas.c.width,
        height: canvas.c.height,
        // backgroundColor: canvas.c.backgroundColor,
      });

      // 2. 深度克隆函数（支持嵌套 Group）
      const deepClone = async (sourceObj) => {
        return new Promise((resolve) => {
          // 创建属性白名单（必须包含所有自定义属性）
          const customProps = [
            'zIndex',
            'speed',
            'power',
            'pass',
            'interval',
            'dept',
            'cutType',
            'src',
          ];

          // 执行克隆并携带白名单
          sourceObj.clone(async (clonedObj) => {
            if (clonedObj.type === 'path') {
              if (clonedObj.stroke === 'blue') {
                clonedObj.set({
                  stroke: null, // 关键修改：清除边框颜色
                  strokeWidth: 0,
                });
              }
              // 获取路径数据
              let pathData = clonedObj.path;

              // 检查并清理路径数据中的 NaN 值
              if (pathData && Array.isArray(pathData)) {
                // 过滤掉包含 NaN 的路径命令
                pathData = pathData.filter((cmd) => {
                  // 检查命令数组中是否有 NaN
                  if (Array.isArray(cmd)) {
                    return !cmd.some(
                      (val) => (typeof val === 'number' && Number.isNaN(val)) || val === 'NaN'
                    );
                  }
                  return true;
                });

                // 更新对象的路径数据
                clonedObj.path = pathData;
              }
            }
            // 1. 同步动态属性（必须前置）
            if (clonedObj.type === 'image') {
              const processedImageInfo = This.processedImagesMap.get(sourceObj.id);

              if (processedImageInfo && processedImageInfo.processedImageUrl) {
                // 使用处理后的图像
                try {
                  const img = new Image();
                  img.crossOrigin = 'Anonymous';

                  await new Promise((imgResolve) => {
                    img.onload = function () {
                      const tempImgCanvas = document.createElement('canvas');
                      tempImgCanvas.width = img.width;
                      tempImgCanvas.height = img.height;
                      const ctx = tempImgCanvas.getContext('2d');
                      ctx.drawImage(img, 0, 0);

                      const dataUrl = tempImgCanvas.toDataURL('image/png');
                      const { originalProps } = processedImageInfo;
                      clonedObj.setSrc(dataUrl, () => {
                        if (clonedObj.stroke === 'blue') {
                          clonedObj.set({
                            stroke: null, // 关键修改：清除边框颜色
                            strokeWidth: 0,
                          });
                        }
                        clonedObj.set({
                          left: originalProps.left,
                          top: originalProps.top,
                          angle: originalProps.angle,
                          // 计算正确的缩放比例，确保尺寸一致
                          scaleX: (originalProps.width * originalProps.scaleX) / clonedObj.width,
                          scaleY: (originalProps.height * originalProps.scaleY) / clonedObj.height,
                        });
                        if (originalProps.clipPath) {
                          clonedObj.clipPath = originalProps.clipPath;
                        }
                        imgResolve();
                      });
                    };

                    img.onerror = function () {
                      console.error('处理后的图像加载失败');
                      imgResolve();
                    };

                    // 使用处理后的图像 URL
                    img.src = processedImageInfo.processedImageUrl;
                  });

                  resolve(clonedObj);
                  return; // 提前返回
                } catch (error) {
                  console.error('处理图像时出错:', error);
                  // 如果出错，继续使用原始图像
                }
              }

              // 原始图像处理逻辑
              try {
                const imgElement = sourceObj._element;
                if (imgElement && imgElement.complete) {
                  const canvass = document.createElement('canvas');
                  canvass.width = imgElement.naturalWidth || imgElement.width;
                  canvass.height = imgElement.naturalHeight || imgElement.height;
                  const ctx = canvass.getContext('2d');
                  ctx.drawImage(imgElement, 0, 0);

                  // 设置内联数据URL
                  const dataUrl = canvass.toDataURL('image/png');
                  clonedObj.setSrc(dataUrl, () => {
                    // 同步基础属性
                    clonedObj.set({
                      left: sourceObj.left,
                      top: sourceObj.top,
                      angle: sourceObj.angle,
                      scaleX: sourceObj.scaleX,
                      scaleY: sourceObj.scaleY,
                    });
                    resolve(clonedObj);
                  });
                  return; // 提前返回
                }
              } catch (e) {
                console.error('图片处理失败:', e);
              }
            }
            // 2. 处理嵌套对象（注意使用await保证顺序）
            if (clonedObj.type === 'group' && clonedObj._objects) {
              clonedObj._objects = await Promise.all(
                clonedObj._objects.map((child) => deepClone(child))
              );
            }

            // 3. 同步基础属性
            clonedObj.set({
              left: sourceObj.left,
              top: sourceObj.top,
              angle: sourceObj.angle,
              scaleX: sourceObj.scaleX,
              scaleY: sourceObj.scaleY,
            });

            resolve(clonedObj);
          }, customProps); // 关键点：传入白名单
        });
      };

      // 3. 克隆原始对象到临时画布
      const originalObjects = canvas.c.getObjects();
      let filterocjects = null;
      if (this.cncorLaser) {
        filterocjects = originalObjects.filter((obj) => obj.zIndex !== '00');
      } else {
        filterocjects = originalObjects.filter((obj) => obj.zIndex === '00');
      }
      console.log(filterocjects, 'filterocjectsfilterocjects');
      const clonedObjects = await Promise.all(
        originalObjects
          .filter(
            (obj) =>
              obj.id !== 'workspace' &&
              obj.id !== 'coordinate' &&
              obj.id !== 'photo' &&
              !(obj instanceof fabric.Rect && obj.fill?.source instanceof HTMLCanvasElement)
          )
          .map((obj) => deepClone(obj))
      );

      tempCanvas.add(...clonedObjects);
      tempCanvas.renderAll();

      // 4. 拆解 Group 的递归函数
      // 4. 拆解 Group 的递归函数
      const deepUngroup = (canvass) => {
        const processGroup = (group) => {
          const groupTransform = group.calcTransformMatrix();
          group._objects.forEach((child) => {
            // 保存原始的翻转状态
            const originalFlipX = child.flipX || group.flipX || false;
            const originalFlipY = child.flipY || group.flipY || false;

            const childTransform = child.calcTransformMatrix();
            const finalTransform = fabric.util.multiplyTransformMatrices(
              groupTransform,
              childTransform
            );
            const options = fabric.util.qrDecompose(finalTransform);

            // 正确处理缩放和翻转
            child.set({
              left: options.translateX,
              top: options.translateY,
              angle: options.angle,
              scaleX: Math.abs(options.scaleX) * (originalFlipX ? -1 : 1),
              scaleY: Math.abs(options.scaleY) * (originalFlipY ? -1 : 1),
              skewX: options.skewX,
              skewY: options.skewY,
              flipX: originalFlipX,
              flipY: originalFlipY,
              group: null,
              originX: 'center',
              originY: 'center',
            });

            child.setCoords();
          });

          canvass.remove(group);
          canvass.add(...group._objects);
        };

        let groups;
        do {
          groups = canvass.getObjects().filter((obj) => obj.type === 'group');
          groups.forEach(processGroup);
        } while (groups.length > 0);
      };

      // 5. 在临时画布执行拆解
      deepUngroup(tempCanvas);
      // 6. 注入 SVG 元数据
      // ... existing code ...
      tempCanvas.forEachObject((obj) => {
        if (obj.type === 'image') {
          const originalToSVG = obj.toSVG;
          obj.toSVG = function (reviver) {
            let svgString = originalToSVG.call(this, reviver);

            // 修复命名空间问题
            svgString = svgString.replace(
              /<image\s+/,
              '<image xmlns:xlink="http://www.w3.org/1999/xlink" '
            );

            return svgString;
          };
        }
        const originalToSVG = obj.toSVG;

        obj.toSVG = (reviver) => {
          let svgString = originalToSVG.call(obj, reviver);

          try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svgString, 'image/svg+xml');

            // 查找具体的图形元素
            const elements = doc.querySelectorAll(
              'rect, circle, ellipse, line, polyline, polygon, path,image'
            );

            elements.forEach((element) => {
              // 根据zIndex构建不同的desc内容
              let descData = {};
              if (obj.zIndex !== '00') {
                descData = {
                  feedRate: obj.speed,
                  spindleSpeed: obj.power,
                  passes: obj.pass,
                  interval: obj.interval,
                  zIndex: obj.zIndex,
                  cutType: obj.cutType,
                };
              } else {
                descData = {
                  targetDepth: obj.dept,
                  startDepth: 0,
                  zIndex: obj.zIndex,
                  cutType: obj.cutType,
                };
              }
              // 创建desc元素
              const desc = doc.createElementNS('http://www.w3.org/2000/svg', 'desc');
              // desc.setAttribute('data-type', 'fabric-custom');
              desc.textContent = JSON.stringify(descData);

              // 插入到图形元素内
              element.appendChild(desc);
            });

            // 序列化并清理输出
            svgString = new XMLSerializer()
              .serializeToString(doc.documentElement)
              .replace(/\n/g, '')
              .replace(/\/>/g, '/>');
          } catch (error) {
            console.error('SVG 增强失败:', error);
          }

          return svgString;
        };
      });

      const mar = originalObjects.find((item) => item.id === 'workspace');
      const dataUrl = tempCanvas.toSVG();
      console.log(dataUrl, 'dataUrldataUrldataUrl');
      // const newSvgUrl = flattenSVG(dataUrl);
      const newblob = new Blob([dataUrl], { type: 'image/svg+xml' });
      // 基于 Blob 创建一个 File 对象
      const svgFile = new File([newblob], `Project${localStorage.getItem('canvasID')}.svg`, {
        type: 'image/svg+xml',
      });
      const formDatas = new FormData();
      formDatas.append('file', svgFile, `Project${localStorage.getItem('canvasID')}.svg`);
      // console.log(formDatas, 'formDatasformDatas');
      svgo(formDatas).then((res) => {
        // console.log(res.data, 'res.datares.data');

        const svgString = res.data;
        console.log(svgString, 'svgStringsvgString');

        this.useSvgo = formatSVG(svgString);
        const newSvg = `${mar.left},${mar.top}\n${this.useSvgo}`;
        // console.log(newSvg, 'dataUrldataUrl');
        const blob = new Blob([newSvg], { type: 'image/svg+xml' });
        // 基于 Blob 创建一个 File 对象
        this.svgFile = new File([blob], `Project${localStorage.getItem('canvasID')}.svg`, {
          type: 'image/svg+xml',
        });
        // console.log(this.svgFile, 'this.svgFilethis.svgFile');
        // this.uploadToServer(this.svgFile);
        // const formData = new FormData();
        // formData.append('file', this.svgFile, `项目${localStorage.getItem('canvasID')}.svg`);
        const reader = new FileReader();
        // console.log(reader, 'readerreaderreader');
        reader.onload = function (e) {
          const originalArrayBuffer = e.target.result;

          const originalUint8Array = new Uint8Array(originalArrayBuffer);
          console.log(
            originalUint8Array,
            '.byteLength.byteLength.byteLength.byteLength.byteLength'
          );
          // // 创建一个新的 Uint8Array，长度是原始的 + 1 字节
          const CHUNK_SIZE = 32 * 1024; // 32KB 每片
          if (originalUint8Array.byteLength < CHUNK_SIZE) {
            const a = {
              firstToolDiameter: This.firBit,
              secondToolDiameter: This.secBit,
              feedRate: This.feedRate,
              zFeedRate: This.zFeedRate,
              safeHeight: '3.8',
              toolStepOver: '0.3',
              depthPerPass: This.depthPerPass,
              spindleSpeed: This.spindleSpeed,
            };
            const jsonString = JSON.stringify(a);
            const encoder = new TextEncoder();
            const uint8Array = encoder.encode(jsonString);
            const arraylength = uint8Array.length;
            const newArray = new Uint8Array(originalUint8Array.length + 3 + arraylength);
            // // 设置第一个字节为 1
            if (arraylength <= 127) {
              newArray[0] = 0;
              newArray[1] = 0;
              newArray[2] = arraylength;
            } else {
              // const firstDigit = parseInt(num.toString()[0], 10)
              // const lastTwoDigits = parseInt(num.toString().slice(-2), 10);
              newArray[0] = 0;
              newArray[1] = arraylength - 127;
              newArray[2] = 127;
            }
            newArray.set(uint8Array, 3);

            // // 将原始的 Uint8Array 数据复制到新的 Uint8Array 中，从第二个字节开始
            newArray.set(originalUint8Array, 3 + arraylength);
            console.log(newArray, 'newArraynewArraynewArray');

            This.getSocket.send(newArray.buffer);
          } else {
            const buffers = originalUint8Array.buffer;
            console.log(buffers, 'buffersbuffers');
            const date = new Date();
            const totalChunks = Math.ceil(buffers.byteLength / CHUNK_SIZE);
            let currentChunk = 0;
            // 使用递归函数代替循环和await
            const sendNextChunk = () => {
              const a = {
                fileId: date,
                fileName: date,
                chunkIndex: currentChunk,
                totalChunks,
                isFirst: currentChunk === 0,
                isLast: currentChunk === totalChunks - 1,
                toolConfig: {
                  firstToolDiameter: This.firBit,
                  secondToolDiameter: This.secBit,
                  feedRate: This.feedRate,
                  zFeedRate: This.zFeedRate,
                  safeHeight: '3.8',
                  toolStepOver: '0.3',
                  depthPerPass: This.depthPerPass,
                  spindleSpeed: This.spindleSpeed,
                },
              };
              const jsonString = JSON.stringify(a);
              console.log(jsonString, 'jsonStringjsonString');

              const encoder = new TextEncoder();
              const uint8Array = encoder.encode(jsonString);
              const arraylength = uint8Array.length;
              if (currentChunk >= totalChunks) {
                console.log('文件传输完成');
                // this.$message.success('文件传输完成');
                return;
              }

              const start = currentChunk * CHUNK_SIZE;
              const end = Math.min(start + CHUNK_SIZE, buffers.byteLength);
              const chunks = buffers.slice(start, end);
              const chunk = new Uint8Array(chunks);
              const newchunk = new Uint8Array(chunk.length + 5 + arraylength);

              if (arraylength <= 127) {
                newchunk[0] = 2;
                newchunk[1] = 0;
                newchunk[2] = 0;
                newchunk[3] = 0;
                newchunk[4] = arraylength;
              } else if (arraylength <= 254) {
                newchunk[0] = 2;
                newchunk[1] = 0;
                newchunk[2] = 0;
                newchunk[3] = arraylength - 127;
                newchunk[4] = 127;
              } else if (arraylength <= 381) {
                newchunk[0] = 2;
                newchunk[1] = 0;
                newchunk[2] = 127;
                newchunk[3] = 127;
                newchunk[4] = arraylength - 254;
              } else if (arraylength <= 508) {
                newchunk[0] = 2;
                newchunk[1] = 127;
                newchunk[2] = 127;
                newchunk[3] = 127;
                newchunk[4] = arraylength - 381;
              }
              newchunk.set(uint8Array, 5);
              newchunk.set(chunk, 5 + arraylength);
              if (This.getSocket && This.getSocket.readyState === WebSocket.OPEN) {
                console.log(newchunk, 'newchunknewchunknewchunk');

                This.getSocket.send(newchunk);

                // 更新进度
                this.progress = Math.round(((currentChunk + 1) / totalChunks) * 100);

                // 递增当前分片索引
                currentChunk++;

                // 使用setTimeout延迟发送下一片
                setTimeout(sendNextChunk, 50);
              } else {
                console.error('WebSocket连接已断开');
                this.$message.error('传输失败: WebSocket连接已断开');
              }
            };

            // 开始发送第一片
            sendNextChunk();
          }
          // This.getSocket.send(newArray.buffer);
          // const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

          // console.log(newArray.buffer, 'originalArrayBuffer');
        };
        // // console.log(formData, 'formDataformData');
        reader.readAsArrayBuffer(this.svgFile);
        // console.log(this.useSvgo, 'this.useSvgothis.useSvgo');
      });
    },
    // 开始雕刻
    carva() {
      this.engraveDialog = true;
    },
    // closeEngrave() {
    //   this.engraveDialog = false;
    // },
    // 导入gcode雕刻
    carveGcode() {
      this.action = 'carve';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
      // this.progressDia = true;
    },
    // handleStartCarving() {
    //   // this.progressDia = true;
    // },
    // closeprogress() {
    //   // this.progressDia = false;
    // },
    // 关闭材料弹窗
    closeConfig() {
      this.configDialog = false;
    },
    // // 连接com口
    // connectLaser() {
    //   this.action = 'connect';
    //   const params = {
    //     action: 'disconnect',
    //   };
    //   this.getSocket.send(JSON.stringify(params));

    //   const data = {
    //     action: this.action,
    //     port: this.selectValue,
    //   };
    //   this.getSocket.send(JSON.stringify(data));
    //   // console.log(this.selectValue);
    // },
    // 开始
    resume() {
      this.showIcon = true;
      this.action = 'resume';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    // 暂停
    pause() {
      this.showIcon = false;
      this.action = 'pause';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
      // if (!this.intervalId) {
      //   this.intervalId = setInterval(() => {
      //     this.updateTimeLeft();
      //   }, 1000);
      // }
    },
    async connectToHardware() {
      const startPort = 7500;
      this.$store.dispatch('webSocket/joinWs', startPort);
      this.getSocket.onopen = () => {
        this.action = 'serials';
        const userId = localStorage.getItem('id');
        this.$store.dispatch('webSocket/setSendType', '');
        const ws = { action: this.action, userId };
        this.getSocket.send(JSON.stringify(ws));
        // this.startTimer();
      };
      this.getSocket.onmessage = (e) => {
        // console.log(this.action === 'carve', "this.action === 'carve'this.action === 'carve'");
        // console.log(this.action === 'resume', "this.action === 'resume'this.action === 'resume'");
        // console.log(this.progressDia, 'this.progressDiathis.progressDia');

        try {
          const jsonData = JSON.parse(e.data); // 尝试将数据解析为JSON对象
          console.log('这是有效的JSON:', jsonData);
          if (this.action === 'serials' && e.data !== 'ping' && this.sendType === '') {
            this.comOptions = jsonData.map((item) => ({ label: item, value: item }));
            // console.log(this.comOptions, 'this.comOptionsthis.comOptions');
            this.$store.dispatch('webSocket/setCom', this.comOptions);
          } else if (this.checked === true) {
            if (this.checked === true) {
              const logOutput = document.getElementById('logOutput');
              logOutput.textContent = `${e.data}\n${logOutput.textContent}`;
              // logOutput.textContent += event.data + '\n';
              // // console.log(logOutput.textContent,'logOutput.textContentlogOutput.textContent');
              logOutput.scrollTop = 0; // 自动滚动到底部
            }
          } else if (jsonData.progress) {
            // console.log(
            //   typeof e.data === 'string',
            //   "typeof e.data === 'string'typeof e.data === 'string'"
            // );
            // console.log(
            //   jsonData.progress === 100,
            //   'jsonData.progress === 100jsonData.progress === 100'
            // );

            if (typeof e.data === 'string' && /^\s*\{.*\}\s*$/.test(e.data)) {
              if (jsonData.success === true && jsonData.progress === 100) {
                this.$message.success('Engraving completed');
                this.progress = jsonData.progress;
                this.$store.dispatch('progress/setProgress', this.progress);
              } else {
                console.log('1');

                // 如果是，则解析 JSON 字符串为对象
                this.progress = jsonData.progress;
                this.$store.dispatch('progress/setProgress', this.progress);
                // console.log(this.progress);
                // console.log(this.progress, 'progressprogressprogress');
                // 剩余时间
                // const [remainingHours, remainingMinutes, remainingSeconds] = jsonData.estimatedTime
                // .split(':')
                // .map(Number);
                this.remainingTime = jsonData.remainingTime;
                // (remainingHours * 60 * 60 + remainingMinutes * 60 + remainingSeconds) * 1000;
                // console.log(this.remainingTime, 'remainingTimeremainingTime');
                this.$store.dispatch('progress/setRemainingTime', this.remainingTime);
                // 雕刻时间
                // const [engravingHours, engravingMinutes, engravingSeconds] = jsonData.duration
                //   .split(':')
                //   .map(Number);
                // this.engravingtime =
                //   (engravingHours * 60 * 60 + engravingMinutes * 60 + engravingSeconds) * 1000;
                this.engravingtime = jsonData.spentTime;
                this.$store.dispatch('progress/setSpentTime', this.engravingtime);
                // console.log(this.engravingtime, 'engravingtimeengravingtime');
                // console.log(this.formattedTimeLeft, 'formattedTimeLeftformattedTimeLeft');

                // this.updateTimeLeft();
                // this.intervalId = setInterval(() => {
                //   this.updateTimeLeft();
                // }, 1000); // 每秒更新一次
                // // console.log(1);
                console.log(this.progress, 'this.progressthis.progress');
                console.log(this.progresremainingTimes, 'this.remainingTime.remainingTime');
                console.log(this.engravingtime, 'this.engravingtime.engravingtime');
                this.messageData = e.data;
              }
            }
          } else if (e.data instanceof ArrayBuffer && this.sendType === 'SVG') {
            // console.log(333);
            const arrayBuffer = e.data;
            const uint8Array = new Uint8Array(arrayBuffer);
            const decoder = new TextDecoder('utf-8');
            const jsonString = decoder.decode(uint8Array);
            // console.log('Decoded JSON String:', jsonString);
            const jsonObject = JSON.parse(jsonString);
            console.log('Parsed JSON Object:', jsonObject);
          } else if (jsonData.includes('successfully') && this.sendType === 'Gcode') {
            // console.log('GcodeGcodeGcodeGcodeGcodeGcode');
            this.gcodeData = this.getGCode;
            // console.log(this.getGCode, 'this.setGCodethis.getGCode');
            this.drawer = true;
            // this.carveGcode();
          } else if (this.sendType === 'SVG' && jsonData.includes('successfully')) {
            this.$message.success('Gcode file generated successfully');
            // document.getElementById('gcodePreview').value = e.data;
            // console.log(e.data, 'datadatadatadatadata');
          }
        } catch (error) {
          if (e.data instanceof Blob && this.sendType === 'SVG') {
            const blob = e.data;
            this.$store.dispatch('webSocket/setGCode', blob);
            this.gcodeData = blob;
            this.drawer = true;
          } else if (this.sendType === 'photograph') {
            this.$store.dispatch('webSocket/setSendType', '');
            const blob = e.data;
            console.log(blob, 'blobblob');
            // 创建 URL
            const imageUrl = URL.createObjectURL(blob);
            console.log(imageUrl, 'imageUrlimageUrl');

            // 创建 fabric 图片对象
            fabric.Image.fromURL(imageUrl, (img) => {
              // 获取工作区对象
              const workspace = canvas.c.getObjects().find((obj) => obj.id === 'workspace');
              if (workspace) {
                // 计算缩放比例，使图片完全覆盖工作区
                const scaleX = workspace.width / img.width;
                const scaleY = workspace.height / img.height;
                // 设置图片大小和位置
                img.set({
                  left: workspace.left,
                  top: workspace.top,
                  // width: workspace.width,
                  // height: workspace.height,
                  scaleX,
                  scaleY,
                  evented: false,
                  isLock: false,
                  lockScalingX: true, // 锁定水平缩放
                  lockScalingY: true, // 锁定垂直缩放
                  selectable: false,
                  hasControls: false,
                  id: 'photo', // 添加唯一ID
                });
                // 保存图片ID
                this.photoId = img.id;

                // 将图片添加到画布最上层
                canvas.c.add(img);
                workspace.sendToBack();
                const allObjects = canvas.c.getObjects();
                const photoIndex = allObjects.findIndex((obj) => obj.id === 'photo');
                const workspaceIndex = allObjects.findIndex((obj) => obj.id === 'workspace');

                // 将照片移动到工作区之上的位置
                if (photoIndex !== -1 && workspaceIndex !== -1) {
                  img.moveTo(workspaceIndex + 1);
                }
                // 更新状态
                this.showPhotoOverlay = true;

                // 清理 URL
                URL.revokeObjectURL(imageUrl);

                canvas.c.renderAll();
              }
            });
          } else if (e.data === 'Connecting') {
            this.join = true;
            this.$store.dispatch('webSocket/setJoin', this.join);
          } else if (
            e.data.startsWith('<Idle') ||
            e.data.startsWith('<Jog') ||
            e.data.startsWith('<Home')
          ) {
            // 定义正则表达式匹配 MPos 和 WPos 的值
            const mposMatch = e.data.match(/MPos:([\d.,-]+)/);
            const wposMatch = e.data.match(/WPos:([\d.,-]+)/);

            // 提取匹配到的值
            const mpos = mposMatch ? mposMatch[1] : null; // "0.000,0.000,0.000"
            const wpos = wposMatch ? wposMatch[1] : null; // "0.000,0.000,0.000"
            this.$store.dispatch('progress/setWorkPosition', wpos);
            this.$store.dispatch('progress/setHomePosition', mpos);
            console.log(e.data, 'data');
          }
          return null;
        }
      };
      this.getSocket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      this.getSocket.onclose = () => {
        this.join = false;
        this.$store.dispatch('webSocket/setJoin', this.join);
        this.connectToHardware();
        // console.log('WebSocket connection closed with code:');
        // if (this.timerId) {
        //   clearInterval(this.timerId);
        // }
      };
    },

    // 取消雕刻
    cancelRun() {
      this.$confirm('Definite cancel engraving?', 'Unengrave', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Cancel  successfully!',
          });
          this.action = 'cancel';
          const data = { action: this.action };
          this.getSocket.send(JSON.stringify(data));
          // this.closeprogress();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancelled',
          });
        });
    },
    updateTimeLeft() {
      if (this.timeLeft > 0) {
        this.timeLeft -= 1000;
      } else {
        clearInterval(this.intervalId);
        this.timeLeft = 0;
      }
    },
    closeGcode() {
      this.gcodeDia = false;
    },
    // 导出
    async exportProject() {
      const tempCanvas = new fabric.StaticCanvas(null, {
        width: canvas.c.width,
        height: canvas.c.height,
        // backgroundColor: canvas.c.backgroundColor,
      });

      // 2. 深度克隆函数（支持嵌套 Group）
      const deepClone = async (sourceObj) => {
        return new Promise((resolve) => {
          // 创建属性白名单（必须包含所有自定义属性）
          const customProps = [
            'zIndex',
            'speed',
            'power',
            'pass',
            'interval',
            'dept',
            'cutType',
            'src',
          ];

          // 执行克隆并携带白名单
          sourceObj.clone(async (clonedObj) => {
            if (clonedObj.type === 'path') {
              if (clonedObj.stroke === 'blue') {
                clonedObj.set({
                  stroke: null, // 关键修改：清除边框颜色
                  strokeWidth: 0,
                });
              }
              // 获取路径数据
              let pathData = clonedObj.path;

              // 检查并清理路径数据中的 NaN 值
              if (pathData && Array.isArray(pathData)) {
                // 过滤掉包含 NaN 的路径命令
                pathData = pathData.filter((cmd) => {
                  // 检查命令数组中是否有 NaN
                  if (Array.isArray(cmd)) {
                    return !cmd.some(
                      (val) => (typeof val === 'number' && Number.isNaN(val)) || val === 'NaN'
                    );
                  }
                  return true;
                });

                // 更新对象的路径数据
                clonedObj.path = pathData;
              }
            }
            if (clonedObj.type === 'image') {
              // 将图片转换为内联数据URL
              try {
                const imgElement = sourceObj._element;
                if (imgElement && imgElement.complete) {
                  const canvass = document.createElement('canvas');
                  canvass.width = imgElement.naturalWidth || imgElement.width;
                  canvass.height = imgElement.naturalHeight || imgElement.height;
                  const ctx = canvass.getContext('2d');
                  ctx.drawImage(imgElement, 0, 0);

                  // 设置内联数据URL
                  const dataUrl = canvass.toDataURL('image/png');
                  clonedObj.setSrc(dataUrl, () => {
                    if (clonedObj.stroke === 'blue') {
                      clonedObj.set({
                        stroke: null, // 关键修改：清除边框颜色
                        strokeWidth: 0,
                      });
                    }
                    // 同步基础属性
                    clonedObj.set({
                      left: sourceObj.left,
                      top: sourceObj.top,
                      angle: sourceObj.angle,
                      scaleX: sourceObj.scaleX,
                      scaleY: sourceObj.scaleY,
                    });
                    resolve(clonedObj);
                  });
                  return; // 提前返回
                }
              } catch (e) {
                console.error('图片处理失败:', e);
              }
            }
            // 2. 处理嵌套对象（注意使用await保证顺序）
            if (clonedObj.type === 'group' && clonedObj._objects) {
              clonedObj._objects = await Promise.all(
                clonedObj._objects.map((child) => deepClone(child))
              );
            }

            // 3. 同步基础属性
            clonedObj.set({
              left: sourceObj.left,
              top: sourceObj.top,
              angle: sourceObj.angle,
              scaleX: sourceObj.scaleX,
              scaleY: sourceObj.scaleY,
            });

            resolve(clonedObj);
          }, customProps); // 关键点：传入白名单
        });
      };

      // 3. 克隆原始对象到临时画布
      const originalObjects = canvas.c.getObjects();
      const clonedObjects = await Promise.all(
        originalObjects
          .filter(
            (obj) =>
              obj.id !== 'workspace' &&
              obj.id !== 'coordinate' &&
              obj.id !== 'photo' &&
              !(obj instanceof fabric.Rect && obj.fill?.source instanceof HTMLCanvasElement)
          )
          .map((obj) => deepClone(obj))
      );

      tempCanvas.add(...clonedObjects);
      tempCanvas.renderAll();

      // 4. 拆解 Group 的递归函数
      // 4. 拆解 Group 的递归函数
      const deepUngroup = (canvass) => {
        const processGroup = (group) => {
          const groupTransform = group.calcTransformMatrix();

          group._objects.forEach((child) => {
            // 保存原始的翻转状态
            const originalFlipX = child.flipX || group.flipX || false;
            const originalFlipY = child.flipY || group.flipY || false;

            const childTransform = child.calcTransformMatrix();
            const finalTransform = fabric.util.multiplyTransformMatrices(
              groupTransform,
              childTransform
            );
            const options = fabric.util.qrDecompose(finalTransform);

            // 正确处理缩放和翻转
            child.set({
              left: options.translateX,
              top: options.translateY,
              angle: options.angle,
              scaleX: Math.abs(options.scaleX) * (originalFlipX ? -1 : 1),
              scaleY: Math.abs(options.scaleY) * (originalFlipY ? -1 : 1),
              skewX: options.skewX,
              skewY: options.skewY,
              flipX: originalFlipX,
              flipY: originalFlipY,
              group: null,
              originX: 'center',
              originY: 'center',
            });

            child.setCoords();
          });

          canvass.remove(group);
          canvass.add(...group._objects);
        };

        let groups;
        do {
          groups = canvass.getObjects().filter((obj) => obj.type === 'group');
          groups.forEach(processGroup);
        } while (groups.length > 0);
      };

      // 5. 在临时画布执行拆解
      deepUngroup(tempCanvas);
      // 6. 注入 SVG 元数据
      // ... existing code ...
      tempCanvas.forEachObject((obj) => {
        if (obj.type === 'image') {
          const originalToSVG = obj.toSVG;
          obj.toSVG = function (reviver) {
            let svgString = originalToSVG.call(this, reviver);

            // 修复命名空间问题
            svgString = svgString.replace(
              /<image\s+/,
              '<image xmlns:xlink="http://www.w3.org/1999/xlink" '
            );

            return svgString;
          };
        }
        const originalToSVG = obj.toSVG;

        obj.toSVG = (reviver) => {
          let svgString = originalToSVG.call(obj, reviver);

          try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svgString, 'image/svg+xml');

            // 查找具体的图形元素
            const elements = doc.querySelectorAll(
              'rect, circle, ellipse, line, polyline, polygon, path,image'
            );

            elements.forEach((element) => {
              // 根据zIndex构建不同的desc内容
              let descData = {};
              if (obj.zIndex !== '00') {
                descData = {
                  feedRate: obj.speed,
                  spindleSpeed: obj.power,
                  passes: obj.pass,
                  interval: obj.interval,
                  zIndex: obj.zIndex,
                  cutType: obj.cutType,
                };
              } else {
                descData = {
                  targetDepth: obj.dept,
                  startDepth: 0,
                  zIndex: obj.zIndex,
                  cutType: obj.cutType,
                };
              }
              // 创建desc元素
              const desc = doc.createElementNS('http://www.w3.org/2000/svg', 'desc');
              // desc.setAttribute('data-type', 'fabric-custom');
              desc.textContent = JSON.stringify(descData);

              // 插入到图形元素内
              element.appendChild(desc);
            });

            // 序列化并清理输出
            svgString = new XMLSerializer()
              .serializeToString(doc.documentElement)
              .replace(/\n/g, '')
              .replace(/\/>/g, '/>');
          } catch (error) {
            console.error('SVG 增强失败:', error);
          }

          return svgString;
        };
      });

      const dataUrl = tempCanvas.toSVG();
      // console.log(dataUrl, 'dataUrl');
      // const newSvgUrl = flattenSVG(dataUrl);
      // // console.log(newSvgUrl, 'newSvgUrlnewSvgUrl');

      const newblob = new Blob([dataUrl], { type: 'image/svg+xml' });
      // 基于 Blob 创建一个 File 对象
      const svgFile = new File([newblob], `Project${localStorage.getItem('canvasID')}.svg`, {
        type: 'image/svg+xml',
      });
      const formDatas = new FormData();
      formDatas.append('file', svgFile, `Project${localStorage.getItem('canvasID')}.svg`);
      // console.log(formDatas, 'formDatasformDatas');

      svgo(formDatas).then((res) => {
        const svgString = res.data;
        this.useSvgo = formatSVG(svgString);
        // console.log(this.useSvgo, 'this.useSvgothis.useSvgo');

        // this.flattenSVG(dataUrl)
        //   .then((optimizedSvg) => {
        //     // console.log('Optimized SVG:', optimizedSvg);
        //     // 将优化后的 SVG 插入到页面中
        //     document.body.innerHTML += `<div>${optimizedSvg}</div>`;
        //   })
        //   .catch((error) => {
        //     console.error('Error optimizing SVG:', error);
        //   });
        const data = {
          id: localStorage.getItem('canvasID'),
          name: `Project${localStorage.getItem('canvasID')}`,
          userId: localStorage.getItem('id'),
          canvasUrl: this.canvasUrl,
          materialInfo: {
            // 材料尺寸
            simension: '{"X":"900 mm","Y":"1200 mm","Z":"19 mm"}',
            previewUrl: this.canvasUrl,
            color: '#FF0000',
            extInfo: '',
          },
          // 设备id
          machineId: localStorage.getItem('machineId'),
          previewUrl: this.canvasUrl,
        };
        const mar = originalObjects.find((item) => item.id === 'workspace');
        exportPro(data).then((ddd) => {
          if (ddd.code === 200) {
            const apiData = ddd.data;
            const fullSvgContent = `${apiData}\n${mar.left},${mar.top}\n${this.useSvgo}`;
            const fileStr = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
              fullSvgContent
            )}`;
            // console.log(fileStr, 'fileStrfileStrfileStr');
            this.downFile(fileStr, `Project${localStorage.getItem('canvasID')}.lac`);
          }
        });
      });
    },
    // 锁定
    doLock(isLock) {
      isLock ? this.lock() : this.unLock();
    },
    lock() {
      this.isLock = true;
      this.updateObjectProperties(true);
      canvas.c.renderAll();
    },
    unLock() {
      this.isLock = false;
      this.updateObjectProperties(false);
      canvas.c.renderAll();
    },
    updateObjectProperties(lock) {
      this.clickActive.selectable = true; // 允许选择
      this.clickActive.isLock = lock; // 允许选择
      this.clickActive.evented = !lock; // 锁定时不允许事件（拖动、缩放）
      this.clickActive.lockMovementX = lock; // 锁定水平移动
      this.clickActive.lockMovementY = lock; // 锁定垂直移动
      this.clickActive.lockScalingX = lock; // 锁定水平缩放
      this.clickActive.lockScalingY = lock; // 锁定垂直缩放
      this.clickActive.hasControls = !lock; // 隐藏控制点
    },
    // 通用属性改变
    changeCommon(key, value) {
      const objects = canvas.c.getObjects();
      const workSpace = objects.find((item) => item.id === 'workspace');
      if (key === 'left') {
        if (value && !value.endsWith('mm')) {
          const cleanedValue = value.replace(/[^\d.-]/g, '');
          if (!Number.isNaN(cleanedValue) && cleanedValue !== '') {
            this.baseAttr.left = `${parseFloat(cleanedValue).toFixed(1)}`;
          } else {
            this.baseAttr.left = '';
          }
        }
        // console.log(11111);
        const x = value;
        this.clickActive.set(
          'left',
          Number(
            (
              millimeterToPx(Number(x)) +
              workSpace.left +
              (this.clickActive.width * this.clickActive.get('scaleX')) / 2
            ).toFixed(2)
          )
        );
        canvas.c.renderAll();
        return;
      }
      if (key === 'top') {
        const x = value;
        this.clickActive.set(
          'top',
          Number(
            (
              workSpace.height +
              workSpace.top -
              millimeterToPx(Number(x)) -
              (this.clickActive.height * this.clickActive.get('scaleY')) / 2
            ).toFixed(2)
          )
        );
        canvas.c.renderAll();
        return;
      }
      if (key === 'width') {
        const x = value;
        // console.log(millimeterToPx(Number(x)), 'millimeterToPx(Number(x))');
        this.clickActive.set(
          'scaleX',
          Number(millimeterToPx(Number(x)).toFixed(2)) / this.clickActive.width
        );
        canvas.c.renderAll();
        return;
      }
      if (key === 'height') {
        const x = value;
        // console.log(millimeterToPx(Number(x)), 'millimeterToPx(Number(x))');
        this.clickActive.set(
          'scaleY',
          Number(millimeterToPx(Number(x)).toFixed(2)) / this.clickActive.height
        );
        canvas.c.renderAll();
        return;
      }
      // 透明度特殊转换
      if (key === 'opacity') {
        this.activeObject && this.activeObject.set(key, value / 100);
        canvas.c.renderAll();
        return;
      }
      // 旋转角度适配
      if (key === 'angle') {
        this.activeObject.rotate(value);
        canvas.c.renderAll();
        return;
      }
      // 圆角
      if (key === 'rx') {
        this.activeObject.set('rx', Number(millimeterToPx(Number(value))));
        if (this.activeObject.ry === 0) {
          this.activeObject.set('ry', Number(millimeterToPx(Number(value))));
        }
        canvas.c.renderAll();
        return;
      }
      if (key === 'ry') {
        this.activeObject.set('ry', Number(millimeterToPx(Number(value))));
        canvas.c.renderAll();
      }
      if (key === 'x') {
        this.$store.dispatch('material/setWidth', this.baseAttr.materialX);
      }
      if (key === 'y') {
        this.$store.dispatch('material/setHeight', this.baseAttr.materialY);
      }
      if (key === 'z') {
        this.$store.dispatch('material/setDept', this.baseAttr.materialZ);
      }
      // activeObject && activeObject.set(key, value);
    },
    // 监听移动
    bindEventListeners() {
      canvas.c.on('object:moving', (e) => {
        // // console.log(e, 'eeeeeeeeesssssseeeeeesmoving');
        const activeObject = e.target;
        const objects = canvas.c.getObjects();
        const workSpace = objects.find((item) => item.id === 'workspace');
        this.baseAttr.left = `${perMillimeter(
          Number(
            (
              activeObject.get('left') -
              workSpace.left -
              (activeObject.get('width') * activeObject.get('scaleX')) / 2
            ).toFixed(2)
          )
        ).toFixed(1)}`;
        this.baseAttr.top = `${perMillimeter(
          Number(
            (
              workSpace.height +
              workSpace.top -
              activeObject.get('top') -
              (activeObject.get('height') * activeObject.get('scaleY')) / 2
            ).toFixed(2)
          )
        ).toFixed(1)}`;
        // // console.log(this.clickActive, 'this.clickActivethis.clickActivethis.clickActive');

        // const pathData = `M ${left},${top} h${width} v${height} h-${width} z`;
      });

      canvas.c.on('object:scaling', (e) => {
        // // console.log(e, 'eeeeeeeeeeeeeeescaling');
        const activeObject = e.target;
        this.baseAttr.width = `${perMillimeter(
          Number(activeObject.get('width') * activeObject.get('scaleX')).toFixed(2)
        ).toFixed(1)}`;
        this.baseAttr.height = `${perMillimeter(
          Number(activeObject.get('height') * activeObject.get('scaleY')).toFixed(2)
        ).toFixed(1)}`;
        // // console.log(e, 'eeeeeeeeeeeeeeeeee');
      });
    },

    unpDownFlip() {
      this.flip('Y');
    },
    sideFilp() {
      this.flip('X');
    },
    group() {
      canvas.editor.group();
    },
    ungroup() {
      canvas.editor.unGroup();
    },

    // 撤销
    undo() {
      if (this.undoStack.length > 0) {
        const currentState = canvas.c.toJSON([
          'id',
          'zIndex',
          'cutType',
          'dept',
          'speed',
          'power',
          'pass',
          'interval',
        ]);
        this.redoStack.push(currentState);

        const prevState = this.undoStack.pop();
        canvas.c.loadFromJSON(prevState, () => {
          canvas.c.renderAll();
        });
      }
    },

    // 重做
    redo() {
      if (this.redoStack.length > 0) {
        const currentState = canvas.c.toJSON([
          'id',
          'zIndex',
          'cutType',
          'dept',
          'speed',
          'power',
          'pass',
          'interval',
        ]);
        this.undoStack.push(currentState);

        const nextState = this.redoStack.pop();
        canvas.c.loadFromJSON(nextState, () => {
          canvas.c.renderAll();
        });
      }
    },
    // 图片处理
    handlePhoto() {
      this.imgLoading = true;
      const activeObject = canvas.c.getActiveObjects()[0];
      console.log(activeObject, 'activeObjectactiveObjectactiveObject');
      this.currentProcessingImage = activeObject;
      this.imageSrc = activeObject._element.src;
      this.controls.interval = activeObject.interval;
      const base64Data = activeObject._element.src;
      const byteString = atob(base64Data.split(',')[1]);
      const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });
      const file = new File([blob], 'image.jpg', { type: mimeString });

      // 创建 FormData 对象并添加所需参数
      const formData = new FormData();
      formData.append('file', file);
      formData.append('width', perMillimeter(activeObject.width * activeObject.scaleX));
      formData.append('height', perMillimeter(activeObject.height * activeObject.scaleY));
      formData.append('effect', this.controls.effect);
      formData.append('interval', activeObject.interval);
      formData.append('angle', activeObject.angle);
      adjust(formData).then((res) => {
        console.log(res, 'res');
        console.log(res.data instanceof Blob, 'res.data instanceof Blob');
        const blobs = new Blob([res.data], { type: 'image/jpeg' });
        console.log(blobs, 'blobsblobsblobs');
        const imageUrl = URL.createObjectURL(blobs);
        console.log(imageUrl, 'imageUrlimageUrl');

        this.imageEffect = imageUrl;
        const filenameStartIndex = res.headers['content-disposition'].indexOf('filename=');
        this.filename = res.headers['content-disposition'].substring(
          filenameStartIndex + 'filename='.length
        );
        if (!this.processedImagesMap) {
          this.processedImagesMap = new Map();
        }

        // 将处理后的图像保存到映射中
        this.processedImagesMap.set(activeObject.id, {
          originalObject: activeObject,
          processedImageUrl: imageUrl,
        });
        this.imgLoading = false;
      });
      this.showImage = true;
    },
    // 关闭图片处理
    closeImage() {
      this.showImage = false;
    },
    // 图片处理
    debounceMethod() {
      if (this.timer) {
        clearTimeout(this.timer); // 清除之前的定时器
      }
      this.timer = setTimeout(() => {
        const activeObject = canvas.c.getActiveObjects()[0];
        activeObject.set('interval', this.controls.interval);
        this.handlePhoto();
      }, 1000); // 设置新的定时器，延迟两秒
    },
    // efectchange
    effectChange() {
      this.handlePhoto();
    },
    viewImage() {
      this.showImage = false;
      const activeObject = canvas.c.getActiveObjects()[0];
      const data = {
        imagePath: this.filename,
        width: perMillimeter(activeObject.width * activeObject.scaleX),
        height: perMillimeter(activeObject.height * activeObject.scaleY),
        power: activeObject.power,
        speed: activeObject.speed,
      };
      getadjusetGcode(data).then((res) => {
        console.log(res, 'ressssssssssssssssssss');
      });
    },
    closeDriver() {
      this.showDriver = false;
    },
    initDriver() {
      this.driver = new Driver({
        animate: true,
        opacity: 0.7,
        padding: 10,
        allowClose: false,
        overlayClickNext: true,
        doneBtnText: 'Done',
        closeBtnText: 'Close',
        nextBtnText: 'Next',
        prevBtnText: 'Previous',
        showButtons: true,
        onHighlightStarted: (element) => {
          console.log('高亮开始:', element);
          return true; // 必须返回 true 以继续
        },
        onDeselected: () => {
          localStorage.setItem('firstLogin', 'false');
        },
      });
      // 定义指引步骤
    },
    // 定义指引步骤
    // defineSteps() {
    //   const observer = new MutationObserver((mutations, obs) => {
    //     const toolsList = document.getElementById('step2');
    //     if (toolsList) {
    //       obs.disconnect(); // 停止观察
    //       this.driver.defineSteps(steps);
    //       this.driver.start();
    //     }
    //   });
    //   observer.observe(document.body, {
    //     childList: true,
    //     subtree: true,
    //   });
    // },
    // 开始指引
    startGuide() {
      const steps = [
        {
          element: '#step1',
          popover: {
            title: 'Tutorial',
            description: 'Follow this tutorial to turn your maker dream into reality.',
            position: 'left',
          },
        },

        {
          element: '#step2',
          popover: {
            title: 'Design Bar',
            description: 'Click the icons in the left bar to add items to your canvas.',
            position: 'right',
          },
        },
        {
          element: '#step3', // 确保 tools 组件有这个类名
          popover: {
            title: 'Toolbar',
            description:
              "Adjust your design's properties, such as size and position, for greater accuracy.",
            position: 'bottom',
          },
        },
        {
          element: '#step4',
          popover: {
            title: 'Properties Tab',
            description:
              'Test the machine in the "General" tab or modify the detailed carving parameters in the CNC/Laser tabs.',
            position: 'left',
          },
        },
        {
          element: '#step5',
          popover: {
            title: 'File',
            description: 'Easily load or save your projects.',
            position: 'bottom',
          },
        },
        {
          element: '#step6',
          popover: {
            title: 'Setup',
            description:
              'Set up a new machine, download drivers, or configure your bits and materials.',
            position: 'bottom',
          },
        },
        {
          element: '#step7',
          popover: {
            title: 'Help',
            description:
              'Read the online support documentation, check out items on sale, or watch instructional videos online.',
            position: 'bottom',
          },
        },
        {
          element: '#step8',
          popover: {
            title: 'Layer',
            description: 'Change the layer of your design.',
            position: 'top',
          },
        },
        {
          element: '#canvas',
          popover: {
            title: 'One more tip',
            description: 'Hold Alt + Left-click to pan the canvas.',
            position: 'top',
          },
        },
      ];
      // 确保 tools-list 元素存在
      const checkElement = () => {
        const toolsList = document.getElementById('step2');
        if (toolsList) {
          this.driver.defineSteps(steps);
          this.driver.start();
        } else {
          setTimeout(checkElement, 100);
        }
      };

      checkElement();
    },
  },
};
</script>
<style lang="less" scoped>
// 属性面板样式
/deep/ .attr-item {
  position: relative;
  margin-bottom: 12px;
  height: 40px;
  padding: 0 10px;
  background: #f6f7f9;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .ivu-tooltip {
    text-align: center;
    flex: 1;
  }
}

.ivu-menu-vertical .menu-item {
  text-align: center;
  padding: 10px 2px;
  box-sizing: border-box;
  font-size: 12px;

  & > i {
    margin: 0;
  }
}

::v-deep .ivu-layout-header {
  height: 60px;
  padding: 0 10px;
  border-bottom: 1px solid #eef2f8;
  background: #fff;
  height: var(--height);
  line-height: var(--height);
  display: flex;
  color: #333;
  font-size: large;
  background-color: #cfcfcf;
  color: #333;
}
.el-onheader {
  line-height: 60px;
  display: flex;
  justify-content: space-between;
}

.home,
.ivu-layout {
  height: 100vh;
}

.icon {
  display: block;
}

.canvas-box {
  position: relative;
}

.inside-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 15px 5px blue;
  box-shadow: inset 0 0 9px 2px #0000001f;
  z-index: 2;
  pointer-events: none;
}

#canvas {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  // background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=");
  // background-size: 30px 30px;
}

#workspace {
  overflow: hidden;
}

.content {
  flex: 1;
  width: 220px;
  padding: 10px;
  padding-top: 0;
  height: 100%;
  overflow-y: auto;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
  background: none;
}
// 标尺与网格背景
.switch {
  margin-right: 10px;
}
.design-stage-point {
  --offsetX: 0px;
  --offsetY: 0px;
  --size: 20px;
  background-size: var(--size) var(--size);
  background-image: radial-gradient(circle, #2f3542 1px, rgba(0, 0, 0, 0) 1px);
  background-position: var(--offsetX) var(--offsetY);
}

.design-stage-grid {
  // dom.style.setProperty('--offsetX', `${point.x + e.clientX}px`) 通过修改 偏移量 可实现跟随鼠标效果 --size 则为间距
  // dom.style.setProperty('--offsetY', `${point.y + e.clientY}px`)
  --offsetX: 0px;
  --offsetY: 0px;
  --size: 16px;
  --color: #dedcdc;
  background-image: linear-gradient(
      45deg,
      var(--color) 25%,
      transparent 0,
      transparent 75%,
      var(--color) 0
    ),
    linear-gradient(45deg, var(--color) 25%, transparent 0, transparent 75%, var(--color) 0);
  background-position: var(--offsetX) var(--offsetY),
    calc(var(--size) + var(--offsetX)) calc(var(--size) + var(--offsetY));
  background-size: calc(var(--size) * 2) calc(var(--size) * 2);
}

.coordinates-bar {
  --ruler-size: 16px;
  --ruler-c: #808080;
  --rule4-bg-c: #252525;
  --ruler-bdw: 1px;
  --ruler-h: 8px;
  --ruler-space: 5px;
  --ruler-tall-h: 16px;
  --ruler-tall-space: 15px;
  position: absolute;
  z-index: 2;
  background-color: var(--rule4-bg-c);
}
.coordinates-bar-top {
  cursor: row-resize;
  top: 0;
  left: 0;
  height: var(--ruler-size);
  width: 100%;
  background-image: linear-gradient(90deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0),
    linear-gradient(90deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0);
  background-repeat: repeat-x;
  background-size: var(--ruler-space) var(--ruler-h), var(--ruler-tall-space) var(--ruler-tall-h);
  background-position: bottom;
}
.coordinates-bar-left {
  cursor: col-resize;
  top: var(--ruler-size);
  width: var(--ruler-size);
  height: 100%;
  left: 0;
  background-image: linear-gradient(0deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0),
    linear-gradient(0deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0);
  background-repeat: repeat-y;
  background-size: var(--ruler-h) var(--ruler-space), var(--ruler-tall-h) var(--ruler-tall-space);
  background-position: right;
}
.el-button--primary {
  background-color: #cfcfcf; /* 鼠标悬停时的背景色 */
}
.el-button {
  color: white;
  border: none;
  transition: background-color 0.3s ease;
  color: black;
}
.el-button:hover {
  background-color: #3a8ee6; /* 鼠标悬停时的背景色 */
}

.el-dropdown-menu__item {
  color: #303133;
}

.el-dropdown-menu__item:hover {
  background-color: #ecf5ff; /* 下拉菜单项鼠标悬停时的背景色 */
  color: #409eff;
}
.el-tabs--border-card > .el-tabs__content {
  padding: 5px;
}
.button-container {
  display: flex;
  flex-direction: column;
  gap: 5px; /* 控制按钮间的间距 */
}
.button-container > .el-button {
  margin: 0;
}
.select-com {
  width: 100px;
}
.el-progress {
  line-height: 60px;
}
/deep/ .config .el-dialog {
  max-height: 900px;
}
/deep/ .menu-button span {
  font-size: 16px;
}
// Shape {
//   height: 1005;
// }
.full-height {
  display: flex;
  height: 100%;
}
.half-height {
  height: 50px;
  display: flex;
  text-align: center;
  align-items: center;
}

.col {
  height: 100%;
  padding: 0 5px;
  border-right: 1px solid #ccc;
}
.bg-purple {
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  padding-left: 10px;
}

.Controls .el-input {
  height: 30px;
}
::v-deep .Controls .el-input__inner {
  height: 100%;
}

/* 自定义图标的样式，以确保其居中显示 */
::v-deep .Controls .el-input__prefix {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0; /* 确保图标紧挨着输入框左侧 */
  width: auto; /* 让宽度自动适应内容 */
  padding-left: 3px; /* 可选：根据实际情况调整内边距 */
}

.Controls .icon-prefix {
  font-size: 16px; /* 根据需要调整大小 */
  pointer-events: none; /* 确保文字不会干扰输入 */
}
.Controls .el-input__icon {
  line-height: 30px;
}
.svg-icon {
  font-size: 30px;
}
.laser-tab .el-form-item {
  margin-bottom: 15px;
}
.el-slider {
  width: 100%;
}
/deep/ .ivu-icon {
  font-size: 30px;
}
.dialog-content {
  display: flex;
}
.svg-display {
  position: relative;
  flex: 1;
  height: 300px; /* 确保有足够的高度 */
  // height: 300px; /* 确保有足够的高度 */
  border: 1px solid #ccc; /* 可选：添加边框 */
}
.control-panel {
  flex: 1;
  padding-left: 20px; /* 添加一些间距 */
}
// ::v-deep .svg-display > svg {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: contain; /* 确保 SVG 按比例缩放 */
// }
/deep/ .el-drawer__header {
  margin-bottom: 10px;
}
/deep/ .el-drawer__header span {
  font-size: 22px;
  font-weight: 600;
}
</style>
