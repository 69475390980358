<template>
  <!-- <div
    class="easel-container"
    :style="{ width: panel2dWidth + 'px', height: panel2dHeight + 'px' }"
  >
    <div class="workspace" :style="{ width: panel2dWidth + 'px', height: panel2dHeight + 'px' }"> -->
  <div style="display: flex; position: absolute; top: 0; left: 0; z-index: 888">
    <ul class="list-container" id="step2">
      <li v-for="(item, index) in items" :key="index" @click="toggleDetails(index)">
        <el-tooltip
          class="tooltip"
          effect="dark"
          :content="item.tooltip"
          placement="right"
          trigger="hover"
          hide-after="500"
        >
          <div class="item-wrapper">
            <img :src="item.image" alt="" />
            <div :class="[item.isActive ? 'triangle' : 'none']"></div>
          </div>
        </el-tooltip>
      </li>
    </ul>
    <span>
      <ul class="childList" :style="{ visibility: ishow ? 'visible' : 'hidden' }">
        <li v-for="(item, index) in childItems" :key="index" @click="childClick(index)">
          <img :src="item.image" alt="" />
        </li>
      </ul>
      <ul class="secChildList" :style="{ visibility: secShow ? 'visible' : 'hidden' }">
        <li v-for="(item, index) in secChildItems" :key="index" @click="secChildClick(index)">
          <img :src="item.image" alt="" />
          <!-- <div :class="['triangle', { active: item.isActive }]"></div> -->
        </li>
      </ul>
      <div style="height: 240px; visibility: visible"></div>
      <ul class="secChildList" :style="{ visibility: importShow ? 'visible' : 'hidden' }">
        <li v-for="(item, index) in importItems" :key="index" @click="importClick(index)">
          <!-- <div :class="['triangle', { active: item.isActive }]"></div> -->
          <img :src="item.image" alt="" />
        </li>
      </ul>
      <div></div>
      <ul class="secChildList" :style="{ visibility: exportShow ? 'visible' : 'hidden' }">
        <li v-for="(item, index) in exportItems" :key="index" @click="exportClick(index)">
          <img :src="item.image" alt="" />
          <!-- :style="{ backgroundImage: `url(${item.image})` }" -->

          <!-- <div :class="['triangle', { active: item.isActive }]"></div> -->
        </li>
      </ul>
    </span>
    <!-- 素材库 -->
    <designLibrary :library.sync="library" @close-visible="closeVisable"></designLibrary>
    <!-- 高级素材库 -->
    <advancedMasterial
      :advanced.sync="advancedVis"
      @close-visible="closeAdvanced"
    ></advancedMasterial>

    <!-- <el-dialog
      title="提示"
      :visible.sync="library"
      width="30%"
      :before-close="closeVisable"
      :modal="false"
    >
      <span>这是一段信息</span>
    </el-dialog> -->

    <!-- 图片处理弹窗 -->
    <el-dialog
      title="Image processing"
      :visible.sync="showImage"
      width="60%"
      :before-close="closeImage"
      :modal="false"
    >
      <div class="dialog-content">
        <!-- 左边：SVG 展示区域 -->
        <div class="svg-display" id="imagePreview" v-loading="imgLoading">
          <el-image
            v-if="imageSrc"
            :src="imageSrc"
            alt="Selected Image"
            fit="contain"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="svg-display" v-html="svgUrl" v-loading="imgLoading"></div>
        <!-- 右边：控制面板 -->
        <div class="control-panel">
          <el-form label-width="145px">
            <el-form-item label="Noise cancellation">
              <el-input-number
                v-model="controls.spotRemoval"
                controls-position="right"
                :disabled="!controls.spotRemovalEnabled"
                :min="1"
                :max="100"
                :step="10"
                @blur="debounceMethod()"
                @change="debounceMethod()"
              ></el-input-number>
              <el-checkbox v-model="controls.spotRemovalEnabled">enable</el-checkbox>
            </el-form-item>
            <el-form-item label="Smoothing">
              <el-input-number
                v-model="controls.smoothing"
                controls-position="right"
                :disabled="!controls.smoothingEnabled"
                :min="0.1"
                :max="1.33"
                :step="0.1"
                @blur="debounceMethod()"
                @change="debounceMethod()"
              ></el-input-number>
              <el-checkbox v-model="controls.smoothingEnabled">enable</el-checkbox>
            </el-form-item>
            <el-form-item label="Reduced sampling">
              <el-input-number
                v-model="controls.downsampling"
                controls-position="right"
                :disabled="!controls.downsamplingEnabled"
                :min="0"
                :max="10"
                :step="1"
                @blur="debounceMethod()"
                @change="debounceMethod()"
              ></el-input-number>
              <el-checkbox v-model="controls.downsamplingEnabled">enable</el-checkbox>
            </el-form-item>
            <el-button @click="uppppp(svgUrl)">Add canvas</el-button>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <!-- AI生成 -->
    <el-dialog
      title="AI Image"
      :visible.sync="showAi"
      width="60%"
      :before-close="closeAi"
      :modal="false"
    >
      <div style="display: flex; text-align: center; align-items: center">
        <el-form :model="aiForm" :rules="aiRules" ref="aiForm" style="width: 30%">
          <el-form-item label="Keyword" prop="textContent">
            <el-input
              type="textarea"
              placeholder="Please enter keyword"
              v-model="aiForm.textContent"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          class="block"
          v-for="item in fits"
          :key="item.style"
          :style="{
            margin: '0 10px',
            width: '120px',
            backgroundColor: item.selected ? '#606266' : '',
            color: item.selected ? '#fff' : '',
          }"
          @click="selectStyle(item)"
        >
          <div>{{ item.style }}</div>
          <el-image style="width: 100px; height: 100px" :src="item.url" :fit="item.fit"></el-image>
        </div>
        <div>
          <el-button type="primary" v-loading="aiLoading" @click="getImage('aiForm')">
            Generate
          </el-button>
        </div>
      </div>

      <div class="dialog-content">
        <!-- 左边：SVG 展示区域 -->
        <div class="svg-display" id="imagePreview">
          <el-image
            v-if="aiImageUrl"
            v-loading="aiLoading"
            :src="aiImageUrl"
            alt="Selected Image"
            fit="contain"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="svg-display" v-html="aiSvg" v-loading="aiLoading"></div>

        <!-- 右边：控制面板 -->
        <div class="control-panel">
          <el-form label-width="145px">
            <el-form-item label="Noise Cancellation">
              <el-input-number
                v-model="aiControl.spotRemoval"
                aiControl-position="right"
                :disabled="!aiControl.spotRemovalEnabled"
                :min="1"
                :max="100"
                :step="10"
                @blur="aiChange()"
                @change="aiChange()"
              ></el-input-number>
              <el-checkbox v-model="aiControl.spotRemovalEnabled">enable</el-checkbox>
            </el-form-item>
            <el-form-item label="Smoothing">
              <el-input-number
                v-model="aiControl.smoothing"
                aiControl-position="right"
                :disabled="!aiControl.smoothingEnabled"
                :min="0.1"
                :max="1.33"
                :step="0.1"
                @blur="aiChange()"
                @change="aiChange()"
              ></el-input-number>
              <el-checkbox v-model="aiControl.smoothingEnabled">enable</el-checkbox>
            </el-form-item>
            <el-form-item label="Reduced Sampling">
              <el-input-number
                v-model="aiControl.downsampling"
                aiControl-position="right"
                :disabled="!aiControl.downsamplingEnabled"
                :min="0"
                :max="10"
                :step="1"
                @blur="aiChange()"
                @change="aiChange()"
              ></el-input-number>
              <el-checkbox v-model="aiControl.downsamplingEnabled">enable</el-checkbox>
            </el-form-item>
            <el-button @click="uppppp(aiSvg)">Add to...</el-button>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="Add Text"
      :visible.sync="showText"
      width="40%"
      :before-close="closeText"
      :modal="false"
    >
      <div>
        <el-form :model="textForm" :rules="textRules" ref="textForm" style="width: 50%">
          <el-form-item label="Text" prop="textContent">
            <el-input
              type="textarea"
              placeholder="Please enter keyword"
              v-model="textForm.textContent"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="Font" prop="textType">
            <el-select v-model="textForm.textType" placeholder="Choose">
              <el-option
                v-for="item in fontFamilyList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" v-loading="aiLoading" @click="handleFontSelect">
            Generate
          </el-button>
        </div>
        <!-- <Divider plain orientation="left">{{ $t('attributes.font') }}</Divider> -->
      </div>
    </el-dialog>
  </div>

  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import fontList from '@/assets/fonts/font';
import { v4 as uuid } from 'uuid';
import initializeLineDrawing from '@/core/initializeLineDrawing';
import { getImgStr, selectFiles } from '@/utils/utils';
import { exportPro, uploadPro } from '@/api/export';
import { importPro, importImg, getProject, importGcode, svgo } from '@/api/import';
import { getAi } from '@/api/aiImage';
import { updateProject } from '@/api/projects';
import designLibrary from '@/components/designLibrary.vue';
import advancedMasterial from '@/components/advancedMasterial.vue';
import { getPolygonVertices } from '@/utils/math';
import fetchSvgContent from '@/utils/svgService';
import formatSVG from '@/utils/changeSvg';

import { getToken } from '@/utils/auth';
// import processArray from '@/utils/arrayProcessor';
// import flattenSVG from '@/utils/svgTransformer';
// import FontFaceObserver from 'fontfaceobserver';
import * as opentype from 'opentype.js';
// import EventBus from '@/utils/eventBus';

// import axios from 'axios';
// import { getToken } from '@/utils/auth';

// 默认属性
const defaultPosition = { shadow: '', fontFamily: 'Arial', zIndex: '00' };
// 拖拽属性

export default {
  name: 'ToolBar',
  inject: ['canvas', 'fabric'],
  props: ['saveProject', 'updateDept', 'updateSocket'],
  computed: {
    // 监听 Vuex 中的 socket 状态
    getSocket() {
      return this.$store.state.webSocket.showLaser;
    },
    getWidth() {
      return this.$store.state.material.width;
    },
    getHeight() {
      return this.$store.state.material.height;
    },
    getDept() {
      return this.$store.state.material.dept;
    },
    firBit() {
      return this.$store.state.materialBits.firBit;
    },
    secBit() {
      return this.$store.state.materialBits.secBit;
    },
    feedRate() {
      return this.$store.state.materialBits.feedRate;
    },
    zFeedRate() {
      return this.$store.state.materialBits.zFeedRate;
    },
    spindleSpeed() {
      return this.$store.state.materialBits.spindleSpeed;
    },
    depthPerPass() {
      return this.$store.state.materialBits.depthPerPass;
    },
  },

  watch: {
    saveProject(newVal) {
      this.project = newVal;
    },
    // updateDept(newVal) {
    //   this.dept = newVal;
    //   this.engravingDepth = this.dept > 5 ? this.dept / 2 : 3;
    // },
    getSocket() {
      this.socket = this.$store.state.webSocket.showLaser;
    },
    // getWidth() {
    //   this.width = this.$store.state.material.width;
    // },
  },

  data() {
    return {
      fontFamilyList: fontList.map((item) => ({ label: item.fontFamily, value: item.fontFamily })),
      showText: false,
      fits: [
        {
          fit: 'fill',
          style: 'simple',
          url: require('@/assets/AI/simple.png'),
          selected: true,
        },
        {
          fit: 'fill',
          style: 'clean',
          url: require('@/assets/AI/clean.png'),
          selected: false,
        },
        {
          fit: 'fill',
          style: 'reality',
          url: require('@/assets/AI/reality.png'),
          selected: false,
        },
        {
          fit: 'fill',
          style: 'bluepencil',
          url: require('@/assets/AI/bluepencil.png'),
          selected: false,
        },
        {
          fit: 'fill',
          style: 'free',
          url: require('@/assets/AI/free.png'),
          selected: false,
        },
      ],
      aiStyle: 'simple',
      aiForm: {
        textContent: 'a cat',
      },
      aiRules: {
        textContent: [{ required: true, message: 'Please enter keyword', trigger: 'blur' }],
      },
      textForm: {
        textContent: 'CNC',
        textType: 'Arial',
      },
      textRules: {
        textContent: [{ required: true, message: 'Please enter Text', trigger: 'blur' }],
        textType: [{ required: true, message: 'Please select a type', trigger: 'blur' }],
      },
      aiImageUrl: null,
      aiSvg: '',
      aipath: '',
      aiLoading: false,
      imgLoading: false,
      width: 0,
      dept: 0,
      engravingDepth: 0,
      timer: null, // 定义定时器变量
      canvasId: null,
      canvasUrl: '',
      project: {},
      uploadInterval: null,
      isDrawingLineMode: true,
      isArrow: false,
      ishow: false,
      secShow: false,
      importShow: false,
      exportShow: false,
      items: [
        {
          image: require('@/assets/images/L1shapes.png'),
          isActive: true,
          tooltip: 'Shapes',
        },
        {
          image: require('@/assets/images/L2pen.png'),
          isActive: true,
          tooltip: 'Line Tool',
        },
        {
          image: require('@/assets/images/L4text.png'),
          isActive: false,
          tooltip: 'Text',
        },
        {
          image: require('@/assets/images/L5libray.png'),
          isActive: true,
          tooltip: 'Design Library',
        },
        {
          image: require('@/assets/images/L6formwork.png'),
          isActive: true,
          tooltip: 'Advanced Library',
        },
        {
          image: require('@/assets/images/L6AI.png'),
          isActive: true,
          tooltip: 'AI Image',
        },
        {
          image: require('@/assets/images/L7import.png'),
          isActive: true,
          tooltip: 'Import',
        },
        {
          image: require('@/assets/images/L8export.png'),
          isActive: true,
          tooltip: 'Export',
        },
        // {
        //   image: require('@/assets/images/L9share.png'),
        //   isActive: false,
        // },
      ],
      childItems: [
        {
          image: require('@/assets/images/L11square.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L12circular.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L13line.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L14triangle.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L15pentagon.png'),
          isActive: false,
        },
      ],
      secChildItems: [
        {
          image: require('@/assets/images/L21pen.png'),
          isActive: false,
        },
        // {
        //   image: require('@/assets/images/L22pencel.png'),
        //   isActive: false,
        // },
      ],
      importItems: [
        {
          image: require('@/assets/images/L71gcode.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L72svg.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L73image.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L73image2.png'),
          isActive: false,
        },
        {
          image: require('@/assets/images/L74project.png'),
          isActive: false,
        },
      ],
      exportItems: [
        // {
        //   image: require('@/assets/images/L81gcode.png'),
        //   isActive: false,
        // },
        {
          image: require('@/assets/images/L82project.png'),
          isActive: false,
        },
      ],
      fabricCanvas: null,
      mode: 'select',
      isGridVisible: true, // 确保网格始终显示
      gridSize: 20, // 网格大小
      snapThreshold: 0.5, // 对齐阈值
      selectedObject: null,
      mask: null,
      addWidth: 0,
      addHeight: 0,
      gridRect: null,
      library: false,
      isDrawing: false,
      toggle: false,
      points: [],
      polygon: null,
      svgFile: null,
      dragOption: {
        left: 0,
        top: 0,
      },
      tempObjects: [],
      // 图片路径
      filePath: '',
      showImage: false,
      showAi: false,
      svgUrl: '', // SVG 文件的 URL 或者内联 SVG 字符串
      imageSrc: null,
      controls: {
        spotRemoval: 10,
        spotRemovalEnabled: true,
        smoothing: 1,
        smoothingEnabled: true,
        downsampling: 2,
        downsamplingEnabled: true,
      },
      aiControl: {
        spotRemoval: 10,
        spotRemovalEnabled: true,
        smoothing: 1,
        smoothingEnabled: true,
        downsampling: 2,
        downsamplingEnabled: true,
      },
      advancedVis: false,
      useSvgo: null,
    };
  },
  components: {
    designLibrary,
    advancedMasterial,
  },
  created() {
    this.drawHandler = initializeLineDrawing(this.canvas.c, defaultPosition);
    this.canvas.c.on('mouse:down', this.handleMouseDown);
    this.canvas.c.on('mouse:up', this.handleMouseUp);
  },
  mounted() {
    this.engravingDepth = this.getDept > 5 ? this.getDept / 2 : 3;
    const objects = this.canvas.c.getObjects();
    const workSpace = objects.find((item) => item.id === 'workspace');
    const workspaceTop = workSpace.top + workSpace.height - 50;
    const workspaceleft = workSpace.left + 50;
    this.dragOption.top = workspaceTop;
    this.dragOption.left = workspaceleft;
    this.init();
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    if (this.uploadInterval) {
      clearInterval(this.uploadInterval);
      this.uploadInterval = null;
    }
    if (this.timer) {
      clearTimeout(this.timer); // 确保在组件销毁前清除定时器
    }
  },
  methods: {
    // 文字添加

    async handleFontSelect() {
      this.$refs.textForm.validate(async (valid) => {
        if (valid) {
          const fontMapping = {
            Cour: require('@/assets/fonts/cn/cour.ttf'),
            Arial: require('@/assets/fonts/cn/arial.ttf'),
            Yoxall8ey: require('@/assets/fonts/cn/yoxall8ey.ttf'),
            Arapey: require('@/assets/fonts/cn/Arapey.ttf'),
            FallingSky: require('@/assets/fonts/cn/FallingSky.otf'),
            FamiliarPro: require('@/assets/fonts/cn/Familiarpro.otf'),
            FoobarPro: require('@/assets/fonts/cn/Foobarpro.otf'),
            GaldienRounded: require('@/assets/fonts/cn/GaldienRounded.otf'),
            GeBody: require('@/assets/fonts/cn/GeBody.ttf'),
            MilfordHollowr: require('@/assets/fonts/cn/MilfordHollowr.ttf'),
            OrgreaveExtended: require('@/assets/fonts/cn/OrgreaveExtended.ttf'),
            PathwayGothicOne: require('@/assets/fonts/cn/PathwayGothicOne.ttf'),
            Swansea: require('@/assets/fonts/cn/Swansea.ttf'),
            Trueno: require('@/assets/fonts/cn/Trueno.otf'),
            WarsawGothic: require('@/assets/fonts/cn/WarsawGothic.otf'),
            // 添加其他字体...
          };
          const fontPath = fontMapping[this.textForm.textType];
          // console.log(fontPath, 'fontPathfontPath');
          try {
            // 使用预定义的映射对象来获取字体路径
            const sss = fontMapping[this.textForm.textType];
            if (!sss) {
              throw new Error('Font not found');
            }
            // 使用 fetch 加载字体文件
            const response = await fetch(fontPath);
            if (!response.ok) {
              throw new Error(`Failed to load font: ${response.statusText}`);
            }
            // 确保获取的是 ArrayBuffer，并等待 Promise 完成
            // 加载字体
            const ddd = await opentype.load(fontPath);
            console.log(ddd, 'ddddddddd');

            // console.log(ddd, 'dddddddddd');
            // 将文本转换为路径
            const path = ddd.getPath(this.textForm.textContent, 0, 0, 100);

            const assasa = path.toSVG();

            console.log(assasa, 'assasaassasaassasa');

            const textPath = new this.fabric.Path(assasa, {
              ...this.dragOption,
            });
            console.log(textPath, 'textPathtextPath');

            const objects = this.canvas.c.getObjects();
            const workSpace = objects.find((item) => item.id === 'workspace');
            textPath.set({
              top: workSpace.top + workSpace.height - textPath.height / 2,
              left: workSpace.left + textPath.width / 2,
              dept: this.engravingDepth,
              id: uuid(),
              originX: 'center',
              originY: 'center',
              cutType: 'Mill Pocket',
              zIndex: '00',
            });
            console.log(textPath, 'textPathtextPathtextPath');

            this.canvas.c.add(textPath);
            this.canvas.c.renderAll();
          } catch (error) {
            console.error('Error loading or parsing font:', error);
          }
        } else {
          // console.log('Error submitting form');
          return false;
        }
      });

      // // console.log(activeObject, 'activeObjectactiveObject');
      // // console.log(e, 'e'); // 华康金刚黑 e
    },

    // AI选择风格
    selectStyle(item) {
      // 取消所有项目的选中状态
      this.fits.forEach((fit) => {
        this.$set(fit, 'selected', false); // 确保 selected 是响应式的
      });
      this.aiStyle = item.style;
      // 设置当前项为选中状态
      this.$set(item, 'selected', true);
    },
    // 获取ai图片
    getImage(aiForm) {
      this.$refs[aiForm].validate((valid) => {
        if (valid) {
          this.aiLoading = true;
          const data = {
            content: this.aiForm.textContent,
            style: this.aiStyle,
          };
          // console.log(data, 'data');
          const token = getToken();
          getAi(data, token).then((res) => {
            if (res.status === 200) {
              this.aiImageUrl = URL.createObjectURL(res.data);
              // console.log(this.aiImageUrl, 'this.aiImagethis.aiImage');
              // const file = new File([blob], 'filename.png', {
              //   type: 'image/png',
              //   lastModified: Date.now(),
              // });
              const formData = new FormData();
              formData.append('file', res.data, 'filename.png');
              importImg(formData).then((item) => {
                if (item.data.code === 200) {
                  const svgUrlList = item.data.data.svg;
                  this.aipath = item.data.data.filePath;
                  // console.log(item, 'item');
                  // console.log(this.aiSvg, 'this.showImagethis.showImagethis.showImage');
                  // console.log(this.aipath, 'this.aipath.showImagethis.showImage');
                  // this.filePath = res.data.data.filePath;
                  // this.showImage = true;
                  // const svgUrlList = res.data.data.svg;
                  this.aiSvg = svgUrlList.replace(/\\n/g, '\n').replace(/\\"/g, '"');
                  this.aichangeMethod();
                  this.aiLoading = false;
                }
              });
            }
            // console.log(res, 'Res');
          });
        } else {
          // console.log('表单校验失败');
          return false;
        }
      });
    },
    async init() {
      const double = localStorage.getItem('double');
      console.log(double, 'doubledoubledouble');

      if (double === 'true') {
        await this.loadSvgContent();
      }
      this.getSvg();
      // 设置定时器，每隔30000毫秒（30秒）调用一次getSvg方法
      this.uploadInterval = setInterval(() => {
        this.getSvg();
      }, 30000);
    },
    // 将用户列表双击展示到画布中
    // ... existing code ...
    async loadSvgContent() {
      try {
        const svgString = await fetchSvgContent(this.saveProject.canvasUrl);
        console.log(svgString, 'svgStringsvgString');

        const cleanedSvg = svgString.replace(
          /<rect x="0" y="0" width="100%" height="100%" fill="white"><\/rect>/,
          ''
        );

        const parser = new DOMParser();
        const doc = parser.parseFromString(cleanedSvg, 'application/xml');
        const descElements = doc.querySelectorAll('desc');
        const descArray = Array.from(descElements);
        const desc = descArray.slice(1);

        // 获取工作区对象
        const workspace = this.canvas.c.getObjects().find((item) => item.id === 'workspace');

        this.fabric.loadSVGFromString(cleanedSvg, (objects) => {
          objects.forEach((obj, index) => {
            if (index < desc.length) {
              try {
                const jsonString = desc[index].innerHTML;
                const jsonObject = JSON.parse(jsonString);
                obj.set({
                  originX: 'center',
                  originY: 'center',
                });
                // 处理变换矩阵和位置
                if (obj.transformMatrix) {
                  const matrix = obj.transformMatrix;
                  // 直接使用原始位置，不进行额外计算
                  obj.set({
                    left: jsonObject.left,
                    top: jsonObject.top,
                    scaleX: matrix[0],
                    scaleY: matrix[3],
                    angle: jsonObject.angle || 0,
                    transformMatrix: null,
                  });
                }

                // 设置自定义属性
                const customProps = {
                  targetDepth: jsonObject.targetDepth,
                  startDepth: jsonObject.startDepth,
                  speed: jsonObject.feedRate,
                  power: jsonObject.spindleSpeed,
                  pass: jsonObject.passes,
                  interval: jsonObject.interval,
                  zIndex: jsonObject.zIndex || '00',
                  cutType: jsonObject.cutType || 'Mill Pocket',
                  dept: jsonObject.targetDepth,
                  id: uuid(),
                };

                Object.keys(customProps).forEach((key) => {
                  if (customProps[key] !== undefined) {
                    obj.set(key, customProps[key]);
                  }
                });

                // 保存完整的元数据
                obj.metadata = jsonObject;
                obj.setCoords();
                console.log(obj, 'objobj');

                // 确保对象在工作区内
                if (workspace) {
                  if (!obj.left || !obj.top) {
                    obj.center();
                  } else {
                    // 计算边界约束
                    const objHalfWidth = (obj.width * obj.scaleX) / 2;
                    const objHalfHeight = (obj.height * obj.scaleY) / 2;

                    // 使用相同的约束逻辑处理所有对象
                    const boundedLeft = Math.max(
                      workspace.left + objHalfWidth,
                      Math.min(jsonObject.left, workspace.left + workspace.width - objHalfWidth)
                    );
                    const boundedTop = Math.max(
                      workspace.top + objHalfHeight,
                      Math.min(jsonObject.top, workspace.top + workspace.height - objHalfHeight)
                    );

                    obj.set({
                      left: boundedLeft,
                      top: boundedTop,
                    });
                  }
                }
              } catch (e) {
                console.error('解析SVG元数据失败:', e);
              }
            }

            this.canvas.c.add(obj);
          });

          this.canvas.c.renderAll();
        });
      } catch (error) {
        console.error('加载SVG内容失败:', error);
      }
    },
    // ... existing code ...
    uppppp(e) {
      const This = this;
      // console.log(e, 'eeeeeeeee');
      This.fabric.loadSVGFromString(e, function (objects) {
        // console.log(objects, 'objectsobjectsssssssssssss');
        objects.forEach((item) => {
          item.zIndex = '00';
          item.cutType = 'Mill Pocket';
          item.dept = This.engravingDepth;
          // item.fill = 'red';
        });

        const templateSize = This.canvas.c.getObjects();
        const masObj = templateSize.filter((element) => element.id === 'workspace');
        const obj = This.fabric.util.groupSVGElements(objects);
        const targetSize = masObj[0].height / 2;
        const originalHeight = obj.height;
        const scaleFactor = targetSize / originalHeight;
        obj.set({
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          left: masObj[0].left + (obj.width * scaleFactor) / 2,
          top: masObj[0].top + masObj[0].height - (obj.height * scaleFactor) / 2,
          originX: 'center',
          originY: 'center',
        });
        obj.set('zIndex', '00');
        obj.set('cutType', 'Mill Pocket');
        obj.set('name', 'svg');
        obj.set('dept', This.engravingDepth);
        // obj.set('fill', 'red');
        This.canvas.c.add(obj);
        This.canvas.c.renderAll();
      });
      this.showImage = false;
      this.showAi = false;
      // const blob = new Blob([this.svgUrl], { type: 'image/svg+xml;charset=utf-8' });
      // const url = URL.createObjectURL(blob);
    },
    toggleDetails(index) {
      this.closeLine();
      this.canvas.isDrawingMode = false;
      if (index === 0) {
        this.items[index];
        this.secShow = false;
        this.importShow = false;
        this.exportShow = false;
        this.ishow = !this.ishow;
      } else if (index === 1) {
        this.ishow = false;
        this.importShow = false;
        this.exportShow = false;
        this.secShow = !this.secShow;
      } else if (index === 6) {
        this.ishow = false;
        this.secShow = false;
        this.exportShow = false;
        this.importShow = !this.importShow;
      } else if (index === 7) {
        this.ishow = false;
        this.secShow = false;
        this.importShow = false;
        this.exportShow = !this.exportShow;
      } else {
        this.ishow = false;
        this.secShow = false;
        this.importShow = false;
        this.exportShow = false;
      }
      if (index === 4) {
        this.advancedVis = true;
        // 橡皮擦功能
      } else if (index === 2) {
        this.showText = true;
        // this.addText(this.dragOption);
      } else if (index === 3) {
        this.library = true;
      } else if (index === 5) {
        this.showAi = true;
      }

      // // console.log(index, "index");
    },
    childClick(index) {
      // console.log(this.getDept, 'getDeptgetDept');
      // console.log(this.engravingDepth, 'this.engravingDepththis.engravingDepth');
      this.closeLine();
      if (index === 0) {
        this.canvas.c.isDrawingMode = false;
        this.addRect(this.dragOption);
      } else if (index === 1) {
        this.canvas.c.isDrawingMode = false;
        this.addCircle(this.dragOption);
      } else if (index === 2) {
        this.canvas.c.isDrawingMode = false;
        // if (this.isArrow) {
        //   this.isArrow = !this.isArrow;
        //   this.toggleDrawingLineMode();
        // } else {
        this.isArrow = !this.isArrow;
        // console.log(this.isArrow, 'this.isArrow');

        this.toggleDrawingLineMode();
        // }
        // }
      } else if (index === 3) {
        this.canvas.c.isDrawingMode = false;
        this.addTriangle(this.dragOption);
      } else if (index === 4) {
        this.canvas.c.isDrawingMode = false;
        this.addPolygon(this.dragOption);
      }
    },
    secChildClick(index) {
      this.closeLine();
      if (index === 0) {
        this.canvas.c.isDrawingMode = false;
        this.toggle = !this.toggle;
        if (this.toggle) {
          this.points = [];
          this.polygon = null;
        } else {
          this.clearCanvas();
        }
      } else if (index === 1) {
        this.isDrawing = !this.isDrawing;
        if (this.isDrawing === true) {
          this.canvas.c.isDrawingMode = true;
        } else if (this.isDrawing === false) {
          this.canvas.c.isDrawingMode = false;
        }
      }
    },
    handleMouseDown(event) {
      if (!this.toggle) return;
      const pointer = this.canvas.c.getPointer(event.e);
      const point = new this.fabric.Point(pointer.x, pointer.y);

      if (this.points.length === 0) {
        this.points.push(point);
        // this.canvas.c.add(
        const circle = new this.fabric.Circle({
          left: point.x,
          top: point.y,
          radius: 5,
          fill: 'rgb(178, 53, 84)',
          // originX: 'center',
          // originY: 'center',
          evented: false,
          selectable: false,
          // scaleX: 1 / this.canvas.getZoom(),
          // scaleY: 1 / this.canvas.getZoom(),
        });
        // );
        this.canvas.c.add(circle);
        this.tempObjects.push(circle);
      } else {
        const lastPoint = this.points[this.points.length - 1];
        const distance = lastPoint.distanceFrom(point);
        // console.log(distance, 'distancedistance');
        if (distance > 10) {
          this.points.push(point);
          // this.canvas.c.add(
          const circle = new this.fabric.Circle({
            left: point.x,
            top: point.y,
            radius: 5,
            fill: 'rgb(178, 53, 84)',
            originX: 'center',
            originY: 'center',
            evented: false,
            selectable: false,
          });
          // );
          this.canvas.c.add(circle);
          this.tempObjects.push(circle);
          const line = this.drawLine(lastPoint, point);
          this.tempObjects.push(line);
        }
      }
    },
    handleMouseUp() {
      if (!this.toggle) return;
      if (this.points.length > 2) {
        const firstPoint = this.points[0];
        const lastPoint = this.points[this.points.length - 1];
        const distance = firstPoint.distanceFrom(lastPoint);
        if (distance < 10) {
          this.finishPolygon();
        }
      }
    },
    drawLine(start, end) {
      const line = new this.fabric.Line([start.x, start.y, end.x, end.y], {
        stroke: 'black',
        selectable: false,
      });
      this.canvas.c.add(line);
      return line;
    },
    finishPolygon() {
      // console.log(this.points, 'this.pointsthis.points');
      // 获取所有 x 和 y 值
      const xValues = this.points.map((point) => point.x);
      const yValues = this.points.map((point) => point.y);

      // 计算最小值
      const minX = Math.min(...xValues);
      const minY = Math.min(...yValues);
      this.polygon = new this.fabric.Polygon(this.points, {
        dept: this.engravingDepth,
        id: uuid(),
        cutType: 'Mill Pocket',
        // stroke: 'red',
        // strokeWidth: 10,
        // selectable: false,
        left: minX, // 设置多边形的初始位置
        top: minY,
        zIndex: '00',
      });
      // console.log(this.polygon, 'this.polygonthis.polygonthis.polygon');
      this.canvas.c.add(this.polygon);
      this.canvas.c.renderAll();
      // console.log('Polygon added to canvas:', this.canvas.c);
      // 移除所有临时对象（点和线）
      this.tempObjects.forEach((obj) => this.canvas.c.remove(obj));
      this.tempObjects = []; // 清空临时对象数组
      // 清理状态
      this.toggle = false;
      this.points = [];
    },
    clearCanvas() {
      this.tempObjects.forEach((obj) => this.canvas.c.remove(obj));
      this.tempObjects = []; // 清空临时对象数组
      this.points = [];
      this.polygon = null;
    },
    importClick(index) {
      this.closeLine();
      if (index === 0) {
        this.insertGcode();
      } else if (index === 1) {
        this.insertSvg();
      } else if (index === 2) {
        this.showImage = true;
        this.insertImg();
      } else if (index === 4) {
        const objects = this.canvas.c.getObjects();
        objects.forEach((obj) => {
          if (obj.id !== 'workspace' && obj.id !== 'photo' && obj.id !== 'coordinate') {
            this.canvas.c.remove(obj);
          }
        });
        this.insertProject();
      } else if (index === 3) {
        this.importImage();
      }
      // console.log(index);
    },
    exportClick(index) {
      this.closeLine();
      if (index === 0) {
        this.exportProject();
      }
      // console.log(index);
    },

    addText(option) {
      const text = new this.fabric.IText(this.$t('everything_is_fine'), {
        ...defaultPosition,
        ...option,
        fontSize: 80,
        isLock: false,
        type: 'text',
        dept: this.engravingDepth,
        id: uuid(),
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(text);
      if (!option) {
        text.center();
      }
      this.canvas.c.setActiveObject(text);
    },
    // 文本
    // addTextBox(option) {
    //   const text = new this.fabric.Textbox(this.$t('everything_goes_well'), {
    //     ...defaultPosition,
    //     ...option,
    //     splitByGrapheme: true,
    //     width: 50,
    //     fontSize: 10,
    //     id: uuid(),
    //   });
    //   this.canvas.c.add(text);
    //   if (!option) {
    //     text.center();
    //   }
    //   this.canvas.c.setActiveObject(text);
    // },
    // 三角形
    addTriangle(option) {
      const triangle = new this.fabric.Triangle({
        ...defaultPosition,
        ...option,
        width: 100,
        height: 100,
        isLock: false,
        dept: this.engravingDepth,
        name: '三角形',
        id: uuid(),
        originX: 'center',
        originY: 'center',
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(triangle);
      if (!option) {
        triangle.center();
      }
      this.canvas.c.setActiveObject(triangle);
    },
    // 圆形
    addCircle(option) {
      const circle = new this.fabric.Circle({
        ...defaultPosition,
        ...option,
        isLock: false,
        radius: 50,
        dept: this.engravingDepth,
        id: uuid(),
        originX: 'center',
        originY: 'center',
        name: '圆形',
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(circle);
      if (!option) {
        circle.center();
      }
      this.canvas.c.setActiveObject(circle);
    },
    // 矩形
    addRect(option) {
      const rect = new this.fabric.Rect({
        ...defaultPosition,
        ...option,
        // fill: '#fff',
        dept: this.engravingDepth,
        isLock: false,
        width: 100,
        height: 100,
        rx: 0,
        ry: 0,
        originX: 'center',
        originY: 'center',
        id: uuid(),
        name: '矩形',
        cutType: 'Mill Pocket',
      });
      // const { left, top, width, height } = rect;
      // // 根据矩形的信息生成路径数据
      // const pathData = `M ${left},${top} h${width} v${height} h-${width} z`;
      // // console.log(pathData, 'pathDatapathData');

      // const path = new this.fabric.Path(pathData, {
      //   ...rect.toObject(['id', 'name', 'cutType', 'dept', 'fill', 'stroke']),
      //   objectClass: 'path',
      //   left,
      //   top,
      // });
      // // console.log(path, 'pathpath');

      this.canvas.c.add(rect);
      if (!option) {
        rect.center();
      }
      this.canvas.c.setActiveObject(rect);
    },
    // 多边形
    addPolygon(option) {
      const Polygon = new this.fabric.Polygon(getPolygonVertices(5, 50), {
        ...defaultPosition,
        ...option,
        isLock: false,
        dept: this.engravingDepth,
        width: 50,
        height: 50,
        originX: 'center',
        originY: 'center',
        id: uuid(),
        name: '正多边形',
        cutType: 'Mill Pocket',
      });
      this.canvas.c.add(Polygon);
      if (!option) {
        Polygon.center();
      }
      this.canvas.c.setActiveObject(Polygon);
    },
    closeLine() {
      // this.isArrow = false;
      this.drawHandler.setMode(false);
    },
    toggleDrawingLineMode() {
      // console.log(this.isArrow, 'this.isArrow');
      this.drawHandler.setMode(this.isArrow);
      this.drawHandler.setArrow(false);
      this.canvas.c.forEachObject((obj) => {
        if (obj.id !== 'workspace' && obj.id !== 'photo' && obj.id !== 'coordinate') {
          obj.selectable = this.isArrow;
          obj.evented = this.isArrow;
        }
      });
      // this.isArrow = !this.isArrow;
    },
    // 插入图片
    insertImg() {
      selectFiles({ accept: 'image/jpeg, image/png, image/bmp', multiple: false }).then(
        (fileList) => {
          this.imgLoading = true;
          // console.log(fileList, 'fileListfileList');
          const data = fileList[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(data);
          const formData = new FormData();
          formData.append('file', data);
          // Array.from(fileList).forEach((item) => {
          //   getImgStr(item).then((file) => {
          //     // console.log(file, 'filefile');
          importImg(formData).then((res) => {
            if (res.data.code === 200) {
              // console.log(res, 'res');
              // console.log(this.showImage, 'this.showImagethis.showImagethis.showImage');
              this.filePath = res.data.data.filePath;
              const svgUrlList = res.data.data.svg;
              this.svgUrl = svgUrlList.replace(/\\n/g, '\n').replace(/\\"/g, '"');
              this.imgLoading = false;
              this.callTargetMethod();
            }
          });
          //     // this.insertmgFile(file);
          //   });
          // });
        }
      );
    },
    // 导入图片
    importImage() {
      selectFiles({ accept: 'image/jpeg, image/png, image/bmp', multiple: false }).then(
        (fileList) => {
          console.log(fileList, 'fileListfileList');
          if (fileList && fileList.length > 0) {
            const imageObjects = this.canvas.c.getObjects();
            const foundItem = imageObjects.find((item) => item.zIndex === '01');
            const found = foundItem || null;
            const file = fileList[0];
            const reader = new FileReader();
            reader.onload = (e) => {
              const imgElement = new Image();
              imgElement.src = e.target.result;
              imgElement.onload = () => {
                // 获取工作区对象
                const objects = this.canvas.c.getObjects();
                const workSpace = objects.find((item) => item.id === 'workspace');

                // 创建fabric图片对象
                const fabricImage = new this.fabric.Image(imgElement, {
                  left: workSpace.left + workSpace.width / 2,
                  top: workSpace.top + workSpace.height / 2,
                  originX: 'center',
                  originY: 'center',
                  id: uuid(),
                  dept: this.engravingDepth,
                  cutType: 'Laser - Fill',
                  zIndex: '01',
                });
                if (found) {
                  // console.log(111111111111111);
                  // console.log(foundItem, 'foundItemfoundItem');
                  fabricImage.set('speed', foundItem.speed);
                  fabricImage.set('power', foundItem.power);
                  fabricImage.set('pass', foundItem.pass);
                  fabricImage.set('interval', foundItem.interval);
                } else {
                  // console.log(22222222222);
                  fabricImage.set('speed', 6000);
                  fabricImage.set('power', 20);
                  fabricImage.set('pass', 1);
                  fabricImage.set('interval', 0.1);
                }
                // 调整图片大小以适应工作区
                const maxWidth = workSpace.width * 0.8;
                const maxHeight = workSpace.height * 0.8;

                if (fabricImage.width > maxWidth || fabricImage.height > maxHeight) {
                  const scale = Math.min(
                    maxWidth / fabricImage.width,
                    maxHeight / fabricImage.height
                  );
                  fabricImage.scale(scale);
                }

                // 添加到画布并设为活动对象
                this.canvas.c.add(fabricImage);
                this.canvas.c.setActiveObject(fabricImage);
                this.canvas.c.renderAll();

                this.$message.success('success');
              };
            };
            reader.readAsDataURL(file);
          }
        }
      );
    },
    // 关闭图片处理
    closeImage() {
      this.showImage = false;
    },
    // 关闭Ai弹窗
    closeAi() {
      this.showAi = false;
    },
    // 关闭添加文字
    closeText() {
      this.showText = false;
    },
    // 导出gcode
    insertGcode() {
      selectFiles({ accept: '.gc', multiple: true }).then((fileList) => {
        // console.log(fileList, 'fileListfileList');
        // Array.from(fileList).forEach((item) => {
        //   getImgStr(item).then((file) => {
        // const blob = new Blob([file], { type: 'text/plain' }); // 设置适当的 MIME 类型
        this.sendBinaryFile(fileList[0]);
      });
    },
    // 插入Svg
    insertSvg() {
      selectFiles({ accept: '.svg', multiple: true }).then((fileList) => {
        Array.from(fileList).forEach((item) => {
          getImgStr(item).then((file) => {
            this.insertSvgFile(file);
          });
        });
      });
    },
    // 导入项目

    insertProject() {
      selectFiles({ accept: '.lac', multiple: true }).then((fileList) => {
        if (fileList && fileList.length > 0) {
          // const promises = Array.fom(fileList).map((file) => );
          const data = fileList[0];
          const formData = new FormData();
          formData.append('file', data);
          // console.log(fileList, 'fileList');
          // console.log(fileList[0].name, 'fileList');
          // console.log(formData, 'formDataformData');

          importPro(formData).then((res) => {
            console.log(res.data, 'data');
            const trueSvg = res.data.replace(/^[^<]+/, '').trim();

            // 解析SVG文档
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(trueSvg, 'image/svg+xml');

            // 直接从SVG中提取所有图形元素及其desc数据
            const elementsWithDesc = [];

            // 查找所有可能的图形元素
            const graphicElements = svgDoc.querySelectorAll(
              'circle, path, rect, polygon, polyline, ellipse'
            );

            graphicElements.forEach((element) => {
              const descElement = element.querySelector('desc');
              if (descElement) {
                try {
                  const descContent = descElement.textContent.trim();
                  const descData = JSON.parse(descContent);

                  // 获取元素的变换信息
                  const transform = element.getAttribute('transform');
                  let tx = 0;
                  let ty = 0;

                  if (transform && transform.includes('translate')) {
                    const matches = transform.match(/translate\(([^)]+)\)/);
                    if (matches && matches[1]) {
                      [tx, ty] = matches[1].split(/\s+|,/).map(parseFloat);
                    }
                  }

                  elementsWithDesc.push({
                    element,
                    descData,
                    transform: { tx, ty },
                    tagName: element.tagName.toLowerCase(),
                  });
                } catch (e) {
                  console.error('解析desc数据失败:', e);
                }
              }
            });

            // 加载SVG到Fabric
            this.fabric.loadSVGFromString(trueSvg, (objects) => {
              const filteredObjects = objects.filter(
                (obj) => obj.id !== 'workspace' && (!obj.fill || obj.fill !== '#F1F1F1')
              );

              // 为每个Fabric对象匹配正确的desc数据
              filteredObjects.forEach((obj, index) => {
                // 尝试找到匹配的元素
                let matchedElement = null;

                // 使用索引直接匹配（假设顺序一致）
                if (index < elementsWithDesc.length) {
                  matchedElement = elementsWithDesc[index];
                }

                obj.desc = matchedElement.descData;

                // 设置其他属性
                if (matchedElement.descData.targetDepth !== undefined)
                  obj.targetDepth = matchedElement.descData.targetDepth;
                if (matchedElement.descData.startDepth !== undefined)
                  obj.startDepth = matchedElement.descData.startDepth;
                if (matchedElement.descData.zIndex !== undefined)
                  obj.zIndex = matchedElement.descData.zIndex;
                if (matchedElement.descData.cutType !== undefined)
                  obj.cutType = matchedElement.descData.cutType;

                // 保存原始位置
                const originalLeft = matchedElement.descData.left;
                const originalTop = matchedElement.descData.top;

                // 设置中心原点
                obj.set('originX', 'center');
                obj.set('originY', 'center');
                obj.set('zIndex', matchedElement.descData.zIndex);
                obj.set('dept', matchedElement.descData.targetDepth);
                obj.set('power', matchedElement.descData.feedRate);
                obj.set('speed', matchedElement.descData.spindleSpeed);
                obj.set('interval', matchedElement.descData.interval);
                obj.set('pass', matchedElement.descData.passes);
                obj.set('cutType', matchedElement.descData.cutType);
                // 根据原点变化调整位置
                console.log(obj, 'objobjobjobjobjobjobj');

                if (obj.width && obj.height) {
                  obj.set({
                    left: originalLeft,
                    top: originalTop,
                  });
                }

                this.canvas.c.add(obj);
              });

              this.canvas.c.renderAll();
            });
          }); // console.log(111);
          // const promises = Array.from(fileList).map((file) => processFile(file));
          // const results = Promise.all(promises);
          // // console.log(results, 'results');
        } else {
          // console.log('没有选择文件');
        }
      });
    },
    // 导入gcode
    sendBinaryFile(file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('width', this.getWidth);
      formData.append('height', this.getHeight);
      formData.append('depth', this.getDept);
      // console.log(formData, 'formDataformData');

      importGcode(formData).then((res) => {
        if (res.status === 200) {
          this.$message.success('success');
          this.$emit('open-three');
          const blob = new Blob([file], { type: file.type });
          this.$store.dispatch('webSocket/setGCode', blob);
          // this.$emit('sss-dd');
          this.$store.dispatch('webSocket/setSendType', 'Gcode');
          const reader = new FileReader();
          console.log(res, 'resresres');
          const This = this;
          reader.onload = function (e) {
            console.log(e, 'eeeeee');
            const originalArrayBuffer = e.target.result;
            const a = {
              firstToolDiameter: This.firBit,
              secondToolDiameter: This.secBit,
              feedRate: This.feedRate,
              zFeedRate: This.zFeedRate,
              safeHeight: '3.8',
              toolStepOver: '0.3',
              depthPerPass: This.depthPerPass,
              spindleSpeed: This.spindleSpeed,
            };
            const jsonString = JSON.stringify(a);
            const encoder = new TextEncoder();
            const uint8Array = encoder.encode(jsonString);
            const arraylength = uint8Array.length;
            const originalUint8Array = new Uint8Array(originalArrayBuffer);
            // // 创建一个新的 Uint8Array，长度是原始的 + 1 字节
            // // 创建一个新的 Uint8Array，长度是原始的 + 1 字节
            const newArray = new Uint8Array(originalUint8Array.length + 3 + arraylength);
            // // 设置第一个字节为 1
            if (arraylength <= 127) {
              newArray[0] = 1;
              newArray[1] = 0;
              newArray[2] = arraylength;
            } else {
              // const firstDigit = parseInt(num.toString()[0], 10)
              // const lastTwoDigits = parseInt(num.toString().slice(-2), 10);
              newArray[0] = 1;
              newArray[1] = arraylength - 127;
              newArray[2] = 127;
            }
            newArray.set(uint8Array, 3);
            newArray.set(originalUint8Array, 3 + arraylength);
            // 发送二进制数据给服务器
            This.getSocket.send(newArray.buffer);
          };
          reader.readAsArrayBuffer(file);
        } else {
          this.$message.error(res.message);
        }
      });

      // reader.onerror = function (error) {
      //   // log('Error reading the file.');
      //   console.error(error);
      // };
    },

    // 插入svg元素
    insertSvgFile(svgFile) {
      // console.log(svgFile, 'svgFilesvgFilesvgFile');
      const This = this;
      const templateSize = This.canvas.c.getObjects();
      const masObj = templateSize.filter((element) => element.id === 'workspace');
      this.fabric.loadSVGFromURL(svgFile || this.svgFile, (objects, options) => {
        // 获取原始的宽度和高度
        let originalWidth = 0;
        let originalHeight = 0;
        // 遍历所有对象，找到最大边界框
        // console.log(objects, 'objectsobjectsobjects');
        objects.forEach((obj) => {
          const bbox = obj.getBoundingRect();
          // console.log(bbox, 'bboxbboxbbox');
          originalWidth = Math.max(originalWidth, bbox.width);
          originalHeight = Math.max(originalHeight, bbox.height);
        });

        // 如果没有找到任何对象，则返回
        if (originalWidth === 0 || originalHeight === 0) {
          console.error('SVG has no valid objects to determine dimensions.');
          return;
        }

        // // 指定宽度
        // const newWidth = masObj[0].height / 2;
        // // // 计算新高度
        // const newHeight = originalHeight * (newWidth / originalWidth);

        const item = This.fabric.util.groupSVGElements(objects, {
          ...options,
        });
        const workspaceleft = masObj[0].left;
        const workspaceTop = masObj[0].top + masObj[0].height - item.height * item.scaleY;
        item.set({
          zIndex: '00',
          cutType: 'Mill Pocket',
          id: uuid(),
          // scaleX: newWidth / originalWidth,
          // scaleY: newHeight / originalHeight,
          left: workspaceleft,
          top: workspaceTop,
          name: 'defaultSVG',
          dept: this.engravingDepth,
        });
        // console.log(item, 'itemitemitem');
        This.canvas.c.add(item);
        This.canvas.c.renderAll();
      });
    },

    // exportPro() {
    //   const data = {};
    //   exportPro(data);
    // },
    // 导出项目
    async exportProject() {
      // 1. 创建临时画布（不污染原始画布）
      const tempCanvas = new this.fabric.StaticCanvas(null, {
        width: this.canvas.c.width,
        height: this.canvas.c.height,
        // backgroundColor: this.canvas.c.backgroundColor,
      });

      // 2. 深度克隆函数（支持嵌套 Group）
      const deepClone = async (sourceObj) => {
        return new Promise((resolve) => {
          // 创建属性白名单（必须包含所有自定义属性）
          const customProps = [
            'zIndex',
            'speed',
            'power',
            'pass',
            'interval',
            'dept',
            'cutType',
            'src',
          ];

          // 执行克隆并携带白名单
          sourceObj.clone(async (clonedObj) => {
            if (clonedObj.type === 'path') {
              if (clonedObj.stroke === 'blue') {
                clonedObj.set({
                  stroke: null, // 关键修改：清除边框颜色
                  strokeWidth: 0,
                });
              }
              // 获取路径数据
              let pathData = clonedObj.path;

              // 检查并清理路径数据中的 NaN 值
              if (pathData && Array.isArray(pathData)) {
                // 过滤掉包含 NaN 的路径命令
                pathData = pathData.filter((cmd) => {
                  // 检查命令数组中是否有 NaN
                  if (Array.isArray(cmd)) {
                    return !cmd.some(
                      (val) => (typeof val === 'number' && Number.isNaN(val)) || val === 'NaN'
                    );
                  }
                  return true;
                });

                // 更新对象的路径数据
                clonedObj.path = pathData;
              }
            }
            // 1. 同步动态属性（必须前置）
            // clonedObj.index = sourceObj.index; // 显式传递index
            if (clonedObj.type === 'image') {
              // 将图片转换为内联数据URL
              try {
                const imgElement = sourceObj._element;
                if (imgElement && imgElement.complete) {
                  const canvas = document.createElement('canvas');
                  canvas.width = imgElement.naturalWidth || imgElement.width;
                  canvas.height = imgElement.naturalHeight || imgElement.height;
                  const ctx = canvas.getContext('2d');
                  ctx.drawImage(imgElement, 0, 0);

                  // 设置内联数据URL
                  const dataUrl = canvas.toDataURL('image/png');
                  clonedObj.setSrc(dataUrl, () => {
                    console.log(
                      clonedObj.stroke === 'blue',
                      "clonedObj.stroke === 'blue'clonedObj.stroke === 'blue'"
                    );
                    if (clonedObj.stroke === 'blue') {
                      clonedObj.set({
                        stroke: null, // 关键修改：清除边框颜色
                        strokeWidth: 0,
                      });
                    }
                    // 同步基础属性
                    clonedObj.set({
                      left: sourceObj.left,
                      top: sourceObj.top,
                      angle: sourceObj.angle,
                      scaleX: sourceObj.scaleX,
                      scaleY: sourceObj.scaleY,
                    });
                    resolve(clonedObj);
                  });
                  return; // 提前返回
                }
              } catch (e) {
                console.error('图片处理失败:', e);
              }
            }

            // 2. 处理嵌套对象（注意使用await保证顺序）
            if (clonedObj.type === 'group' && clonedObj._objects) {
              clonedObj._objects = await Promise.all(
                clonedObj._objects.map((child) => deepClone(child))
              );
            }

            // 3. 同步基础属性
            clonedObj.set({
              left: sourceObj.left,
              top: sourceObj.top,
              angle: sourceObj.angle,
              scaleX: sourceObj.scaleX,
              scaleY: sourceObj.scaleY,
            });
            resolve(clonedObj);
          }, customProps); // 关键点：传入白名单
        });
      };

      // 3. 克隆原始对象到临时画布
      const originalObjects = this.canvas.c.getObjects();
      const clonedObjects = await Promise.all(
        originalObjects
          .filter(
            (obj) =>
              obj.id !== 'workspace' &&
              obj.id !== 'photo' &&
              obj.id !== 'coordinate' &&
              !(obj instanceof this.fabric.Rect && obj.fill?.source instanceof HTMLCanvasElement)
          )
          .map((obj) => deepClone(obj))
      );
      console.log(clonedObjects, 'clonedObjectsclonedObjects');

      tempCanvas.add(...clonedObjects);
      tempCanvas.renderAll();

      // 4. 拆解 Group 的递归函数
      const deepUngroup = (canvas) => {
        const processGroup = (group) => {
          const groupTransform = group.calcTransformMatrix();

          group._objects.forEach((child) => {
            // 保存原始的翻转状态
            const originalFlipX = child.flipX || group.flipX || false;
            const originalFlipY = child.flipY || group.flipY || false;

            const childTransform = child.calcTransformMatrix();
            const finalTransform = this.fabric.util.multiplyTransformMatrices(
              groupTransform,
              childTransform
            );
            const options = this.fabric.util.qrDecompose(finalTransform);

            // 正确处理缩放和翻转
            child.set({
              left: options.translateX,
              top: options.translateY,
              angle: options.angle,
              scaleX: Math.abs(options.scaleX) * (originalFlipX ? -1 : 1),
              scaleY: Math.abs(options.scaleY) * (originalFlipY ? -1 : 1),
              skewX: options.skewX,
              skewY: options.skewY,
              flipX: originalFlipX,
              flipY: originalFlipY,
              group: null,
              originX: 'center',
              originY: 'center',
            });

            child.setCoords();
          });

          canvas.remove(group);
          canvas.add(...group._objects);
        };

        let groups;
        do {
          groups = canvas.getObjects().filter((obj) => obj.type === 'group');
          groups.forEach(processGroup);
        } while (groups.length > 0);
      };

      // 5. 在临时画布执行拆解
      deepUngroup(tempCanvas);
      console.log(tempCanvas, 'tempCanvastempCanvas');

      // 6. 注入 SVG 元数据
      // ... existing code ...
      tempCanvas.forEachObject((obj) => {
        if (obj.type === 'image') {
          const originalToSVG = obj.toSVG;
          obj.toSVG = function (reviver) {
            let svgString = originalToSVG.call(this, reviver);

            // 修复命名空间问题
            svgString = svgString.replace(
              /<image\s+/,
              '<image xmlns:xlink="http://www.w3.org/1999/xlink" '
            );

            return svgString;
          };
        }
        const originalToSVG = obj.toSVG;

        obj.toSVG = (reviver) => {
          let svgString = originalToSVG.call(obj, reviver);

          try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svgString, 'image/svg+xml');

            // 查找具体的图形元素
            const shapeElement = doc.querySelector(
              'rect, circle, ellipse, line, polyline, polygon, path,image'
            );

            if (shapeElement) {
              // 根据zIndex构建不同的desc内容
              let descData = {};
              if (obj.zIndex !== '00') {
                descData = {
                  feedRate: obj.speed || 0,
                  spindleSpeed: obj.power || 0,
                  passes: obj.pass || 1,
                  interval: obj.interval || 0,
                  zIndex: obj.zIndex || '00',
                  cutType: obj.cutType || 'Mill Pocket',
                  // 保存位置和变换信息
                  left: obj.left,
                  top: obj.top,
                  scaleX: obj.scaleX,
                  scaleY: obj.scaleY,
                  angle: obj.angle,
                  width: obj.width,
                  height: obj.height,
                };
              } else {
                descData = {
                  targetDepth: obj.dept || 0,
                  startDepth: 0,
                  zIndex: obj.zIndex || '00',
                  cutType: obj.cutType || 'Mill Pocket',
                  // 保存位置和变换信息
                  left: obj.left,
                  top: obj.top,
                  scaleX: obj.scaleX,
                  scaleY: obj.scaleY,
                  angle: obj.angle,
                  width: obj.width,
                  height: obj.height,
                };
              }

              // 创建desc元素
              const desc = doc.createElementNS('http://www.w3.org/2000/svg', 'desc');
              // desc.setAttribute('data-type', 'fabric-custom');
              desc.textContent = JSON.stringify(descData);

              // 插入到图形元素内
              shapeElement.appendChild(desc);
            }

            // 序列化并清理输出
            svgString = new XMLSerializer()
              .serializeToString(doc.documentElement)
              .replace(/\n/g, '')
              .replace(/\/>/g, '/>');
          } catch (error) {
            console.error('SVG 元数据注入失败:', error);
          }

          return svgString;
        };
      });

      // 5. 生成最终 SVG
      const dataUrl = tempCanvas.toSVG();
      console.log(dataUrl, 'dataUrl');
      console.log('11111111111');

      // const newSvgUrl = flattenSVG(dataUrl);
      // // console.log(newSvgUrl, 'newSvgUrlnewSvgUrl');

      const newblob = new Blob([dataUrl], { type: 'image/svg+xml' });
      // 基于 Blob 创建一个 File 对象
      const svgFile = new File([newblob], `Project${localStorage.getItem('canvasID')}.svg`, {
        type: 'image/svg+xml',
      });
      const formDatas = new FormData();
      formDatas.append('file', svgFile, `Project${localStorage.getItem('canvasID')}.svg`);
      // console.log(formDatas, 'formDatasformDatas');

      svgo(formDatas).then((res) => {
        const svgString = res.data;
        console.log(svgString, 'svgStringsvgString');

        this.useSvgo = formatSVG(svgString);
        const data = {
          id: localStorage.getItem('canvasID'),
          name: `Project${localStorage.getItem('canvasID')}`,
          userId: localStorage.getItem('id'),
          canvasUrl: this.canvasUrl,
          materialInfo: {
            // 材料尺寸
            simension: '{"X":"900 mm","Y":"1200 mm","Z":"19 mm"}',
            previewUrl: this.canvasUrl,
            color: '#FF0000',
            extInfo: '',
          },
          // 设备id
          machineId: localStorage.getItem('machineId'),
          previewUrl: this.canvasUrl,
        };
        const mar = originalObjects.find((item) => item.id === 'workspace');
        exportPro(data).then((ddd) => {
          if (ddd.code === 200) {
            const apiData = ddd.data;
            const fullSvgContent = `${apiData}\n${mar.left},${mar.top}\n${this.useSvgo}`;
            const fileStr = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
              fullSvgContent
            )}`;
            // console.log(fileStr, 'fileStrfileStrfileStr');
            this.downFile(fileStr, `Project${localStorage.getItem('canvasID')}.lac`);
          }
        });
      });
    },
    // 上传文件
    uploadFile(data) {
      uploadPro(data).then((res) => {
        if (res.code === 200) {
          this.canvasUrl = res.data;
          this.$emit('canvas-url', this.canvasUrl);
          this.uploadProject();
        } else {
          this.$message.error('保存失败');
        }
      });
    },
    // 更新项目
    uploadProject() {
      const data = {
        id: localStorage.getItem('canvasID'),
        name: `Project${localStorage.getItem('canvasID')}`,
        userId: localStorage.getItem('id'),
        canvasUrl: this.canvasUrl,
        materialInfo: {
          simension: '{"X":"19 mm","Y":"19 mm","Z":"19 mm"}',
          previewUrl: this.canvasUrl,
          color: '#FF0000',
          extInfo: '',
        },
        machineId: localStorage.getItem('machineId'),
        previewUrl: this.canvasUrl,
        gcodeUrl: this.canvasUrl,
      };
      updateProject(data).then((res) => {
        if (res.code === 200) {
          localStorage.setItem('canvasID', res.data);
          this.canvasId = res.data;
        }
      });
    },
    // 获取svg
    async getSvg() {
      // 1. 创建临时画布（不污染原始画布）
      const tempCanvas = new this.fabric.StaticCanvas(null, {
        width: this.canvas.c.width,
        height: this.canvas.c.height,
        // backgroundColor: this.canvas.c.backgroundColor,
      });

      // 2. 深度克隆函数（支持嵌套 Group）
      const deepClone = async (sourceObj) => {
        return new Promise((resolve) => {
          // 创建属性白名单（必须包含所有自定义属性）
          const customProps = [
            'zIndex',
            'speed',
            'power',
            'pass',
            'interval',
            'dept',
            'cutType',
            'src',
          ];

          // 执行克隆并携带白名单
          sourceObj.clone(async (clonedObj) => {
            if (clonedObj.type === 'path') {
              if (clonedObj.stroke === 'blue') {
                clonedObj.set({
                  stroke: null, // 关键修改：清除边框颜色
                  strokeWidth: 0,
                });
              }
              // 获取路径数据
              let pathData = clonedObj.path;

              // 检查并清理路径数据中的 NaN 值
              if (pathData && Array.isArray(pathData)) {
                // 过滤掉包含 NaN 的路径命令
                pathData = pathData.filter((cmd) => {
                  // 检查命令数组中是否有 NaN
                  if (Array.isArray(cmd)) {
                    return !cmd.some(
                      (val) => (typeof val === 'number' && Number.isNaN(val)) || val === 'NaN'
                    );
                  }
                  return true;
                });

                // 更新对象的路径数据
                clonedObj.path = pathData;
              }
            }
            if (clonedObj.type === 'image') {
              // 将图片转换为内联数据URL
              try {
                const imgElement = sourceObj._element;
                if (imgElement && imgElement.complete) {
                  const canvas = document.createElement('canvas');
                  canvas.width = imgElement.naturalWidth || imgElement.width;
                  canvas.height = imgElement.naturalHeight || imgElement.height;
                  const ctx = canvas.getContext('2d');
                  ctx.drawImage(imgElement, 0, 0);

                  // 设置内联数据URL
                  const dataUrl = canvas.toDataURL('image/png');
                  clonedObj.setSrc(dataUrl, () => {
                    if (clonedObj.stroke === 'blue') {
                      clonedObj.set({
                        stroke: null, // 关键修改：清除边框颜色
                        strokeWidth: 0,
                      });
                    }
                    // 同步基础属性
                    clonedObj.set({
                      left: sourceObj.left,
                      top: sourceObj.top,
                      angle: sourceObj.angle,
                      scaleX: sourceObj.scaleX,
                      scaleY: sourceObj.scaleY,
                    });
                    resolve(clonedObj);
                  });
                  return; // 提前返回
                }
              } catch (e) {
                console.error('图片处理失败:', e);
              }
            }
            // 2. 处理嵌套对象（注意使用await保证顺序）
            if (clonedObj.type === 'group' && clonedObj._objects) {
              clonedObj._objects = await Promise.all(
                clonedObj._objects.map((child) => deepClone(child))
              );
            }

            // 3. 同步基础属性
            clonedObj.set({
              left: sourceObj.left,
              top: sourceObj.top,
              angle: sourceObj.angle,
              scaleX: sourceObj.scaleX,
              scaleY: sourceObj.scaleY,
            });

            resolve(clonedObj);
          }, customProps); // 关键点：传入白名单
        });
      };

      // 3. 克隆原始对象到临时画布
      const originalObjects = this.canvas.c.getObjects();
      const clonedObjects = await Promise.all(
        originalObjects
          .filter(
            (obj) =>
              obj.id !== 'workspace' &&
              obj.id !== 'coordinate' &&
              obj.id !== 'photo' &&
              !(obj instanceof this.fabric.Rect && obj.fill?.source instanceof HTMLCanvasElement)
          )
          .map((obj) => deepClone(obj))
      );
      console.log(clonedObjects, 'clonedObjectsclonedObjects');

      tempCanvas.add(...clonedObjects);
      tempCanvas.renderAll();

      // 4. 拆解 Group 的递归函数
      const deepUngroup = (canvas) => {
        const processGroup = (group) => {
          const groupTransform = group.calcTransformMatrix();

          group._objects.forEach((child) => {
            // 保存原始的翻转状态
            const originalFlipX = child.flipX || group.flipX || false;
            const originalFlipY = child.flipY || group.flipY || false;

            const childTransform = child.calcTransformMatrix();
            const finalTransform = this.fabric.util.multiplyTransformMatrices(
              groupTransform,
              childTransform
            );
            const options = this.fabric.util.qrDecompose(finalTransform);

            // 正确处理缩放和翻转
            child.set({
              left: options.translateX,
              top: options.translateY,
              angle: options.angle,
              scaleX: Math.abs(options.scaleX) * (originalFlipX ? -1 : 1),
              scaleY: Math.abs(options.scaleY) * (originalFlipY ? -1 : 1),
              skewX: options.skewX,
              skewY: options.skewY,
              flipX: originalFlipX,
              flipY: originalFlipY,
              group: null,
              originX: 'center',
              originY: 'center',
            });

            child.setCoords();
          });

          canvas.remove(group);
          canvas.add(...group._objects);
        };

        let groups;
        do {
          groups = canvas.getObjects().filter((obj) => obj.type === 'group');
          groups.forEach(processGroup);
        } while (groups.length > 0);
      };

      // 5. 在临时画布执行拆解
      deepUngroup(tempCanvas);
      // 6. 注入 SVG 元数据
      // ... existing code ...
      tempCanvas.forEachObject((obj) => {
        if (obj.type === 'image') {
          const originalToSVG = obj.toSVG;
          obj.toSVG = function (reviver) {
            let svgString = originalToSVG.call(this, reviver);

            // 修复命名空间问题
            svgString = svgString.replace(
              /<image\s+/,
              '<image xmlns:xlink="http://www.w3.org/1999/xlink" '
            );

            return svgString;
          };
        }
        const originalToSVG = obj.toSVG;

        obj.toSVG = (reviver) => {
          let svgString = originalToSVG.call(obj, reviver);

          try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svgString, 'image/svg+xml');

            // 查找具体的图形元素
            const shapeElement = doc.querySelector(
              'rect, circle, ellipse, line, polyline, polygon, path,image'
            );

            if (shapeElement) {
              // 根据zIndex构建不同的desc内容
              let descData = {};
              if (obj.zIndex !== '00') {
                descData = {
                  feedRate: obj.speed || 0,
                  spindleSpeed: obj.power || 0,
                  passes: obj.pass || 1,
                  interval: obj.interval || 0,
                  zIndex: obj.zIndex || '00',
                  cutType: obj.cutType || 'Mill Pocket',
                  // 保存位置和变换信息
                  left: obj.left,
                  top: obj.top,
                  scaleX: obj.scaleX,
                  scaleY: obj.scaleY,
                  angle: obj.angle,
                  width: obj.width,
                  height: obj.height,
                };
              } else {
                descData = {
                  targetDepth: obj.dept || 0,
                  startDepth: 0,
                  zIndex: obj.zIndex || '00',
                  cutType: obj.cutType || 'Mill Pocket',
                  // 保存位置和变换信息
                  left: obj.left,
                  top: obj.top,
                  scaleX: obj.scaleX,
                  scaleY: obj.scaleY,
                  angle: obj.angle,
                  width: obj.width,
                  height: obj.height,
                };
              }

              // 创建desc元素
              const desc = doc.createElementNS('http://www.w3.org/2000/svg', 'desc');
              // desc.setAttribute('data-type', 'fabric-custom');
              desc.textContent = JSON.stringify(descData);

              // 插入到图形元素内
              shapeElement.appendChild(desc);
            }

            // 序列化并清理输出
            svgString = new XMLSerializer()
              .serializeToString(doc.documentElement)
              .replace(/\n/g, '')
              .replace(/\/>/g, '/>');
          } catch (error) {
            console.error('SVG 元数据注入失败:', error);
          }

          return svgString;
        };
      });
      const dataUrl = tempCanvas.toSVG();
      // console.log(dataUrl, 'dataUrldataUrldataUrl');

      // const newSvgUrl = flattenSVG(dataUrl);
      // // console.log(newSvgUrl, 'newSvgUrlnewSvgUrl');

      const newblob = new Blob([dataUrl], { type: 'image/svg+xml' });
      // 基于 Blob 创建一个 File 对象
      const svgFile = new File([newblob], `Project${localStorage.getItem('canvasID')}.svg`, {
        type: 'image/svg+xml',
      });
      const formDatas = new FormData();
      formDatas.append('file', svgFile, `Project${localStorage.getItem('canvasID')}.svg`);
      // console.log(formData, 'formDataformData');

      await this.uploadFile(formDatas);
    },

    // uploadToServer(file) {},
    downFile(fileStr, fileType) {
      const anchorEl = document.createElement('a');
      anchorEl.href = fileStr;
      anchorEl.download = `${fileType}`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    },
    closeVisable() {
      this.library = false;
    },

    // 图片处理
    debounceMethod() {
      if (this.timer) {
        clearTimeout(this.timer); // 清除之前的定时器
      }
      this.timer = setTimeout(() => {
        this.callTargetMethod();
      }, 1000); // 设置新的定时器，延迟两秒
    },
    callTargetMethod() {
      const data = {
        filePath: this.filePath,
        turdsize: this.controls.spotRemoval,
        alphamax: this.controls.smoothing,
        downsampling: this.controls.downsampling,
      };
      getProject(data).then((res) => {
        if (res.code === 200) {
          const svgUrlList = res.data.data.svg;
          this.svgUrl = svgUrlList.replace(/\\n/g, '\n').replace(/\\"/g, '"');
        }
      });
      // 在这里调用你想要的方法，例如更新服务器数据、重新渲染图表等
    },
    // Aisvg处理

    aiChange() {
      if (this.timer) {
        clearTimeout(this.timer); // 清除之前的定时器
      }
      this.timer = setTimeout(() => {
        this.aichangeMethod();
      }, 1000); // 设置新的定时器，延迟两秒
    },
    aichangeMethod() {
      const data = {
        filePath: this.aipath,
        turdsize: this.aiControl.spotRemoval,
        alphamax: this.aiControl.smoothing,
        downsampling: this.aiControl.downsampling,
      };
      getProject(data).then((res) => {
        if (res.code === 200) {
          const svgUrlList = res.data.data.svg;
          this.aiSvg = svgUrlList.replace(/\\n/g, '\n').replace(/\\"/g, '"');
        }
      });
      // 在这里调用你想要的方法，例如更新服务器数据、重新渲染图表等
    },
    // 高级素材库
    closeAdvanced() {
      this.advancedVis = false;
    },
  },
};
</script>

<style scoped lang="less">
.tool-box {
  display: flex;
  justify-content: space-around;
  span {
    flex: 1;
    text-align: center;
    padding: 5px 0;
    background: #f6f6f6;
    margin-left: 2px;
    cursor: pointer;
    &:hover {
      background: #edf9ff;
      svg {
        fill: #2d8cf0;
      }
    }
  }
  .bg {
    background: #d8d8d8;

    &:hover {
      svg {
        fill: #2d8cf0;
      }
    }
  }
}
.img {
  width: 20px;
}
.sidebar {
  width: ⅓;
  padding: 10px;
  background-color: #f4f4f4;
  border-right: 1px solid #ccc;
}

.upload-file input[type='file'] {
  display: none;
}

.upload-file label {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.workspace {
  width: 100%;
  height: 100%;
  position: relative;
  background: #cce6fd;
  flex: 1;
}

#canvas {
  width: 100%;
  height: 100%;
}

.properties-panel {
  position: absolute;
  top: 0;
  left: 100;
  width: 20px;
  padding: 10px;
  background-color: #f4f4f4;
  border-left: 1px solid #ccc;
}
img {
  width: 50px;
  height: 50px;
}
.list-container {
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-container li {
  padding: 5px;
  border-bottom: 1px solid #c2c2c2;
  position: relative;
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.childList {
  // background-color: white;
  height: 60px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.childList li {
  padding: 5px;
  background-color: white;
  border-bottom: 1px solid #c2c2c2;
  // float: left;
  position: relative;
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.secChildList {
  // display: inline-flex;
  // background-color: white;
  height: 60px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.secChildList li {
  padding: 5px;
  background-color: white;
  border-bottom: 1px solid #c2c2c2;
  // float: left;
  position: relative;
  width: 60px;
  height: 60px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 半透明黑色遮罩 */
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1; /* 确保遮罩层在内容之上 */
  pointer-events: none;
}

li:hover::before {
  opacity: 1; /* 鼠标悬停时显示遮罩层 */
}
.triangle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 0;
  border-color: transparent #000 transparent transparent;
  transition: border-color 0.3s;
}

.triangle.active {
  border-color: transparent red transparent transparent;
}
::v-deep .v-modal {
  display: none;
}

.dialog-content {
  display: flex;
}

.svg-display {
  position: relative;
  flex: 1;
  height: 300px; /* 确保有足够的高度 */
  // height: 300px; /* 确保有足够的高度 */
  border: 1px solid #ccc; /* 可选：添加边框 */
}
::v-deep .svg-display > svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* 确保 SVG 按比例缩放 */
}
.control-panel {
  flex: 1;
  padding-left: 20px; /* 添加一些间距 */
}

.block:hover {
  cursor: pointer;
}
// .tooltip {
//   z-index: 999; /* 确保 Tooltip 在遮罩层之上 */
// }

.tooltip-wrapper {
  background-color: #2d8cf0;
  // position: relative;
  // z-index: 1; /* 内容层在遮罩层之上 */
}
// .list-container li div {
//   display: flex; /* 使用弹性布局 */
//   align-items: center; /* 垂直居中 */
//   justify-content: center; /* 水平居中 */
//   height: 100%; /* 确保高度占满父级 */
// }
.item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
