<template>
  <!-- <div class="container"> -->
  <el-dialog
    title="Library"
    :visible.sync="vis"
    width="40%"
    :before-close="handleClose"
    :modal="false"
  >
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item style="width: 80%">
        <el-input v-model="formInline.user" type="text" clearable @clear="clearInput"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">Search</el-button>
      </el-form-item>
    </el-form>
    <el-tabs tab-position="left" @tab-click="tabClick" v-model="Animal">
      <el-tab-pane v-for="(item, index) in tabPane" :key="index" :label="item" :name="item">
        <div
          class="image-container"
          ref="imageContainer"
          v-infinite-scroll="loadMore"
          :infinite-scroll-distance="showMore"
        >
          <div v-for="(item, index) in imageList" :key="index" class="image-item" ref="imageItem">
            <el-image
              :src="item.previewUrl"
              class="image"
              :alt="item.altText || ''"
              @dblclick="handleImageDoubleClick(item.url)"
            />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
  <!-- </div> -->
</template>

<script>
import { getLibeaetList, getLibeaetType, getLibeaetTag } from '@/api/library';
import fetchSvgContent from '@/utils/svgService';

// import fetchPngAndConvertToSvg from '@/utils/potrace';

export default {
  name: 'design-library',
  inject: ['canvas', 'fabric'],
  props: {
    library: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    // 监听 Vuex 中的 socket 状态
    getDept() {
      return this.$store.state.material.dept;
    },
  },
  data() {
    return {
      vis: this.library,
      formInline: {
        user: '',
      },
      type: '',
      Animal: '',
      tag: '',
      pageNo: 1,
      pageSize: 50,
      tabPane: [],
      imageList: [],
      hasMore: true,
      showMore: 0,
      engravingDepth: 0,
    };
  },
  watch: {
    library(newVal) {
      this.vis = newVal;
      if (this.vis === true) {
        this.getType();
      }
    },
    vis(newVal) {
      this.$emit('update:library', newVal);
    },
  },
  mounted() {
    console.log(this.engravingDepth, 'this.getDeptthis.getDept');
    this.engravingDepth = this.getDept > 5 ? this.getDept / 2 : 3;
  },

  methods: {
    clearInput() {
      this.getType();
    },
    handleClose() {
      this.$emit('close-visible');
    },
    onSubmit() {
      this.pageNo = 1;
      this.pageSize = 40;
      this.tag = this.formInline.user;
      this.type = '';
      // this.Animal = '';
      this.getList();
      console.log(this.formInline.user, 'formInline.userformInline.user');
    },
    // 获取素材库列表
    getList() {
      const data = {
        type: this.type,
        tag: this.tag,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      getLibeaetList(data).then((res) => {
        if (res.code === 200) {
          console.log(res.data.length, 'res.data.lengthres.data.length');
          this.hasMore = res.data.length === this.pageSize; // 判断是否还有更多数据
          if (this.pageNo === 1) {
            this.imageList = res.data; // 第一页数据清空重新赋值
          } else {
            this.imageList = this.imageList.concat(res.data); // 追加数据
          }
          this.imageList = res.data;
          console.log(this.imageList, 'this.imageList');

          this.imageList.forEach((item, index) => {
            this.getImageDimensions(item.previewUrl, (width, height) => {
              if (width && height) {
                this.$nextTick(() => {
                  // 如果需要在获取尺寸后立即设置容器大小
                  const container = this.$refs.imageItem[index];
                  if (container) {
                    if (height > width) {
                      // 图片高度大于宽度，以高度为 150px
                      const imgWidth = `${width / (height / 150)}px`;
                      container.querySelector('.image').style.width = imgWidth;
                    }
                  }
                });
              }
            });
          });
        }
      });
    },
    async getType() {
      await getLibeaetType().then((res) => {
        if (res.code === 200) {
          this.tabPane = res.data;
          [this.type] = this.tabPane;
          [this.Animal] = this.tabPane;
        }
        console.log(res, 'ressss');
      });
      await this.getList();
    },
    getTag() {
      getLibeaetTag().then((res) => {
        console.log(res, 'ressssd');
      });
    },
    tabClick(e) {
      this.type = e.label;
      this.Animal = e.label;
      this.tag = '';
      this.getList();
    },
    loadMore() {
      console.log(this.hasMore, 'this.hasMore');
      if (this.hasMore) {
        this.pageSize += 20;
        this.getList();
      }
    },
    getImageDimensions(url, callback) {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        callback(img.naturalWidth, img.naturalHeight);
      };
      img.onerror = (error) => {
        console.error('Failed to load image:', error);
        callback(null, null);
      };
    },
    // 双击图片
    async handleImageDoubleClick(e) {
      const svgString = await fetchSvgContent(e);
      const This = this;
      This.fabric.loadSVGFromString(svgString, (objects, options) => {
        console.log(objects, 'objectsobjects');
        const templateSize = This.canvas.c.getObjects();
        const masObj = templateSize.filter((element) => element.id === 'workspace');
        console.log(masObj[0], 'masObjmasObj');
        const obj = This.fabric.util.groupSVGElements(objects, options);
        console.log(obj, 'objjjhjjj');
        if (obj._objects) {
          obj._objects.forEach((item) => {
            item.set({
              zIndex: '00',
              dept: This.engravingDepth,
              cutType: 'Mill Pocket',
            });
          });
        }

        const targetSize = masObj[0].height / 2;
        const originalHeight = obj.height;
        const scaleFactor = targetSize / originalHeight;
        const workspaceTop = masObj[0].top + masObj[0].height - (obj.height * scaleFactor) / 2;
        const workspaceleft = masObj[0].left + (obj.width * scaleFactor) / 2;
        // objects.forEach((item) => {

        obj.set({
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          left: workspaceleft,
          top: workspaceTop,
          originX: 'center',
          originY: 'center',
          zIndex: '00',
          dept: This.engravingDepth,
          cutType: 'Mill Pocket',
        });
        // });
        This.canvas.c.add(obj);
        // 可选：渲染画布
        This.canvas.c.renderAll();
      });
      this.$emit('close-visible');
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .v-modal {
  display: none;
}
::v-deep .el-form-item__content {
  width: 100%;
}
::v-deep .el-tab-pane {
  // display: flex;
  max-height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.image-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 创建6列 */
  grid-auto-rows: 150px;
  height: 100%;
  width: 100%;
}

.image-item {
  display: grid;
  place-items: center;
  height: 150px; /* 固定高度 */
  box-sizing: border-box; /* 确保 padding 和 border 不增加元素的总宽度 */
  padding: 5px; /* 可选：添加一些间距 */
  position: relative; /* 使 .image 相对于 .image-item 定位 */
}

.image {
  width: 100%; /* 占据整个 .image-item 容器的宽度 */
  object-fit: contain; /* 保持图像的宽高比，等比例缩放 */
}
::v-deep .el-dialog {
  height: 75%;
  overflow: hidden;
}
::v-deep .el-dialog__body {
  height: 100%;
  padding: 0 20px;
}
::v-deep .el-tabs--left {
  height: 88%;
}
::v-deep .el-tabs__content {
  height: 100%;
  // overflow-y: scroll;
}
::v-deep .el-tabs__nav-scroll {
  overflow-y: scroll;
}
</style>
