<template>
  <div>
    <!-- <el-form ref="form" :model="size" label-width="80px" style="display: flex" class="flex-form">
      <el-form-item label="木板长度">
        <el-input type="number" v-model="size.workWidth"></el-input>
      </el-form-item>
      <el-form-item label="木板高度">
        <el-input type="number" v-model="size.workHeight"></el-input>
      </el-form-item>
      <el-form-item label="木板厚度">
        <el-input type="number" v-model="size.dept"></el-input>
      </el-form-item>
      <el-form-item label="钻头直径">
        <el-input type="number" v-model="size.diameter"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="setSize">立即创建</el-button>
      </el-form-item>
    </el-form> -->
  </div>
</template>

<script>
import select from '@/mixins/select';
import EditorWorkspace from '@/core/EditorWorkspace';
// import EditorMaterals from '@/core/EditorMaterals';
import { millimeterToPx } from '@/utils/pixelConversion';
import { machineList } from '@/api/maschine';
// import EventBus from '@/utils/eventBus';

export default {
  name: 'canvasSize',
  mixins: [select],
  inject: ['canvas', 'fabric'],
  props: ['message'],
  data() {
    return {
      machinesData: [],
      size: {
        machineWidth: 300,
        machineHeight: 180,
        workWidth: 150,
        workHeight: 90,
        dept: 10,
      },
      // presetSize: [
      //   {
      //     label: this.$t('red_book_vertical'),
      //     width: 500,
      //     height: 500,
      //     dept: 20,
      //   },
      //   {
      //     label: this.$t('red_book_horizontal'),
      //     width: 1200,
      //     height: 900,
      //     dept: 20,
      //   },
      //   {
      //     label: this.$t('phone_wallpaper'),
      //     width: 1080,
      //     height: 1920,
      //     dept: 20,
      //   },
      // ],
    };
  },
  created() {
    // this.getMachineList();
  },
  mounted() {
    this.getMachineList().then(() => {
      this.updateDept();
      this.$emit('setSizeLoaded');
    });
    // const a = new EditorMaterals(this.canvas.c, {
    //   machineWidth: this.machineWidth * 0.03,
    //   machineHeight: this.machineHeight * 0.03,
    //   workWidth: this.workWidth * 0.03,
    //   workHeight: this.workHeight * 0.03,
    //   dept: this.dept * 0.03,
    // });
    // // console.log(a, 'aa');
  },
  methods: {
    getMachineList() {
      return new Promise((resolve) => {
        const data = {
          userId: localStorage.getItem('id'),
        };
        machineList(data)
          .then((res) => {
            // { machine: '1', added: '刀1', size: '3', setting: '60', hours: '60', projects: '60' },
            if (res.code === 200) {
              this.machinesData = res.data.map((item) => ({
                MachineName: item.machineName,
                size: JSON.parse(item.extInfo),
                id: item.id,
                userId: item.userId,
                isDefault: item.isDefault,
                updateTime: item.updateTime,
                machineType: item.machineType,
              }));
            }
            // console.log(this.machinesData, 'this.machinesDatathis.machinesDatathis.machinesData');
            const machin = this.machinesData.find((item) => item.isDefault === 1);
            this.$store.dispatch('machineSize/setMaschirWidth', machin.size.x);
            this.$store.dispatch('machineSize/setMaschirHeight', machin.size.y);
            this.size.machineWidth = this.$store.state.machineSize.maschirWidth;
            // console.log(this.size.machineWidth, 'this.size.machineWidththis.size.machineWidth');
            // console.log(this.size.maschirHeight, 'this.size.maschirHeight.size.maschirHeight');
            this.size.machineHeight = this.$store.state.machineSize.maschirHeight;
            this.size.workWidth = this.size.machineWidth;
            console.log(this.size.workWidth, 'this.size.workWidththis.size.workWidth');
            this.size.workHeight = this.size.machineHeight;
            console.log(this.size.workHeight, 'this.size.workWidththis.size.workHeight');
            // localStorage.setItem('dept', this.size.dept);
            this.canvas.editor.editorWorkspace = new EditorWorkspace(this.canvas.c, {
              machineWidth: millimeterToPx(this.size.machineWidth),
              machineHeight: millimeterToPx(this.size.machineHeight),
              workWidth: millimeterToPx(this.size.workWidth),
              workHeight: millimeterToPx(this.size.workHeight),
            });
            // 完成后解析 Promise
            resolve();
          })
          .catch((error) => {
            console.error('获取机器列表失败:', error);
            // 即使出错也要解析 Promise，以便继续执行
            resolve();
          });
      });
    },
    // 获取机器列表

    updateDept() {
      console.log('11111111111111111111111111111122222');
      this.$store.dispatch('material/setWidth', this.size.workWidth);
      this.$store.dispatch('material/setHeight', this.size.workHeight);
      this.$store.dispatch('material/setDept', this.size.dept);
      this.$emit('update:dept', Number(this.size.dept));
    },
    setSizeBy(machineWidth, machineHeight, workWidth, workHeight) {
      this.size.machineWidth = machineWidth;
      this.size.machineWidth = machineHeight;
      this.size.workWidth = workWidth;
      this.size.workHeight = workHeight;
      this.setSize();
    },
    setSize() {
      this.updateDept();
      // console.log('1111111111');
      // localStorage.setItem('dept', this.size.dept);
      this.canvas.editor.editorWorkspace.setSize(
        millimeterToPx(Number(this.size.machineWidth)),
        millimeterToPx(Number(this.size.machineHeight)),
        millimeterToPx(Number(this.size.workWidth)),
        millimeterToPx(Number(this.size.workHeight))
      );
      // EditorMaterals(this.width, this.height, this.dept);
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ivu-form-item {
  margin-bottom: 0;
}
/deep/ .ivu-divider-plain.ivu-divider-with-text-left {
  margin: 10px 0;
  font-weight: bold;
}
::v-deep .el-input {
  width: 100px;
}
.flex-form {
  display: flex;
  height: 100%;
  flex-direction: row; /* 水平排列 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中（如果需要的话） */
  // flex-direction: column; /* 设置为列布局 */
  // align-items: stretch; /* 让子元素拉伸以填满父元素 */
}

.flex-form .el-form-item {
  // display: flex;
  justify-content: center;
  align-items: center; /* 垂直居中 */
  margin-bottom: 10px; /* 可选：设置间距 */
}
// .flex-form .el-form-item__content {
//   flex-grow: 1; /* 让内容区域填充剩余空间 */
// }
</style>
