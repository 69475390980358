<template>
  <div style="display: flex" id="step8">
    <div class="CNC-button" @click="setCnc(10, '00')">CNC</div>
    <div class="color-list">
      <span
        v-for="(item, i) in colorList"
        :key="item"
        :style="`background:${item}`"
        @click="setLaser(item, '0' + (i + 1))"
      >
        {{ '0' + (i + 1) }}
      </span>
      <span class="el-icon-plus" @click="addColor" v-show="addShow"></span>
    </div>
  </div>
</template>

<script>
import select from '@/mixins/select';
import EventBus from '@/utils/eventBus';

export default {
  name: 'layer-ssss',
  mixins: [select],
  computed: {
    firBit() {
      return this.$store.state.materialBits.firBit;
    },
    showLaser() {
      return this.$store.state.showTab.showLaser;
    },
  },
  data() {
    return {
      addShow: true,
      colorList: ['#5F2B63', '#B23554', '#F27E56', '#FFD700'],
      availableColors: ['#33FF57', '#33FFF9', '#8B0000', '#006400', '#8B008B', '#FF1493'],
      index: 0, // 计数器，用于追踪当前应选择的颜色索引
    };
  },
  created() {
    // const activeObject = this.canvas.c.getActiveObjects()[0];
    // // console.log(activeObject, 'activeObject');
  },
  mounted() {},
  methods: {
    setCnc(color, zIndex) {
      this.setCncl(color, zIndex);
    },
    setLaser(color, zIndex) {
      // console.log(color, zIndex, 'zzzzzzzzzzz');
      this.setLayser(color, zIndex);
    },
    addColor() {
      if (this.index >= this.availableColors.length) {
        this.index = 0; // 如果计数器达到了数组的末尾，则重置为0
      }

      const randomColor = this.availableColors[this.index];
      this.index++; // 每次调用后增加计数器

      if (this.colorList.length === 9) {
        this.addShow = false;
      }
      // 将随机颜色添加到 colorList
      this.colorList.push(randomColor);
    },
    setLayser(color, layer) {
      this.$store.dispatch('showTab/toggleShowLaser', 'Laser');
      EventBus.$emit('updateTabClick', this.showLaser);
      const activeObject = this.canvas.c.getActiveObjects()[0];
      const objects = this.canvas.c.getObjects();
      const foundItem = objects.find((item) => item.zIndex === layer);
      const found = foundItem || null;
      console.log(activeObject, 'activeObjectactiveObjectactiveObject');
      // // console.log(
      //   this.canvas.c.getObjects(),
      //   'this.canvas.c.getActiveObjects()this.canvas.c.getActiveObjects()'
      // );
      // // console.log(
      //   activeObject.stroke === null &&
      //     activeObject.stroke === 'blue' &&
      //     activeObject.strokeWidth === 1,
      //   '11111111'
      // );

      if (activeObject.fill === null) {
        console.log(layer, 'layerlayerlayerlayerlayerlayer');
        activeObject.set('zIndex', layer);
        activeObject.set('fill', null); // 移除填充
        activeObject.set('stroke', color); // 设置描边颜色
        activeObject.set('strokeWidth', 1); // 设置描边颜色
        activeObject.set('cutType', 'Laser - On path');
        // console.log(activeObject, 'activeObjectactiveObjectactiveObjectactiveObject');
        this.canvas.c.renderAll();
      }
      if (activeObject.stroke === null || activeObject.stroke === 'blue') {
        console.log(layer, 'layerlayer');
        activeObject.set('zIndex', layer);
        activeObject.set('fill', color); // 移除填充
        activeObject.set('stroke', null); // 设置描边颜色
        activeObject.set('cutType', 'Laser - Fill');
        if (activeObject._objects) {
          activeObject._objects.forEach((item) => {
            // 修改属性值
            item.set('zIndex', layer); // 修改dept
            item.set('fill', color); // 修改dept
            item.set('stroke', null); // 修改dept
            item.set('cutType', 'Laser - Fill'); // 修改dept
            if (found) {
              // console.log(111111111111111);
              // console.log(foundItem, 'foundItemfoundItem');
              item.set('speed', foundItem.speed);
              item.set('power', foundItem.power);
              item.set('pass', foundItem.pass);
              item.set('interval', foundItem.interval);
            } else {
              // console.log(22222222222);
              item.set('speed', 6000);
              item.set('power', 20);
              item.set('pass', 1);
              item.set('interval', 0.1);
            }
          });
        }
        this.canvas.c.renderAll();
        // console.log(activeObject, 'activeObjectactiveObjectactiveObjectactiveObject');
      }
      if (found) {
        // // console.log(1111111111111112222);
        activeObject.set('speed', foundItem.speed);
        activeObject.set('power', foundItem.power);
        activeObject.set('pass', foundItem.pass);
        activeObject.set('interval', foundItem.interval);
      } else {
        // // console.log(22222222222);
        activeObject.set('speed', 6000);
        activeObject.set('power', 20);
        activeObject.set('pass', 1);
        activeObject.set('interval', 0.1);
      }
    },
    setCncl(dept, zIndex) {
      const activeObject = this.canvas.c.getActiveObjects()[0];
      if (activeObject.type === 'image') {
        return;
      }
      this.$store.dispatch('showTab/toggleShowLaser', 'Cut');
      if (activeObject.fill === null) {
        // console.log(zIndex, 'layerlayerlayerlayerlayerlayer');
        activeObject.set('zIndex', zIndex);
        // activeObject.set('fill', null); // 移除填充
        // activeObject.set('stroke', color); // 设置描边颜色
        activeObject.set('cutType', 'Mill - On path');
        // console.log(activeObject, 'activeObjectactiveObjectactiveObjectactiveObject');
        this.canvas.c.renderAll();
      }
      if (
        activeObject.stroke === null ||
        activeObject.stroke === 'blue' ||
        activeObject.strokeWidth === 1
      ) {
        // console.log(zIndex, 'layerlayer');
        activeObject.set('strokeWidth', this.firBit); // 设置描边颜色
        activeObject.set('zIndex', zIndex);
        // activeObject.set('fill', color); // 移除填充
        // activeObject.set('stroke', null); // 设置描边颜色
        activeObject.set('cutType', 'Mill Pocket');
        if (activeObject._objects) {
          activeObject._objects.forEach((item) => {
            // 修改属性值
            item.set('zIndex', zIndex); // 修改dept
            // item.set('fill', color); // 修改dept
            // item.set('stroke', null); // 修改dept
            item.set('cutType', 'Mill Pocket'); // 修改dept
          });
        }
        this.canvas.c.renderAll();
        // console.log(activeObject, 'activeObjectactiveObjectactiveObjectactiveObject');
      }

      activeObject.set('targetDepth', dept);
      activeObject.set('startDepth', 0);
    },
  },
};
</script>

<style lang="less" scoped>
.CNC-button {
  width: 100px;
  background: black;
  color: white;
  line-height: 30px;
  text-align: center;
  height: 30px;
}
.color-list {
  span {
    display: inline-block;
    color: black;
    line-height: 30px;
    text-align: center;
    height: 30px;
    width: 30px;
    margin-left: 5px;
  }
  .el-icon-plus {
    color: black;
  }
}
</style>
