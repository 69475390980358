// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  showLaser: false,
  showAddTabs: true,
  changePassword: false,
};
const mutations = {
  toggleShowLaser(aaa, sss) {
    aaa.showLaser = sss;
  },
  showAddTabs(aaa, sss) {
    aaa.showAddTabs = sss;
  },
  setChangePassword(aaa, sss) {
    aaa.changePassword = sss;
  },
};
const actions = {
  toggleShowLaser({ commit }, sss) {
    commit('toggleShowLaser', sss);
  },
  showAddTabs({ commit }, sss) {
    commit('showAddTabs', sss);
  },
  setChangePassword({ commit }, sss) {
    commit('setChangePassword', sss);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
