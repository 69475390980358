// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  firBit: null,
  secBit: null,
  material: null,
  feedRate: null,
  zFeedRate: null,
  spindleSpeed: null,
  depthPerPass: null,
  firBitName: null,
  secBitName: null,
  firvAngle: 0,
  secvAngle: 0,
};
const mutations = {
  firBitDiameter(aaa, sss) {
    aaa.firBit = sss;
  },
  secBitDiameter(aaa, sss) {
    console.log(sss, 'ssss');

    aaa.secBit = sss;
  },
  material(aaa, sss) {
    aaa.material = sss;
  },
  feedRate(aaa, sss) {
    aaa.feedRate = sss;
  },
  zFeedRate(aaa, sss) {
    aaa.zFeedRate = sss;
  },
  spindleSpeed(aaa, sss) {
    aaa.spindleSpeed = sss;
  },
  depthPerPass(aaa, sss) {
    aaa.depthPerPass = sss;
  },
  setFirBitName(aaa, sss) {
    console.log(sss, 'sssssssssssssss');
    aaa.firBitName = sss;
  },
  setSecBitName(aaa, sss) {
    aaa.secBitName = sss;
  },
  setFirvAngle(aaa, sss) {
    aaa.firvAngle = sss;
  },
  setSecvAngle(aaa, sss) {
    aaa.secvAngle = sss;
  },
};
const actions = {
  firBitDiameter({ commit }, sss) {
    console.log('111111111111111111');
    commit('firBitDiameter', sss);
  },
  secBitDiameter({ commit }, sss) {
    commit('secBitDiameter', sss);
  },
  material({ commit }, sss) {
    commit('material', sss);
  },
  feedRate({ commit }, sss) {
    commit('feedRate', sss);
  },
  zFeedRate({ commit }, sss) {
    commit('zFeedRate', sss);
  },
  spindleSpeed({ commit }, sss) {
    commit('spindleSpeed', sss);
  },
  depthPerPass({ commit }, sss) {
    commit('depthPerPass', sss);
  },
  setFirBitName({ commit }, sss) {
    commit('setFirBitName', sss);
  },
  setSecBitName({ commit }, sss) {
    commit('setSecBitName', sss);
  },
  setFirvAngle({ commit }, sss) {
    commit('setFirvAngle', sss);
  },
  setSecvAngle({ commit }, sss) {
    commit('setSecvAngle', sss);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
