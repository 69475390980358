<template>
  <div
    style="
      width: 100%;
      height: 100%;
      /* padding: 10px; */
      overflow-y: auto;
      position: absolute;
      z-index: 777;
      top: 0;
      right: 0;
    "
  >
    <el-tabs
      id="step4"
      type="border-card"
      @tab-click="tabClick(activeName)"
      v-model="activeName"
      style="height: 100%"
    >
      <el-tab-pane label="Cut" name="Cut" v-if="showLaser === 'Cut'">
        <div style="margin-bottom: 20px">
          <Divider plain orientation="left">{{ $t('attributes.materialPull') }}</Divider>
          <el-select v-model="materialPull" placeholder="change" @change="materialChange">
            <el-option
              v-for="item in materialList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px">
          <Divider plain orientation="left">{{ $t('attributes.firstbit') }}</Divider>
          <el-select v-model="firstBit" placeholder="change" @change="firstBitChange">
            <el-option
              v-for="item in bitList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div style="margin-bottom: 20px">
          <Divider plain orientation="left">{{ $t('attributes.secbit') }}</Divider>
          <div style="display: flex; justify-content: space-between; align-items: center">
            <el-select
              v-model="secBit"
              placeholder="change"
              @change="secBitChange"
              :disabled="!secCheck"
            >
              <el-option
                v-for="item in bitList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-checkbox
              v-model="secCheck"
              style="margin-left: 5px"
              @change="secChange"
            ></el-checkbox>
          </div>
        </div>
        <div style="display: flex">
          <div class="left-cut">
            <Divider plain orientation="left">{{ $t('attributes.dept') }}</Divider>
            <el-input v-model.number="sliderValue" @change="inputChange"></el-input>
            <div v-if="sliderValue === maxDept ? true : false">
              <Divider plain orientation="left">{{ $t('attributes.adddepth') }}</Divider>
              <el-select v-model="addDepthValue" @change="addDeptChange">
                <el-option
                  v-for="item in addDepthOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <Divider plain orientation="left">{{ $t('attributes.feedRate') }}</Divider>
            <el-input v-model.number="feedRate" @change="feedRateChange"></el-input>
            <Divider plain orientation="left">{{ $t('attributes.zFeedRate') }}</Divider>
            <el-input v-model.number="zFeedRate" @change="zFeedRateChange"></el-input>
            <Divider plain orientation="left">{{ $t('attributes.spindleSpeed') }}</Divider>
            <el-input v-model.number="spindleSpeed" @change="spindleChange"></el-input>
            <Divider plain orientation="left">{{ $t('attributes.depthPerPass') }}</Divider>
            <el-input v-model.number="depthPerPass" @change="depthPerChange"></el-input>
            <!-- <div style="margin-top: 10px">
              <img src="@/assets/fillside/fill.png" alt="" style="width: 50px" />
              <div>{{ $t('attributes.shift') }}</div>
              <el-input type="text" v-model="shiftValue"></el-input>
            </div> -->
          </div>
          <div class="right-cut">
            <el-slider
              v-model="sliderValue"
              :min="0"
              :max="maxDept"
              height="300px"
              vertical
              :marks="marks"
              @change="sliderChange"
              @input="sliderInput"
            ></el-slider>
          </div>
        </div>
        <div>
          <Divider plain orientation="left">{{ $t('attributes.cutpath') }}</Divider>
          <div>
            <div class="flex-view">
              <div class="flex-item" style="text-align: center; justify-content: center">
                <div class="size-content">
                  <div style="position: relative; text-align: center; background: #f6f7f9">
                    <el-dropdown trigger="click" @command="handleFontSelect">
                      <img src="@/assets/fillside/onpath.png" alt="" style="width: 60px" />
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="changeCommon('onpath', '#57606B')">
                          <img src="@/assets/fillside/onpath.png" alt="" style="width: 50px" />
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="changeCommon('inside', '#57606B')">
                          <img src="@/assets/fillside/inside.png" alt="" style="width: 50px" />
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="changeCommon('outside', '#57606B')">
                          <img src="@/assets/fillside/outside.png" alt="" style="width: 50px" />
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </div>
              <div class="flex-item" style="text-align: center; justify-content: center">
                <div class="size-content">
                  <img
                    src="@/assets/fillside/fill.png"
                    alt="Fill Button"
                    style="width: 60px; cursor: pointer"
                    @click="changeCommon('fill', '#57606B')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showAddTabs">
          <Divider plain orientation="left">{{ $t('attributes.usetabs') }}</Divider>
          <div>Quantity</div>
          <el-input v-model.number="tabs" @change="inputChange"></el-input>
        </div>

        <div v-html="assasa"></div>
      </el-tab-pane>

      <el-tab-pane label="Laser" name="Laser" v-if="showLaser === 'Laser'" class="laser-tab">
        <div style="align-self: flex-start; width: 100%">
          <div style="margin-bottom: 20px">
            <Divider plain orientation="left">{{ $t('attributes.materialPull') }}</Divider>
            <el-select v-model="materialPull" placeholder="change" @change="materialChange">
              <el-option
                v-for="item in materialList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            @row-click="rowClick"
            max-height="250"
          >
            <el-table-column prop="layer" label="Layer" width="60" align="center"></el-table-column>
            <el-table-column prop="mode" label="Mode" width="100" align="center">
              <template slot-scope="scope">
                <el-select v-model="scope.row.mode" placeholder="change" @change="selectChange">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="speed"
              label="Speed/Power"
              align="center"
              width="130"
            ></el-table-column>
          </el-table>
          <div>
            <el-form ref="form" :model="attribute" label-width="80px">
              <el-form-item label="Speed">
                <InputNumber
                  v-model="attribute.speed"
                  @on-change="(value) => speedChange(value)"
                  :min="60"
                  :max="6000"
                  :step="60"
                  show-input
                ></InputNumber>
              </el-form-item>
              <el-form-item label="Power">
                <InputNumber
                  v-model="attribute.power"
                  @on-change="(value) => powerChange(value)"
                  :min="0"
                  :max="100"
                  :step="10"
                  show-input
                ></InputNumber>
              </el-form-item>
              <el-form-item label="Pass">
                <InputNumber
                  v-model="attribute.pass"
                  @on-change="(value) => passChange(value)"
                  :min="1"
                  :max="10"
                  :step="1"
                  show-input
                ></InputNumber>
              </el-form-item>
              <el-form-item label="Interval">
                <InputNumber
                  v-model="attribute.interval"
                  @on-change="(value) => intervalChange(value)"
                  :min="0.1"
                  :max="10"
                  :step="0.1"
                  show-input
                ></InputNumber>
              </el-form-item>
            </el-form>
            <!-- <ul>
          <li>
            speed:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Power:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Pass:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Interval:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
        </ul> -->
            <Divider plain orientation="left">{{ $t('attributes.cutpath') }}</Divider>
            <div>
              <div class="flex-view">
                <div class="flex-item" style="text-align: center; justify-content: center">
                  <div class="size-content">
                    <img
                      src="@/assets/fillside/onpath.png"
                      alt="Fill Button"
                      style="width: 60px; cursor: pointer"
                      @click="changeCommon('onpath', '#57606B')"
                    />
                  </div>
                </div>
                <div class="flex-item" style="text-align: center; justify-content: center">
                  <div class="size-content">
                    <img
                      src="@/assets/fillside/fill.png"
                      alt="Fill Button"
                      style="width: 60px; cursor: pointer"
                      @click="changeCommon('fill', '#57606B')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showImage" style="align-self: flex-end">
          <el-image :src="url" :preview-src-list="bigUrl"></el-image>
        </div>
      </el-tab-pane>
      <el-tab-pane label="General" name="General">
        <div>
          <div style="padding: 0 10px">
            <div>
              <span>Machine Name:</span>
              <span>{{ maschineName ? maschineName : 'none' }}</span>
            </div>
            <div>
              <span>Connect:</span>
              <span>{{ connectionStatus }}</span>
            </div>
          </div>
          <div style="margin-top: 10px; display: flex; line-height: 52px" id="select">
            <el-select
              v-model="selectValue"
              placeholder="Choose"
              class="select-com"
              style="width: 50%"
            >
              <el-option
                v-for="item in getCom"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-button
              style="margin: 5px 0 5px 10px; width: 50%"
              @click="connectLaser"
              type="primary"
            >
              Connect
            </el-button>
          </div>
          <Debugdialog></Debugdialog>
          <div style="padding: 0 10px">
            <div>
              <span>Work Pos:</span>
              <span>{{ getWorkPosition }}</span>
            </div>
            <div>
              <span>Machine Pos:</span>
              <span>{{ getHomePosition }}</span>
            </div>
          </div>
          <div class="button-container" style="display: flex">
            <el-button type="primary" @click="goHome" class="equal-button">Home</el-button>
            <el-button type="primary" @click="hold" class="equal-button">Hold</el-button>
            <el-button type="primary" @click="unlock" class="equal-button">Unlock</el-button>
          </div>
          <div class="button-container" style="display: flex; margin: 10px 0">
            <el-button type="primary" @click="reset" class="equal-button">Reset</el-button>
            <el-button
              type="primary"
              @mousedown.native="handleMouseDown"
              @mouseup.native="handleMouseUp"
              class="equal-button"
            >
              Spindle
            </el-button>
          </div>
          <div style="display: flex">
            <el-button type="primary" @click="photograph" class="equal-button">Update BG</el-button>
            <el-button type="primary" @click="removePhotoOverlay" class="equal-button">
              Remove BG
            </el-button>
          </div>
          <div style="margin: 10px 0; display: flex; line-height: 60px">
            <el-select
              v-model="lamplightValue"
              placeholder="Choose"
              class="select-com"
              @change="lamplightChange"
              style="width: 50%; padding: 0 5px"
            >
              <el-option
                v-for="item in lamplightList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-button
              style="margin: 10px 5px 10px 10px"
              type="primary"
              @click="okRGB"
              class="equal-button"
            >
              Set RGB
            </el-button>
          </div>
          <!-- 在适当的位置添加按钮 -->
          <!-- <el-button type="success" @click="sendGcode" size="small">Preview</el-button> -->
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import fontList from '@/assets/fonts/font';
import select from '@/mixins/select';
// import FontFaceObserver from 'fontfaceobserver';
import { millimeterToPx, inchToPx, perMillimeter } from '@/utils/pixelConversion';
import initAligningGuidelines from '@/core/initAligningGuidelines';
// import * as opentype from 'opentype.js';
import Debugdialog from '@/views/home/components/Debugdialog/index.vue';
import { machineList } from '@/api/maschine';
import { materList, bitList } from '@/api/materialbit';
import EventBus from '@/utils/eventBus';
// import EditorWorkspace from '@/core/EditorWorkspace';

// import attribute from '@/components/attribute.vue';

export default {
  name: 'on-shape',
  mixins: [select],
  //   components: {
  //     attribute,
  //   },
  props: ['updateDept'],
  watch: {
    updateDept(newVal) {
      // console.log(newVal, 'newValnewVal111');
      this.maxDept = newVal;
      this.marks = {};
      const step = this.maxDept / 2;
      for (let i = 0; i <= 2; i++) {
        const key = Math.round(i * step);
        this.marks[Number(key)] = String(key);
      }
    },
    showLaser(newValue) {
      // console.log(this.showLaser, 'this.showLaserthis.showLaserthis.showLaser');
      this.activeName = newValue;
    },
    getJoin: {
      immediate: true, // 在组件创建时立即执行一次
      handler(newValue) {
        this.connectionStatus = newValue ? 'Connected' : 'Disconnected';
      },
    },
  },

  computed: {
    firBit() {
      return this.$store.state.materialBits.firBit;
    },
    showLaser() {
      return this.$store.state.showTab.showLaser;
    },
    showAddTabs() {
      return this.$store.state.showTab.showAddTabs;
    },
    getSocket() {
      return this.$store.state.webSocket.showLaser;
    },
    getJoin() {
      // console.log(this.$store.state.webSocket.sendJoin, 'getJoingetJoingetJoingetJoin');
      return this.$store.state.webSocket.sendJoin;
    },
    getCom() {
      // console.log(this.$store.state.webSocket.sendCom, 'getComgetComgetComgetCom');
      return this.$store.state.webSocket.sendCom;
    },
    getWorkPosition() {
      // console.log(this.$store.state.webSocket.sendCom, 'getComgetComgetComgetCom');
      return this.$store.state.progress.workPosition;
    },
    getHomePosition() {
      // console.log(this.$store.state.webSocket.sendCom, 'getComgetComgetComgetCom');
      return this.$store.state.progress.homePosition;
    },
  },
  components: {
    Debugdialog,
  },
  data() {
    return {
      // 灯光
      lamplightValue: '',
      lamplightList: [
        { label: 'Close', value: 'closeRGB' },
        { label: 'Blue', value: 'RGBS0xFF' },
        { label: 'Red', value: 'RGBS0xFF00' },
        { label: 'Green', value: 'RGBS0xFF0000' },
        { label: 'White', value: 'RGBS0xFFFFFF' },
      ],
      isV: false,
      firvAngle: 0,
      secvAngle: 0,

      connectionStatus: 'Disconnected',
      bitList: [],
      firstBit: '',
      secBit: '',
      secCheck: false,
      url: null,
      bigUrl: null,
      materialList: [],
      materialPull: 'Pine',
      action: null,
      join: false,
      comOptions: [],
      defaultMachine: {},
      maschineId: null,
      maschineName: null,
      selectValue: '',
      activeName: 'General',
      edit: true,
      tabs: 4,
      bitDiametert: 0,
      maxDept: 0,
      sliderValue: 0,
      feedRate: 0,
      zFeedRate: 0,
      spindleSpeed: 0,
      depthPerPass: 0,
      shiftValue: 0,
      marks: {},
      addDepthValue: 0,
      addDepthOptions: [
        {
          value: 0,
          label: 'none',
        },
        {
          value: 0.2,
          label: '0.2mm',
        },
        {
          value: 0.5,
          label: '0.5mm',
        },
        {
          value: 1,
          label: '1mm',
        },
        {
          value: 3,
          label: '3mm',
        },
      ],
      isLock: false,
      baseType: [
        'polygon',
        'text',
        'i-text',
        'textbox',
        'rect',
        'circle',
        'triangle',
        'image',
        'group',
        'line',
        'arrow',
        'path',
      ],
      // 通用属性
      baseAttr: {
        opacity: 0,
        angle: 0,
        fill: '#fff',
        strokeWidth: 0,
        strokeDashArray: [],
        stroke: '#fff',
        shadow: {
          color: '#fff',
          blur: 0,
          offsetX: 0,
          offsetY: 0,
        },
      },
      assasa: '',
      // 字体属性
      fontAttr: {
        fontSize: 0,
        fontFamily: '',
        lineHeight: 0,
        charSpacing: 0,
        fontWeight: '',
        textBackgroundColor: '#fff',
        textAlign: '',
        fontStyle: '',
        underline: false,
        linethrough: false,
        overline: false,
      },
      // 字体下拉列表
      fontFamilyList: fontList.map((item) => item.fontFamily),
      options: [
        {
          value: 'Line',
          label: 'Stroke',
        },
        {
          value: 'fill',
          label: 'Fill',
        },
      ],
      tableData: [],
      attribute: {
        speed: 6000,
        power: 20,
        pass: 1,
        interval: 0.1,
      },
      clickRow: {},
      form: {},
      clickActive: null,
      isDragging: false,
      color: null,
      breakpoints: [],
    };
  },
  created() {
    this.$store.dispatch('showTab/toggleShowLaser', 'General');
    this.getMachineList();
    this.getMaterialList();
    this.getBitList();
    this.activeName = this.showLaser;
    // this.connectToHardware();
    this.event.on('selectCancel', () => {
      this.baseAttr.fill = '';
      this.$forceUpdate();
    });
    this.event.on('selectOne', () => {
      this.bindEventListeners();
      const objects = this.canvas.c.getObjects();
      const workSpace = objects.find((item) => item.id === 'workspace');
      // const machine = objects.find((item) => (item.id = 'machine'));
      const [clickActive] = this.canvas.c.getActiveObjects();
      this.clickActive = clickActive;
      // this.color = this.clickActive.fill;
      this.sliderValue = this.clickActive.dept ? this.clickActive.dept : 0;
      const activeObject = this.canvas.c.getActiveObjects()[0];
      if (activeObject.zIndex === '00') {
        this.activeName = 'Cut';
        this.$store.dispatch('showTab/toggleShowLaser', 'Cut');
      } else if (
        activeObject.zIndex === '01' ||
        activeObject.zIndex === '02' ||
        activeObject.zIndex === '03' ||
        activeObject.zIndex === '04' ||
        activeObject.zIndex === '05' ||
        activeObject.zIndex === '06' ||
        activeObject.zIndex === '07' ||
        activeObject.zIndex === '08' ||
        activeObject.zIndex === '09' ||
        activeObject.zIndex === '10'
      ) {
        this.activeName = 'Laser';
        this.$store.dispatch('showTab/toggleShowLaser', 'Laser');
      } else {
        this.$store.dispatch('showTab/toggleShowLaser', 'General');
      }
      if (activeObject) {
        // console.log(activeObject, 'activeObjectactiveObject');
        // console.log(activeObject.type, 'activeObject.type');

        if (this.showLaser === 'Laser') {
          this.tabClick(this.showLaser);
        }
        // this.attribute.speed = activeObject.speed ? activeObject.speed : 6000;
        // this.attribute.power = activeObject.power ? activeObject.power : 20;
        // this.attribute.pass = activeObject.pass ? activeObject.pass : 1;
        // this.attribute.interval = activeObject.interval ? activeObject.interval : 0.1;
        // base
        this.baseAttr.opacity = activeObject.get('opacity') * 100;
        this.baseAttr.fill = activeObject.get('fill');
        this.baseAttr.left = `${perMillimeter(
          Number((activeObject.get('left') - workSpace.left).toFixed(2))
        ).toFixed(1)}`;
        this.baseAttr.top = `${perMillimeter(
          Number(
            (
              workSpace.height +
              workSpace.top -
              activeObject.get('top') -
              activeObject.get('height')
            ).toFixed(2)
          )
        ).toFixed(1)}`;
        this.baseAttr.width = `${perMillimeter(
          Number(activeObject.get('width') * activeObject.get('scaleX')).toFixed(2)
        ).toFixed(1)}`;
        this.baseAttr.height = `${perMillimeter(
          Number(activeObject.get('height') * activeObject.get('scaleY')).toFixed(2)
        ).toFixed(1)}`;
        this.baseAttr.stroke = activeObject.get('stroke');
        this.baseAttr.strokeWidth = activeObject.get('strokeWidth');
        this.baseAttr.shadow = activeObject.get('shadow') || {};
        this.baseAttr.angle = activeObject.get('angle') || 0;
        if (this.mSelectOneType === 'rect') {
          this.baseAttr.rx = `${perMillimeter(Number(activeObject.get('rx').toFixed(2))).toFixed(
            1
          )}`;
          this.baseAttr.ry = `${perMillimeter(Number(activeObject.get('ry').toFixed(2))).toFixed(
            1
          )}`;
          this.isLock = activeObject.get('isLock');
        }
        const textTypes = ['i-text', 'text', 'textbox'];
        if (textTypes.includes(activeObject.type)) {
          this.fontAttr.fontSize = activeObject.get('fontSize');
          this.fontAttr.fontFamily = activeObject.get('fontFamily');
          this.fontAttr.lineHeight = activeObject.get('lineHeight');
          this.fontAttr.textAlign = activeObject.get('textAlign');
          this.fontAttr.underline = activeObject.get('underline');
          this.fontAttr.linethrough = activeObject.get('linethrough');
          this.fontAttr.charSpacing = activeObject.get('charSpacing');
          this.fontAttr.overline = activeObject.get('overline');
          this.fontAttr.fontStyle = activeObject.get('fontStyle');
          this.fontAttr.textBackgroundColor = activeObject.get('textBackgroundColor');
          this.fontAttr.fontWeight = activeObject.get('fontWeight');
        }
        this.getColor();
        if (this.baseAttr.fill) {
          if (this.clickActive.zIndex === '00') {
            this.clickActive.set('fill', this.getColor());
          } else {
            this.clickActive.set('fill', this.baseAttr.fill);
          }
          console.log(this.clickActive, 'this.baseAttrthis.baseAttr');
          console.log('111');
        } else {
          return null;
        }
        this.canvas.c.renderAll();

        // this.sliderInput();
        // 图片滤镜
        // if (activeObject.type === 'image') {
        //   this.imgAttr.blur = activeObject.filters[0] ? activeObject.filters[0].blur : 0;
        // }
      }
    });
    this.event.on('selectMultiple', () => {
      const ssss = this.canvas.c.getActiveObjects();
      console.log(ssss, 'ssssssssss');
    });
  },
  // beforeDestroy() {
  //   // 组件销毁前移除事件监听
  //   EventBus.$off('updateTabClick');
  // },
  mounted() {
    EventBus.$on('updateTabClick', (showLaser) => {
      console.log(showLaser, 'showLasershowLaser');
      console.log('33333333333');
      this.tabClick(showLaser);
    });
    this.maxDept = this.updateDept;
    const step = this.maxDept / 2;
    for (let i = 0; i <= 2; i++) {
      const key = Math.round(i * step);
      this.marks[Number(key)] = String(key);
    }
    initAligningGuidelines(this.canvas.c);
  },
  methods: {
    // 灯光改变
    lamplightChange(e) {
      this.lamplightValue = e;
    },
    // 关闭灯光
    okRGB() {
      if (this.lamplightValue === 'closeRGB') {
        const action = this.lamplightValue;
        const data = {
          action,
        };
        this.getSocket.send(JSON.stringify(data));
      } else {
        const action = 'changeLightColor';
        const data = {
          action,
          color: this.lamplightValue,
        };
        this.getSocket.send(JSON.stringify(data));
      }
    },
    handleFontSelect(e) {
      console.log(e, 'eee');
    },
    // 获取刀头
    getBitList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      bitList(data).then((res) => {
        if (res.code === 200) {
          const array = [];
          Object.entries(res.data).forEach(([type, items]) => {
            // 为每个工具添加type属性并推入allBitsData数组
            items.forEach((item) => {
              item.type = type;
              array.push(item);
            });
          });
          // console.log(array, 'array');

          this.bitList = array.map((item) => ({
            // 获取图片路径，如果 item.name 对应的键存在于 images 中则使用它，否则设置为 null 或者其他默认值。
            label: item.name,
            value: item.name,
            ...item,
          }));
          this.firstBit = this.bitList[0].name;
          this.secBit = this.bitList[0].name;
          this.$store.dispatch('materialBits/setFirBitName', this.firstBit);
          this.$store.dispatch('materialBits/setSecBitName', this.secBit);
          const getFirstBit = this.bitList.find((obj) => obj.name === this.firstBit);
          if (getFirstBit.bitType === 'V') {
            this.isV = true;
            this.firvAngle = getFirstBit.info.angle;
            this.secvAngle = getFirstBit.info.angle;
            this.$store.dispatch('materialBits/setFirvAngle', this.firvAngle);
            this.$store.dispatch('materialBits/setSecvAngle', this.secvAngle);
          } else {
            this.isV = false;
            this.$store.dispatch('materialBits/setFirvAngle', 0);
            this.$store.dispatch('materialBits/setSecvAngle', 0);
          }
          const firstBitDiameter = getFirstBit.info.diameter;

          const a = firstBitDiameter.replace(/\s+/g, '');
          if (a.toLowerCase().endsWith('in')) {
            const valueStr = a.slice(0, -2);
            // 尝试将提取的字符串转换为浮点数
            const value = this.parseFraction(valueStr); // 使用 eval 来计算分数表达式
            const millimeters = inchToPx(value);
            console.log(millimeters, 'millimetersmillimetersmillimeters');
            this.$store.dispatch('materialBits/firBitDiameter', millimeters);
            console.log(this.firBit, 'this.firBit111');
          } else {
            const millimeters = millimeterToPx(firstBitDiameter);
            this.$store.dispatch('materialBits/firBitDiameter', millimeters);
            console.log(this.firBit, 'this.22222');
          }
          this.$store.dispatch('materialBits/secBitDiameter', 0);
        }
      });
    },
    // 是否选则第二个刀头
    secChange() {
      if (this.secCheck) {
        const getFirstBit = this.bitList.find((obj) => obj.name === this.secBit);
        const secBitDiameter = getFirstBit.info.diameter;
        const a = secBitDiameter.replace(/\s+/g, '');
        if (a.toLowerCase().endsWith('in')) {
          const valueStr = a.slice(0, -2);
          // 尝试将提取的字符串转换为浮点数
          const value = this.parseFraction(valueStr); // 使用 eval 来计算分数表达式
          const millimeters = inchToPx(value);
          this.$store.dispatch('materialBits/secBitDiameter', millimeters);
        } else {
          const millimeters = millimeterToPx(secBitDiameter);
          this.$store.dispatch('materialBits/secBitDiameter', millimeters);
        }
      } else {
        this.$store.dispatch('materialBits/secBitDiameter', 0);
      }
    },
    parseFraction(fractionStr) {
      // 去除空格并分割分数
      const parts = fractionStr.split('/').map((part) => part.trim());
      if (parts.length === 1) {
        return parseFloat(parts[0]);
      }
      if (parts.length === 2) {
        const numerator = parseFloat(parts[0]);
        const denominator = parseFloat(parts[1]);
        if (denominator === 0) {
          throw new Error('分母不能为零');
        }
        return numerator / denominator;
      }
      throw new Error('无效的分数格式');
    },

    // 第一个刀头变化
    firstBitChange(e) {
      const getFirstBit = this.bitList.find((obj) => obj.name === e);
      console.log(getFirstBit, 'getFirstBitgetFirstBit');
      this.$store.dispatch('materialBits/setFirBitName', getFirstBit.label);
      if (getFirstBit.bitType === 'V') {
        this.isV = true;
        this.firvAngle = getFirstBit.info.angle;
        this.$store.dispatch('materialBits/setFirvAngle', this.firvAngle);
      } else {
        this.isV = false;
        this.$store.dispatch('materialBits/setFirvAngle', 0);
      }
      const firstBitDiameter = getFirstBit.info.diameter;
      console.log(firstBitDiameter, 'firstBitDiameter');
      const a = firstBitDiameter.replace(/\s+/g, '');
      if (a.toLowerCase().endsWith('in')) {
        const valueStr = a.slice(0, -2);
        // 尝试将提取的字符串转换为浮点数
        const value = this.parseFraction(valueStr); // 使用 eval 来计算分数表达式
        const millimeters = inchToPx(value);
        this.$store.dispatch('materialBits/firBitDiameter', millimeters);
      } else {
        const millimeters = millimeterToPx(firstBitDiameter);
        this.$store.dispatch('materialBits/firBitDiameter', millimeters);
      }
      const objects = this.canvas.c.getObjects();
      const filteredObjects = objects.filter(
        (obj) => obj.id !== 'workspace' && obj.id !== 'photo' && obj.id !== 'coordinate'
      );
      console.log(filteredObjects, 'objectsobjects');
      filteredObjects.forEach((item) => {
        if (item.fill === null) {
          if (getFirstBit.bitType === 'V') {
            // 计算等腰三角形底边长度
            // 将角度转换为弧度
            const angleInRadians = (this.firvAngle * Math.PI) / 180;
            // 计算底边长度 = 2 * 高度 * tan(顶角/2)
            const baseLength = 2 * this.sliderValue * Math.tan(angleInRadians / 2);
            // 设置strokeWidth为计算出的底边长度
            item.set('strokeWidth', baseLength);
          } else {
            item.set('strokeWidth', this.firBit);
          }
        }
      });
      this.canvas.c.renderAll();
    },
    secBitChange(e) {
      const getFirstBit = this.bitList.find((obj) => obj.name === e);
      this.$store.dispatch('materialBits/setSecBitName', getFirstBit.label);
      if (getFirstBit.bitType === 'V') {
        this.isV = true;
        this.secvAngle = getFirstBit.info.angle;
        this.$store.dispatch('materialBits/setSecvAngle', this.secvAngle);
      } else {
        this.isV = false;
        this.$store.dispatch('materialBits/setSecvAngle', 0);
      }
      const secBitDiameter = getFirstBit.info.diameter;
      const a = secBitDiameter.replace(/\s+/g, '');
      if (a.toLowerCase().endsWith('in')) {
        const valueStr = a.slice(0, -2);
        // 尝试将提取的字符串转换为浮点数
        const value = this.parseFraction(valueStr); // 使用 eval 来计算分数表达式
        const millimeters = inchToPx(value);
        this.$store.dispatch('materialBits/secBitDiameter', millimeters);
      } else {
        const millimeters = millimeterToPx(secBitDiameter);
        this.$store.dispatch('materialBits/secBitDiameter', millimeters);
      }
    },
    feedRateChange(e) {
      this.$store.dispatch('materialBits/feedRate', e);
    },
    zFeedRateChange(e) {
      this.$store.dispatch('materialBits/zFeedRate', e);
    },
    spindleChange(e) {
      this.$store.dispatch('materialBits/spindleSpeed', e);
    },
    depthPerChange(e) {
      this.$store.dispatch('materialBits/depthPerPass', e);
    },
    // 获取材料列表
    getMaterialList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      materList(data).then((res) => {
        if (res.code === 200) {
          this.materialList = res.data.map((item) => ({
            // 获取图片路径，如果 item.name 对应的键存在于 images 中则使用它，否则设置为 null 或者其他默认值。
            label: item.name,
            value: item.name,
            ...item,
          }));
          this.materialChange();

          // console.log(this.materialList, 'this.materialListthis.materialList');
        }
      });
    },
    // 材料下拉变化
    materialChange() {
      // console.log(e, 'e');
      const images = {
        Bamboo: require('@/assets/showMaterial/Bamboo.jpg'),
        Beech: require('@/assets/showMaterial/Beech.jpg'),
        Cardboard: require('@/assets/showMaterial/Cardboard.jpg'),
        Ceramics: require('@/assets/showMaterial/Ceramics.jpg'),
        Cotton: require('@/assets/showMaterial/Cotton.jpg'),
        Denim: require('@/assets/showMaterial/Denim.jpg'),
        KraftPaper: require('@/assets/showMaterial/KraftPaper.jpg'),
        Leather: require('@/assets/showMaterial/Leather.jpg'),
        MDF: require('@/assets/showMaterial/MDF.jpg'),
        Oak: require('@/assets/showMaterial/Oak.jpg'),
        Paulownia: require('@/assets/showMaterial/Paulownia.jpg'),
        Peach: require('@/assets/showMaterial/Peach.jpg'),
        Pine: require('@/assets/showMaterial/Pine.jpg'),
        Plywood: require('@/assets/showMaterial/Plywood.jpg'),
        PVC: require('@/assets/showMaterial/PVC.jpg'),
        Rosewood: require('@/assets/showMaterial/Rosewood.jpg'),
      };
      this.url = images[this.materialPull];
      this.bigUrl = [images[this.materialPull]];
      const foundObject = this.materialList.find((obj) => obj.name === this.materialPull);
      if (foundObject.extInfo.length > 0) {
        const extInfo = JSON.parse(foundObject.extInfo.replace(/'/g, '"'));
        if (extInfo.feedRate) {
          this.feedRate = extInfo.feedRate;
          this.zFeedRate = extInfo.zFeedRate;
          this.spindleSpeed = extInfo.spindleSpeed;
          this.depthPerPass = extInfo.depthPerPass;
        } else {
          this.feedRate = 0;
          this.zFeedRate = 0;
          this.spindleSpeed = 0;
          this.depthPerPass = 0;
        }
        // console.log(extInfo, 'extInfoextInfo');
      } else {
        this.feedRate = 0;
        this.zFeedRate = 0;
        this.spindleSpeed = 0;
        this.depthPerPass = 0;
      }
      this.$store.dispatch('materialBits/feedRate', this.feedRate);
      this.$store.dispatch('materialBits/zFeedRate', this.zFeedRate);
      this.$store.dispatch('materialBits/spindleSpeed', this.spindleSpeed);
      this.$store.dispatch('materialBits/depthPerPass', this.depthPerPass);
      // console.log(foundObject, 'foundObjectfoundObject');
    },
    // async connectToHardware() {
    //   // const startPort = 7500;
    //   // this.$store.dispatch('webSocket/joinWs', startPort);
    //   this.getSocket.onmessage = (e) => {
    //     if (this.action === 'serials' && e.data !== 'ping' && this.sendType === '') {
    //       this.comOptions = JSON.parse(e.data).map((item) => ({ label: item, value: item }));
    //       // console.log(this.comOptions, 'this.comOptionsthis.comOptions');
    //     } else if (e.data === 'Connecting') {
    //       this.join = true;
    //     }
    //   };
    // },
    // 获取机器列表
    getMachineList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      machineList(data).then((res) => {
        // console.log('this.defaultMachinethis.defaultMachinethis.defaultMachine');
        if (res.code === 200) {
          if (res.data.length > 0) {
            // console.log(1111);
            this.defaultMachine = res.data.find((item) => item.isDefault === 1);
            this.maschineName = this.defaultMachine.machineName;
            this.$store.dispatch('machineSize/setMaschineName', this.maschineName);
            this.maschineId = this.defaultMachine.id;
            localStorage.setItem('maschineId', this.maschineId);
          }
        }
        // console.log(res, 'res');
      });
    },
    // 连接com口
    connectLaser() {
      this.action = 'connect';
      const data = {
        action: this.action,
        port: this.selectValue,
        userId: localStorage.getItem('id'),
      };
      this.getSocket.send(JSON.stringify(data));
      // console.log(this.selectValue);
    },
    // 回到原点
    goHome() {
      this.action = 'home';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    //
    hold() {
      this.action = 'hold';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },

    unlock() {
      this.action = 'unlock';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },

    reset() {
      this.action = 'reset';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    handleMouseDown() {
      console.log(1);
      this.action = 'spindle';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    handleMouseUp() {
      console.log(2);
      this.action = 'unSpindle';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    // 拍照
    photograph() {
      this.$store.dispatch('webSocket/setSendType', 'photograph');
      this.action = 'photograph';
      const data = { action: this.action };
      this.removePhotoOverlay();
      this.getSocket.send(JSON.stringify(data));
    },
    // 监听移动
    bindEventListeners() {
      this.canvas.c.on('object:moving', (e) => {
        const activeObject = e.target;
        const objects = this.canvas.c.getObjects();
        const workSpace = objects.find((item) => item.id === 'workspace');
        this.baseAttr.left = `${perMillimeter(
          Number((activeObject.get('left') - workSpace.left).toFixed(2))
        ).toFixed(1)}`;
        this.baseAttr.top = `${perMillimeter(
          Number(
            (
              workSpace.height +
              workSpace.top -
              activeObject.get('top') -
              activeObject.get('height')
            ).toFixed(2)
          )
        ).toFixed(1)}`;
        // console.log(this.clickActive, 'this.clickActivethis.clickActivethis.clickActive');

        // const pathData = `M ${left},${top} h${width} v${height} h-${width} z`;
      });

      this.canvas.c.on('object:scaling', (e) => {
        // console.log(e, 'eeeeeeeeeeeeeeescaling');
        const activeObject = e.target;
        this.baseAttr.width = `${perMillimeter(
          Number(activeObject.get('width') * activeObject.get('scaleX')).toFixed(2)
        ).toFixed(1)}`;
        this.baseAttr.height = `${perMillimeter(
          Number(activeObject.get('height') * activeObject.get('scaleY')).toFixed(2)
        ).toFixed(1)}`;
        // console.log(e, 'eeeeeeeeeeeeeeeeee');
      });
    },
    // 图形断点
    editPoint() {
      this.edit = !this.edit;
      // console.log(this.clickActive, 'this.clickActivethis.clickActive');
      // const obj = this.clickActive.path;
      const { left, top, width, height } = this.clickActive;
      // 根据矩形的信息生成路径数据
      const pathData = `M ${left},${top} h${width} v${height} h-${width} z`;
      const path = new this.fabric.Path(pathData, {
        ...this.clickActive,
        objectClass: 'path',
        left: this.clickActive.left,
        top: this.clickActive.top,
      });
      // console.log(path, 'pathpath');
      if (this.clickActive) {
        this.toggleEditing(path);
      }
    },
    toggleEditing(obj) {
      // console.log(obj.path, 'objobj');
      // const points = obj.path;
      // const controlPoints = [];
      if (this.edit) {
        // 清除之前的断点
        this.breakpoints.forEach((bp) => this.canvas.remove(bp));
        this.breakpoints = [];
        // 设置对象为不可选中和不可移动
        obj.set({
          hasControls: true, // 显示控制点（如缩放、旋转等）
          hasBorders: true, // 显示边框
          lockMovementX: true, // 禁止水平移动
          lockMovementY: true, // 禁止垂直移动
          selectable: true, // 对象可选中
          // evented: false, // 禁止触发事件
        });
        // 解析路径数据，创建控制点
        const points = this.parsePathData(obj.path);
        // 创建并添加控制点
        points.forEach((point, index) => {
          // console.log(point, 'pointpoint');
          const controlPoint = new this.fabric.Circle({
            left: point.x, // x 坐标
            top: point.y, // y 坐标
            radius: 5,
            fill: 'red',
            hasControls: false,
            hasBorders: false,
            originX: 'center',
            originY: 'center',
            data: { index }, // 保存点的索引
            selectable: true, // 控制点本身是可选中的
          });
          // console.log(controlPoint, 'controlPointcontrolPoint');
          // 添加拖拽事件监听器
          controlPoint.on('mousedown', (e) => {
            const activePoint = e.target;
            // const pointer = this.canvas.getPointer(e.e);

            // // 记录初始位置
            // const initialLeft = activePoint.left;
            // const initialTop = activePoint.top;

            this.canvas.c.on('mouse:move', (event) => {
              const newX = event.pointer.x;
              const newY = event.pointer.y;

              // 更新控制点的位置
              activePoint.set({ left: newX, top: newY });
              // 更新路径的对应点
              const pathIndex = activePoint.data.index;
              obj.path[pathIndex][1] = newX;
              obj.path[pathIndex][2] = newY;

              // 更新路径数据并重新绘制对象
              // obj.set({ path: obj.path });
              obj.dirty = true; // 标记对象为脏状态以强制重绘
              this.canvas.c.requestRenderAll();
            });

            this.canvas.c.on('mouse:up', () => {
              this.canvas.c.off('mouse:move'); // 移除鼠标移动监听器
            });
          });

          this.breakpoints.push(controlPoint);
          this.canvas.c.add(controlPoint); // 将控制点添加到画布
        });

        // 更新画布
        this.canvas.c.requestRenderAll();
      } else {
        obj.set({
          hasControls: true, // 恢复默认控制点
          hasBorders: true, // 恢复边框
          lockMovementX: false, // 允许水平移动
          lockMovementY: false, // 允许垂直移动
          selectable: true, // 对象可选中
          cornerColor: 'blue', // 自定义控制点颜色
          cornerStyle: 'rect', // 自定义控制点样式
          // evented: true,
        });

        // 移除所有控制点
        this.breakpoints.forEach((bp) => this.canvas.c.remove(bp));
        this.breakpoints = [];

        // 更新画布
        this.canvas.c.requestRenderAll();
        // obj.cornerColor = 'blue';
        // obj.cornerStyle = 'rect';
        // obj.hasBorders = true;
        // obj.controls = this.fabric.controlsUtils.createObjectDefaultControls();
      }
      // obj.setCoords();
    },
    parsePathData(pathData) {
      const points = [];
      for (let i = 0; i < pathData.length; i += 3) {
        const command = pathData[i];
        const x = pathData[i + 1];
        const y = pathData[i + 2];
        if (command === 'M' || command === 'L') {
          points.push({ x, y });
        }
      }
      return points;
    },
    updatePolygonPoint(polygon, pointIndex, target) {
      // 更新多边形的顶点位置
      polygon.points[pointIndex].x = target.left - polygon.left;
      polygon.points[pointIndex].y = target.top - polygon.top;

      // 刷新画布以显示更新后的多边形
      polygon.setCoords();
      this.canvas.c.requestRenderAll();
    },

    getColor() {
      // console.log('getColorgetColorgetColorgetColor');

      // 计算颜色深度
      if (this.clickActive.fill === null && this.sliderValue === this.maxDept) {
        this.$store.dispatch('showTab/showAddTabs', true);
        // this.addBreakpoints();
      } else {
        this.$store.dispatch('showTab/showAddTabs', false);
      }
      const color =
        this.clickActive.fill === null ? this.clickActive.stroke : this.clickActive.fill;
      // const grayValue = Math.round((this.maxDept - this.sliderValue) * 2.55);
      if (this.clickActive.zIndex === '00') {
        // console.log(1);
        const a = 255 / this.maxDept;
        const grayValue = (this.maxDept - this.sliderValue) * a;
        // console.log(grayValue, 'grayValuegrayValue');

        // 计算颜色深度
        // const grayValue = 100 - mappedValue;
        return `rgb(${grayValue}, ${grayValue}, ${grayValue})`;
      }
      if (
        this.clickActive.stroke === null ||
        this.clickActive.stroke === 'blue' ||
        this.clickActive.strokeWidth === 1
      ) {
        // console.log(2);
        // console.log(this.clickActive.fill, 'this.clickActive.fill');

        return color;
      }
    },
    sliderInput(e) {
      // console.log(e, 'eee111');
      // console.log(this.clickActive, 'this.clickActivethis.clickActive');
      // console.log(this.clickActive._objects, 'this.clickActive._objectsthis.clickActive._objects');

      this.sliderValue = e;
      // if(this.activeObject)
      if (this.clickActive.fill === null) {
        this.clickActive.set('stroke', this.getColor());
      } else {
        if (this.clickActive._objects) {
          // console.log(this.getColor(), 'this.getColor()this.getColor()this.getColor()');
          this.clickActive.set('fill', this.getColor());
          this.clickActive._objects.forEach((item) => {
            // 修改属性值
            item.set('dept', e); // 修改dept
            item.set('fill', this.getColor()); // 修改dept
          });
          this.canvas.c.renderAll();
          return;
        }
        this.clickActive.set('fill', this.getColor());
      }

      this.canvas.c.renderAll();
    },
    // 滑块拖动
    sliderChange(e) {
      // console.log(this.clickActive, 'this.clickActivethis.clickActive');
      if (this.isV) {
        // 计算等腰三角形底边长度
        // 将角度转换为弧度
        const angleInRadians = (this.firvAngle * Math.PI) / 180;
        // 计算底边长度 = 2 * 高度 * tan(顶角/2)
        const baseLength = 2 * this.sliderValue * Math.tan(angleInRadians / 2);
        // 设置strokeWidth为计算出的底边长度
        this.clickActive.set('strokeWidth', baseLength);
        this.canvas.c.renderAll();
      }
      this.clickActive.set('dept', e);
    },
    // 深度设置
    inputChange(e) {
      // console.log(e, 'eeeeeeeee');
      this.clickActive.set('dept', e);
    },
    addDeptChange(e) {
      // console.log(e, 'eeee');
      const a = this.clickActive.get('dept');
      this.clickActive.set('dept', e + a);
    },
    // laser table点击
    rowClick(row) {
      // console.log(row, 'rowrow');
      this.clickRow = row;
      this.attribute = { ...this.clickRow };
      // this.attribute.speed = this.attribute.speed.split('/')[0];
    },
    // 填充和描边选择
    selectChange(e) {
      console.log(e, 'eeee');
    },
    speedChange(e) {
      this.clickActive.set('speed', e);
      this.changeData('speed', e);
      this.clickRow.speed = e;
    },
    powerChange(e) {
      this.clickActive.set('power', e);
      this.changeData('power', e);
      this.clickRow.power = e;
    },
    passChange(e) {
      this.clickActive.set('pass', e);
      this.changeData('pass', e);
      this.clickRow.pass = e;
    },
    intervalChange(e) {
      this.clickActive.set('interval', e);
      this.changeData('interval', e);
      this.clickRow.interval = e;
    },
    changeData(str, e) {
      const objects = this.canvas.c.getObjects();
      const foundItem = objects.filter((item) => item.zIndex === this.clickActive.zIndex);
      console.log(foundItem, 'foundItemfoundItem');
      foundItem.forEach((item) => {
        item[str] = e;
        this.canvas.c.requestRenderAll();
      });
      if (this.clickActive._objects && this.clickActive._objects.length > 0) {
        this.clickActive._objects.forEach((item) => {
          item[str] = e;
          this.canvas.c.requestRenderAll();
        });
      }
    },
    // tab切换
    tabClick(e) {
      if (e === 'Laser') {
        console.log('1111222');
        const newArray = this.canvas.c.getObjects();
        console.log(newArray, 'newArraynewArraynewArray22222');

        const filteredObjects = newArray.filter(
          (obj) =>
            obj.id !== 'workspace' &&
            obj.id !== 'coordinate' &&
            obj.id !== 'photo' &&
            !(
              obj instanceof this.fabric.Rect &&
              obj.fill &&
              obj.fill.source instanceof HTMLCanvasElement
            )
        );
        console.log(filteredObjects, 'newArraynewArraynewArray11111');
        this.tableData = filteredObjects
          .map((item) => {
            console.log(item, 'itemitemitemitem');
            console.log(item.zIndex, 'zIndexzIndexzIndexzIndex');

            if (item.zIndex !== '00') {
              return {
                layer: item.zIndex,
                mode: item.fill === null ? 'stroke' : 'fill',
                // speed: `${this.attribute.speed} / ${this.attribute.power}`,
                speed: item.speed ? item.speed : 0,
                power: item.power ? item.power : 0,
                pass: item.pass ? item.pass : 0,
                interval: item.interval ? item.interval : 0,
              };
            }
            return null;
          })
          .filter((item) => item !== null);
        console.log(this.tableData, 'tableDatatableDatatableData');

        // console.log(this.clickActive.speed, 'this.clickActive.speedthis.clickActive.speed');
        this.attribute.speed = this.clickActive.speed ? this.clickActive.speed : 6000;
        this.attribute.power = this.clickActive.power ? this.clickActive.power : 20;
        this.attribute.pass = this.clickActive.pass ? this.clickActive.pass : 1;
        this.attribute.interval = this.clickActive.interval ? this.clickActive.interval : 0.1;
        this.tableData = this.tableData.reduce((acc, current) => {
          // 如果当前 layer 还没有在 acc 中出现，则添加到 acc
          if (!acc.find((item) => item.layer === current.layer)) {
            acc.push(current);
          }
          return acc;
        }, []);
        // console.log(this.tableData, 'newArraysss');
      }
      console.log(this.tableData, 'this.tableDatathis.tableData');
    },
    // 锁定
    doLock(isLock) {
      isLock ? this.lock() : this.unLock();
    },
    lock() {
      this.isLock = true;
      this.updateObjectProperties(true);
      this.canvas.c.renderAll();
    },
    unLock() {
      this.isLock = false;
      this.updateObjectProperties(false);
      this.canvas.c.renderAll();
    },
    updateObjectProperties(lock) {
      this.clickActive.selectable = true; // 允许选择
      this.clickActive.isLock = lock; // 允许选择
      this.clickActive.evented = !lock; // 锁定时不允许事件（拖动、缩放）
      this.clickActive.lockMovementX = lock; // 锁定水平移动
      this.clickActive.lockMovementY = lock; // 锁定垂直移动
      this.clickActive.lockScalingX = lock; // 锁定水平缩放
      this.clickActive.lockScalingY = lock; // 锁定垂直缩放
      this.clickActive.hasControls = !lock; // 隐藏控制点
    },
    // 通用属性改变
    changeCommon(key, value) {
      this.bitDiametert = Number(this.firBit);
      // const objects = this.canvas.c.getObjects();
      // const workSpace = objects.find((item) => item.id === 'workspace');
      const activeObject = this.canvas.c.getActiveObjects()[0];
      const colors1 = this.canvas.c.getActiveObjects()[0].stroke;
      const colors2 = this.canvas.c.getActiveObjects()[0].fill;
      // const widthss = activeObject.width;
      // const heightss = activeObject.height;
      // const cx = activeObject.left + widthss / 2; // 计算中心点X
      // const cy = activeObject.top + heightss / 2; // 计算中心点Y
      console.log(colors1, colors2, 'colors1colors1');
      // 透明度特殊转换
      if (key === 'opacity') {
        activeObject && activeObject.set(key, value / 100);
        this.canvas.c.renderAll();
        return;
      }

      // 描边
      if (key === 'onpath') {
        console.log(this.bitDiametert, 'this.firBitthis.firBit');
        // console.log(activeObject, 'activeObjectactiveObject');
        // console.log(activeObject.fill, 'activeObject.fillactiveObject.fill');
        const color = activeObject.fill;
        // console.log('onpath');
        if (activeObject.zIndex === '00') {
          activeObject.set('fill', null);
          activeObject.set('strokeWidth', this.bitDiametert);
          activeObject.set('stroke', this.getColor());
          activeObject.set('strokeUniform', true);
          activeObject.set('cutType', 'Mill - On path');
          this.canvas.c.renderAll();
          return;
        }
        if (this.clickActive.fill !== null) {
          activeObject.set('fill', null);
          activeObject.set('strokeWidth', 1);
          activeObject.set('stroke', color);
          activeObject.set('strokeUniform', true);
          activeObject.set('cutType', 'Laser - On path');
          // console.log(activeObject, 'activeObjectactiveObject');
          this.canvas.c.renderAll();
          return;
        }
      }
      if (key === 'inside') {
        activeObject.set('fill', null);
        activeObject.set('strokeWidth', this.bitDiametert);
        activeObject.set('stroke', 'black');
        activeObject.set('width', activeObject.width - this.bitDiametert);
        activeObject.set('height', activeObject.height - this.bitDiametert);
        activeObject.set('strokeUniform', true);
        this.canvas.c.renderAll();

        // const innerStrokeWidth = this.bitDiametert; // 内部描边宽度
        // activeObject.clone(async (clonedObj) => {
        //   const innerStrokeObject = clonedObj;
        //   // console.log(innerStrokeObject, 'innerStrokeObjectinnerStrokeObject');
        //   // 调整大小和位置以适应内部描边
        //   innerStrokeObject.set({
        //     left: activeObject.left,
        //     top: activeObject.top,
        //     scaleX: Math.max(
        //       activeObject.scaleX - innerStrokeWidth / (activeObject.width * activeObject.scaleX),
        //       0.001
        //     ),
        //     scaleY: Math.max(
        //       activeObject.scaleY - innerStrokeWidth / (activeObject.height * activeObject.scaleY),
        //       0.001
        //     ),
        //     stroke: 'black', // 内部描边颜色
        //     strokeWidth: innerStrokeWidth,
        //     fill: null, // 确保内部没有填充
        //     selectable: false, // 确保用户不能选择它
        //     evented: false, // 确保事件不触发在这个对象上
        //   });

        //   // 将新的内部描边对象添加到画布并置于顶层
        //   this.canvas.c.add(innerStrokeObject).bringToFront(activeObject);
        //   this.canvas.c.renderAll();

        //   // console.log('Cloned object:', innerStrokeObject);
        // });
      }
      if (key === 'outside') {
        activeObject.setShadow({
          color: 'black', // 阴影颜色
          blur: 10, // 阴影模糊度
          offsetX: 5, // 阴影偏移量X
          offsetY: 5, // 阴影偏移量Y
        });
        // const newWidth = widthss + this.bitDiametert * 2; // 外轮廓宽度增加
        // const newHeight = heightss + this.bitDiametert * 2; // 外轮廓高度增加

        // // 更新图形属性，确保中心点不变
        // activeObject.set({
        //   left: cx - newWidth / 2,
        //   top: cy - newHeight / 2,
        //   width: newWidth,
        //   height: newHeight,
        //   stroke: 'black', // 设置轮廓颜色
        //   strokeWidth: this.bitDiametert,
        //   strokeUniform: true,
        //   fill: null, // 清除填充
        // });
        // activeObject.set('fill', null);
        // activeObject.set('strokeWidth', this.bitDiametert);
        // activeObject.set('stroke', 'black');
        // activeObject.set('width', activeObject.width + this.bitDiametert);
        // activeObject.set('height', activeObject.height + this.bitDiametert);
        // activeObject.set('strokeUniform', true);
        this.canvas.c.renderAll();

        // const outerStrokeWidth = this.bitDiametert; // 外部描边宽度
        // const outerStrokeObject = activeObject.clone();
        // // 调整大小和位置以适应外部描边
        // outerStrokeObject.set({
        //   left: activeObject.left - outerStrokeWidth / 2,
        //   top: activeObject.top - outerStrokeWidth / 2,
        //   scaleX:
        //     activeObject.scaleX + outerStrokeWidth / (activeObject.width * activeObject.scaleX),
        //   scaleY:
        //     activeObject.scaleY + outerStrokeWidth / (activeObject.height * activeObject.scaleY),
        //   stroke: 'black', // 外部描边颜色
        //   strokeWidth: outerStrokeWidth,
        //   fill: null, // 确保内部没有填充
        //   selectable: false, // 确保用户不能选择它
        //   evented: false, // 确保事件不触发在这个对象上
        // });

        // // 将新的外部描边对象添加到画布并置于底层
        // this.canvas.c.add(outerStrokeObject).sendToBack(outerStrokeObject);
        // this.canvas.c.renderAll();
        // activeObject.set('strokeUniform', true);
      }
      // 填充
      if (key === 'fill') {
        const color = activeObject.stroke;
        if (activeObject.zIndex === '00') {
          // console.log(1);
          if (activeObject.get('fill') === null) {
            activeObject.set('fill', this.getColor()); // 移除填充
            activeObject.set('stroke', null); // 设置描边颜色
            activeObject.set('cutType', 'Mill Pocket');
            this.canvas.c.renderAll();
          }
        } else if (this.clickActive.fill === null) {
          // console.log(2);

          activeObject.set('fill', color); // 移除填充
          activeObject.set('stroke', null); // 设置描边颜色
          activeObject.set('cutType', 'Laser - Fill');
          this.canvas.c.renderAll();
        }
      }
      // activeObject && activeObject.set(key, value);
    },

    // 移除照片
    removePhotoOverlay() {
      // 找到并移除照片对象
      const photoObj = this.canvas.c.getObjects().find((obj) => obj.id === 'photo');

      if (photoObj) {
        this.canvas.c.remove(photoObj);
      }
      // 确保网格可见
      const workspace = this.canvas.c.getObjects().find((obj) => obj.id === 'workspace');
      if (workspace) {
        workspace.visible = true;
      }

      this.canvas.c.renderAll();
    },
  },
};
</script>

<style lang="less" scoped>
.el-dropdown-menu {
  max-height: 50%;
  overflow-y: scroll;
}
.flex-view {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  display: inline-flex;
  justify-content: space-around;
  border-radius: 5px;
  background: #f6f7f9;
}
.flex-item {
  display: flex;
  flex: 1;
  .label {
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    font-size: 14px;
    color: #333333;
  }
  .content {
    width: 150px;
  }
  .size-label {
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    font-size: 14px;
    color: #333333;
  }
  .size-content {
    // width: 150px;
    .ivu-input-number {
      width: 60px;
    }
  }
  .slider-box {
    width: calc(100% - 50px);
    margin-left: 10px;
  }
  .right {
    margin-left: 10px;
    /deep/ .ivu-input-number {
      display: block;
      width: 100%;
    }
  }
  /deep/ .ivu-slider-wrap {
    margin: 13px 0;
  }
  /deep/ .ivu-radio-group-button .ivu-radio-wrapper {
    width: 48px;
    line-height: 50px;
    text-align: center;
    svg {
      vertical-align: baseline;
    }
  }

  /deep/ .ivu-btn-group-large > .ivu-btn {
    font-size: 24px;
  }

  /deep/ .ivu-radio-group-button.ivu-radio-group-large .ivu-radio-wrapper {
    font-size: 24px;
  }
}
.left-cut {
  width: 50%;
}
.right-cut {
  width: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
}
/deep/ .el-slider .is-vertical {
  transform: rotateX(180deg);
  display: inline-block;
}

/deep/ .el-slider__bar {
  transform: rotateX(180deg);
}

/deep/ .el-slider__button {
  transform: rotateX(180deg);
}

::v-deep .ivu-input-number {
  width: 65px;
}
.shapeTab .el-input {
  width: 100%;
}
::v-deep .el-tabs {
  height: 100%;
}
/deep/ .el-tabs__content {
  height: 96%;
}
/deep/ .laser-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/deep/ .ivu-divider {
  margin: 8px 0;
}
.equal-button {
  width: 30%;
  margin: 0 1.5%;
  flex: 1;
}
.el-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
</style>
