// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  showLaser: null,
  find: true,
  sendType: '',
  sendCom: [],
  sendJoin: false,
  gCode: null,
  secGCode: null,
  cncLaser: false,
  gCodePath: false,
};
const mutations = {
  joinWs(aaa, sss) {
    aaa.showLaser = sss;
  },
  setShowLaser(aaa, value) {
    aaa.find = value;
  },
  setSendType(aaa, value) {
    aaa.sendType = value;
  },
  setCom(aaa, value) {
    console.log(value, 'valuevalue');

    aaa.sendCom = value;
  },
  setJoin(aaa, value) {
    console.log(value, 'valuevalue');
    aaa.sendJoin = value;
  },
  setGCode(aaa, value) {
    aaa.gCode = value;
  },
  setSecGCode(aaa, value) {
    aaa.secGCode = value;
  },
  setCncLaser(aaa, value) {
    aaa.cncLaser = value;
  },
  setGCodePath(aaa, value) {
    aaa.gCodePath = value;
  },
};
const actions = {
  joinWs({ commit }, port) {
    // const socket = new WebSocket(`ws://192.168.1.240:${port}/ws`);
    const socket = new WebSocket(`ws://localhost:${port}/ws`);
    commit('joinWs', socket);
  },
  setShowLaser({ commit }, value) {
    commit('setShowLaser', value);
  },
  setSendType({ commit }, value) {
    commit('setSendType', value);
  },
  setCom({ commit }, value) {
    commit('setCom', value);
  },
  setJoin({ commit }, value) {
    commit('setJoin', value);
  },
  setGCode({ commit }, value) {
    commit('setGCode', value);
  },
  setSecGCode({ commit }, value) {
    commit('setSecGCode', value);
  },
  setCncLaser({ commit }, value) {
    console.log(value, 'valuevalue');

    commit('setCncLaser', value);
  },
  setGCodePath({ commit }, value) {
    commit('setGCodePath', value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
