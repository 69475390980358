<template>
  <div id="app">
    <!-- GCode 文件内容显示区域 -->
    <!-- <div class="gcode-viewer">
      <textarea v-model="parsedGCodeContent" readonly rows="6"></textarea>
    </div> -->

    <!-- 控制栏 -->
    <div class="control-bar">
      <!-- 添加材质贴图选择下拉框 -->
      <!-- <div class="texture-selector" style="margin: 5px 0">
        <label for="texture-select-1">
          Material：
          <select id="texture-select-1" v-model="selectedTexture" @change="updateMaterialTexture">
            <option value="">N/A</option>
            <option v-for="texture in textureOptions" :key="texture" :value="texture">
              {{ texture }}
            </option>
          </select>
        </label>
      </div> -->

      <div class="material-inputs">
        <!-- 新增材料长度 -->
        <!-- 材料输入部分 -->
        <div>
          <label for="material-length">
            X:
            <input
              type="number"
              id="material-length"
              v-model.number="materialLength"
              min="0"
              :disabled="true"
              @keypress="validateNumberInput"
              @change="updateMaterialAndGrid"
            />
          </label>
        </div>
        <!-- 对宽度和厚度输入框也做同样修改 -->
        <div>
          <label for="material-width">
            Y:
            <input
              type="number"
              id="material-width"
              v-model.number="materialWidth"
              min="0"
              @keypress="validateNumberInput"
              @change="updateMaterialAndGrid"
              :disabled="true"
            />
          </label>
        </div>
        <!-- 原有材料厚度 -->
        <div>
          <label for="material-thickness">
            Z:
            <input
              type="number"
              id="material-thickness"
              v-model.number="materialThickness"
              min="0"
              @keypress="validateNumberInput"
              @change="updateMaterialAndGrid"
              :disabled="true"
            />
          </label>
        </div>
        <div style="font-size: 14px; font-weight: 600">
          <span>Lines:</span>
          <span v-if="fileLineCount" style="margin-right: 10px">{{ fileLineCount }}</span>
          <span>Remaining time:</span>
          <span style="margin-right: 10px">{{ getRemainingTime }}</span>
          <span>Time consuming:</span>
          <span>{{ getSpentTime }}</span>
        </div>
      </div>
      <!-- <button @click="loadGCodeFile" :disabled="isLoading">
        {{ isLoading ? 'Loading...' : 'Load GCode' }}
      </button> -->

      <!-- 播放控制区域 -->
      <div style="margin-top: 10px">
        <button @click="stepBackward">←</button>
        <button @click="toggleAnimation">{{ animationRunning ? '⏸' : '▶' }}</button>
        <button @click="stepForward">→</button>
        <button @click="decreaseSpeed">-</button>
        <span class="speed-display">{{ speed }}x</span>
        <button @click="increaseSpeed">+</button>
        <input type="range" v-model="progress" @input="handleProgress" />
        <span>{{ Math.round(progress) }}%</span>

        <div class="simplification-toggle" style="margin-left: 15px">
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label class="toggle-label">
            FastMode
            <input type="checkbox" v-model="simplificationEnabled" @change="toggleSimplification" />
          </label>
        </div>
        <div class="grid-toggle">
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label class="toggle-label">
            ShowGrid
            <input type="checkbox" v-model="gridVisible" @change="toggleGridVisibility" />
          </label>
        </div>
        <div style="margin: 0 10px">
          <el-switch
            style="display: block"
            v-model="path"
            active-color="#13ce66"
            inactive-color="#13ce66"
            active-text="Path2"
            inactive-text="Path1"
            @change="gCodePathChange"
          ></el-switch>
        </div>
        <div>
          <el-button type="primary" @click="showGcode">Show Gcode</el-button>
        </div>
        <div class="zoom-controls">
          <span style="font-size: 14px; font-weight: 600; margin: 0 0 0 15px">Zoom:</span>
          <button @click="zoomIn" title="zoomIn">+</button>
          <button @click="zoomOut" title="zoomOut">-</button>
          <button @click="resetView" title="resetView">ResetView</button>
        </div>
      </div>
      <!-- 进度控制区域 -->

      <!-- 添加路径简化切换按钮 -->

      <!-- 添加网格显示开关 -->
    </div>
    <!-- 预览容器 -->
    <div ref="previewContainer" class="preview-container"></div>

    <!-- 加载遮罩层 -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-content">
        <div class="loading-spinner"></div>
        <div class="loading-text">Loading GCode file...</div>
      </div>
    </div>
    <el-dialog
      title="GCode"
      :visible.sync="gcodeDia"
      :before-close="closeGcode"
      width="600px"
      :modal="true"
      :modal-append-to-body="false"
      append-to-body
    >
      <textarea id="gcodePreview" rows="20" cols="50" readonly style="width: 100%"></textarea>
    </el-dialog>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

export default {
  name: 'GCodeViewer',
  data() {
    return {
      path: false,
      gcodeDia: false,
      showGcodeData: null,
      scene: null, // Three.js场景
      camera: null, // 透视相机
      renderer: null, // WebGL渲染器
      controls: null, // 轨道控制器
      pathLines: [], // 存储路径线段
      gcodePoints: [], // 解析后的GCode路径点
      currentPosition: null, // 当前位置指示器（锥体）
      animationRunning: false, // 动画状态
      progress: 0, // 进度百分比
      speed: 1, // 播放速度
      fileLineCount: null, // 文件行数
      parsedGCodeContent: '', // 新增数据属性，用于存储解析后的坐标点内容
      originalGCodeContent: '', // 新增：保存原始GCode文件内容
      materialLength: 0, // 材料长度
      materialWidth: 0, // 材料宽度
      materialThickness: 0, // 原有材料厚度
      materialCube: null, // 材料立方体对象
      gridHelper: null, // 自定义网格对象
      rapidMoveGeometry: null, // G0快速移动几何体
      normalMoveGeometry: null, // G1普通移动几何体
      rapidMoveLine: null, // G0快速移动线对象
      normalMoveLine: null, // G1普通移动线对象

      // 添加已走过路径的几何体和线条对象
      traversedRapidMoveGeometry: null, // 已走过的G0快速移动几何体
      traversedNormalMoveGeometry: null, // 已走过的G1普通移动几何体
      traversedRapidMoveLine: null, // 已走过的G0快速移动线对象
      traversedNormalMoveLine: null, // 已走过的G1普通移动线对象

      // 存储原始路径段信息，用于动态更新已走过/未走过的路径
      pathSegments: [], // 存储所有路径段信息

      // 添加贴图相关数据
      textureOptions: [
        'Denim.jpg',
        'KraftPaper.jpg',
        'Leaf.jpg',
        'Leather.jpg',
        'MDF.jpg',
        'Peach.jpg',
        'Pine.jpg',
        'Plywood.jpg',
      ], // 材质选项列表
      selectedTexture: '', // 当前选中的贴图
      isLoading: false, // 添加加载状态变量
      simplificationEnabled: true, // 是否启用路径简化
      simplificationTolerance: 1, // 简化容差（默认0.1 可调整）
      gridVisible: false, // 网格是否可见，默认关闭
      font: null, // 添加字体属性
    };
  },
  // props: {
  //   gcodeData: {
  //     type: Blob,
  //     default: null,
  //   },
  // },

  computed: {
    getGCode() {
      return this.$store.state.webSocket.gCode;
    },
    getWidth() {
      return this.$store.state.material.width;
    },
    getHeight() {
      return this.$store.state.material.height;
    },
    getDept() {
      return this.$store.state.material.dept;
    },
    getProgress() {
      // console.log(this.$store.state.webSocket.sendCom, 'getComgetComgetComgetCom');
      return this.$store.state.progress.progress;
    },
    getRemainingTime() {
      // console.log(this.$store.state.webSocket.sendCom, 'getComgetComgetComgetCom');
      return this.$store.state.progress.remainingTime;
    },
    getSpentTime() {
      // console.log(this.$store.state.webSocket.sendCom, 'getComgetComgetComgetCom');
      return this.$store.state.progress.spentTime;
    },
  },
  // watch: {
  //   // 监听 GCode 数据变化
  //   gcodeData(newVal) {
  //     console.log();

  //     console.log('32222222');

  //     if (newVal) {
  //       console.log(newVal, 'newValnewVal');
  //       this.handleGCodeBlob(newVal);
  //       this.showGcodeData = newVal;
  //       // this.showGcode(newVal);
  //     }
  //   },

  // },
  activated() {
    // 每次组件被激活时更新数据
    this.updateMaterialData();
  },
  watch: {
    // 监听getGCode的变化
    getGCode(newVal) {
      if (newVal) {
        this.handleGCodeBlob();
        // this.showGcodeData = newVal;
      } else {
        console.log('gcodeData is null or undefined');
      }
    },
    getProgress: {
      handler(newValue) {
        // 将Vuex中的progress值同步到本地data中的progress
        if (newValue !== undefined && newValue !== null) {
          this.progress = parseFloat(newValue);
          // 如果需要，可以在这里调用handleProgress方法更新视图
          this.handleProgress();
        }
      },
      immediate: true, // 组件创建时立即执行一次
    },
    // 同时监听 Vuex 中的数据变化
    // $route: function () {
    //   this.updateMaterialData();
    // },

    // 同时监听 Vuex 中的数据变化
    '$store.state.material.width': function () {
      this.updateMaterialData();
    },
    '$store.state.material.height': function () {
      this.updateMaterialData();
    },
    '$store.state.material.dept': function () {
      this.updateMaterialData();
    },
  },
  mounted() {
    this.updateMaterialData();
    // this.materialLength = this.$store.state.material.width;
    // this.materialWidth = this.$store.state.material.height;
    // this.materialThickness = this.$store.state.material.dept;
    this.loadFont(); // 加载字体
    this.initThree(); // 初始化Three.js环境
    this.handleGCodeBlob();
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
    this.cleanupResources(); // 清理资源
  },
  methods: {
    gCodePathChange() {
      this.$store.dispatch('webSocket/setCncLaser', this.path);
    },
    updateMaterialData() {
      this.materialLength = this.$store.state.material.width;
      this.materialWidth = this.$store.state.material.height;
      this.materialThickness = this.$store.state.material.dept;

      // 如果 Three.js 已经初始化，则更新材料和网格
      if (this.scene && this.materialCube) {
        this.updateMaterialAndGrid();
      }
    },
    // 展示gcode
    showGcode() {
      const reader = new FileReader();
      reader.onloadend = function () {
        const cleanBase64 = reader.result.replace(/^data:application\/octet-stream;base64,/, ''); // 去除前缀
        const decodedString = atob(cleanBase64);
        // console.log(this.showGcodeData, ' this.gcodeData this.gcodeData');
        // console.log(decodedString, '|decodedString');

        document.getElementById('gcodePreview').value = decodedString;
      };
      reader.readAsDataURL(this.$store.state.webSocket.gCode); // 或者使用其他 readAs 方法
      this.gcodeDia = true;
    },
    closeGcode() {
      this.gcodeDia = false;
    },
    /**
     * 加载字体
     */
    loadFont() {
      const loader = new FontLoader();
      // 修正字体路径，确保路径正确
      loader.load(
        'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json',
        (font) => {
          console.log('字体加载成功');
          this.font = font;
          // 如果网格已经创建，则更新网格
          if (this.gridHelper) {
            this.updateGridHelper();
          }
        },
        // 添加进度回调
        // (xhr) => {
        //   console.log((xhr.loaded / xhr.total) * 100 + '% 字体加载中');
        // },
        // 添加错误回调
        (err) => {
          console.error('字体加载失败:', err);
        }
      );
    },

    /**
     * 切换网格可见性
     */
    toggleGridVisibility() {
      if (this.gridHelper) {
        this.gridHelper.visible = this.gridVisible;
      }
    },

    /**
     * 切换路径简化功能
     */
    toggleSimplification() {
      // 如果当前有加载的GCode文件，则重新解析
      if (this.fileLineCount && this.originalGCodeContent) {
        // 保存当前进度
        const currentProgress = this.progress;

        // 重新解析当前文件
        this.cleanupPath();
        this.parseGCode(this.originalGCodeContent);
        this.adjustCameraView();

        // 重新设置锥体位置到第一个点
        if (this.gcodePoints.length > 0 && this.currentPosition) {
          const firstPoint = this.gcodePoints[0];
          const zOffset = 2.5;
          this.currentPosition.position.set(firstPoint.x, firstPoint.y, firstPoint.z + zOffset);
        }

        // 恢复进度
        this.progress = currentProgress;
        this.handleProgress();

        console.log(`路径简化: ${this.simplificationEnabled ? '开启' : '关闭'}`);
      }
    },

    /**
     * 放大视图
     */
    zoomIn() {
      this.camera.zoom *= 1.2;
      this.camera.updateProjectionMatrix();
    },

    /**
     * 缩小视图
     */
    zoomOut() {
      this.camera.zoom /= 1.2;
      this.camera.updateProjectionMatrix();
    },

    /**
     * 重置视图
     */
    resetView() {
      if (this.gcodePoints.length > 0) {
        this.adjustCameraView();
      } else {
        this.adjustCameraToMaterial();
      }
    },

    // 数字输入验证
    validateNumberInput(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
    },

    /**
     * 初始化Three.js基础环境
     */
    initThree() {
      // 场景设置
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xf0f0f0);

      // 添加灯光
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
      this.scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(1, 1, 1).normalize();
      this.scene.add(directionalLight);

      // 相机设置（75度视角，自适应宽高比）
      const viewSize = 300; // 初始可见区域尺寸
      const aspect = window.innerWidth / window.innerHeight;
      this.camera = new THREE.OrthographicCamera(
        (-viewSize * aspect) / 2, // left
        (viewSize * aspect) / 2, // right
        viewSize / 2, // top
        -viewSize / 2, // bottom
        0.1,
        1000
      );
      this.camera.position.set(180, 75, 200); // 保持原位置
      this.camera.zoom = 0.5; // 初始缩放系数
      this.camera.updateProjectionMatrix();

      // 渲染器设置
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.$refs.previewContainer.appendChild(this.renderer.domElement);

      // 控制器设置（禁止阻尼惯性）
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = false;
      this.controls.dampingFactor = 0.05;

      // 限制绕 Z 轴旋转的角度为 ±90°
      this.controls.minAzimuthAngle = -Math.PI / 2; // 最小角度（-90°）
      this.controls.maxAzimuthAngle = Math.PI / 2; // 最大角度（90°）

      // 添加辅助元素
      this.addGridHelper(); // 自定义网格
      this.addAxesHelper(); // 坐标轴辅助线

      // 启动渲染循环
      this.animate();

      // 添加：确保网格可见性正确设置
      if (this.gridHelper) {
        this.gridHelper.visible = this.gridVisible;
      }
    },

    /**
     * 添加自定义网格（0,0到300,180，间隔10单位，沿Z轴向下移动材料厚度值）
     */
    addGridHelper() {
      const zOffset = 0; // 暂时先不沿Z轴向下移动材料厚度值this.materialThickness

      // 内部网格（浅灰色半透明）
      const innerVertices = [];
      const innerColors = [];
      const innerColor = new THREE.Color(0xcccccc);

      // 水平线（排除边界）
      for (let y = 10; y < 180; y += 10) {
        innerVertices.push(0, y, zOffset, 300, y, zOffset);
        innerColors.push(
          ...[innerColor.r, innerColor.g, innerColor.b].flatMap(() => [
            innerColor.r,
            innerColor.g,
            innerColor.b,
          ])
        );
      }
      // 垂直线（排除边界）
      for (let x = 10; x < 300; x += 10) {
        innerVertices.push(x, 0, zOffset, x, 180, zOffset);
        innerColors.push(
          ...[innerColor.r, innerColor.g, innerColor.b].flatMap(() => [
            innerColor.r,
            innerColor.g,
            innerColor.b,
          ])
        );
      }
      const innerGeometry = new THREE.BufferGeometry();
      innerGeometry.setAttribute('position', new THREE.Float32BufferAttribute(innerVertices, 3));
      innerGeometry.setAttribute('color', new THREE.Float32BufferAttribute(innerColors, 3));
      const innerMaterial = new THREE.LineBasicMaterial({
        vertexColors: true,
        transparent: true,
        opacity: 0.5,
      });
      const innerGrid = new THREE.LineSegments(innerGeometry, innerMaterial);

      // 外边框（深灰色）
      const borderVertices = [
        0,
        0,
        zOffset,
        300,
        0,
        zOffset,
        300,
        0,
        zOffset,
        300,
        180,
        zOffset,
        300,
        180,
        zOffset,
        0,
        180,
        zOffset,
        0,
        180,
        zOffset,
        0,
        0,
        zOffset,
      ];

      const borderGeometry = new THREE.BufferGeometry();
      borderGeometry.setAttribute('position', new THREE.Float32BufferAttribute(borderVertices, 3));

      const borderMaterial = new THREE.LineBasicMaterial({
        color: 0x666666,
        linewidth: 2,
      });

      const borderGrid = new THREE.LineSegments(borderGeometry, borderMaterial);

      // 将网格组合成一个组
      this.gridHelper = new THREE.Group();
      this.gridHelper.add(innerGrid);
      this.gridHelper.add(borderGrid);

      // 如果字体已加载，添加坐标标尺
      if (this.font) {
        console.log('正在添加网格坐标标记');
        // 添加X轴标尺数字 - 修改间隔为10
        for (let x = 0; x <= 300; x += 10) {
          // 创建文本标签
          const textGeometry = new TextGeometry(x.toString(), {
            font: this.font,
            size: 3, // 减小字体大小
            height: 0.1,
            curveSegments: 4, // 减少曲线分段以提高性能
          });
          textGeometry.computeBoundingBox();
          const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
          const textMesh = new THREE.Mesh(textGeometry, textMaterial);

          // 定位文本（X轴下方，保持在XY平面上）
          textMesh.position.set(x - 3, -8, zOffset);

          // 确保文本朝向正确，在XY平面上
          textMesh.rotation.set(0, 0, 0);

          this.gridHelper.add(textMesh);
        }

        // 添加Y轴标尺数字 - 修改间隔为10
        for (let y = 0; y <= 180; y += 10) {
          // 创建文本标签
          const textGeometry = new TextGeometry(y.toString(), {
            font: this.font,
            size: 3, // 减小字体大小
            height: 0.1,
            curveSegments: 4, // 减少曲线分段以提高性能
          });
          textGeometry.computeBoundingBox();
          const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
          const textMesh = new THREE.Mesh(textGeometry, textMaterial);

          // 定位文本（Y轴左方，保持在XY平面上）
          textMesh.position.set(-12, y - 3, zOffset);

          // 确保文本朝向正确，在XY平面上
          textMesh.rotation.set(0, 0, 0);

          this.gridHelper.add(textMesh);
        }
      } else {
        console.warn('字体尚未加载，无法添加坐标标记');
      }

      this.scene.add(this.gridHelper);

      // 修改：默认显示网格，方便调试
      this.gridVisible = true;
      this.gridHelper.visible = this.gridVisible;
    },

    /**
     * 更新材料和网格
     */
    updateMaterialAndGrid() {
      this.updateMaterialCube();
      this.updateGridHelper();
      this.adjustCameraToMaterial();
    },

    /**
     * 更新自定义网格
     */
    updateGridHelper() {
      // 移除旧网格
      if (this.gridHelper) {
        this.scene.remove(this.gridHelper);
        // 清理网格中的所有子对象
        this.gridHelper.children.forEach((child) => {
          child.geometry.dispose();
          child.material.dispose();
        });
      }

      // 重新添加网格，使用当前材料尺寸
      this.addGridHelper();
    },

    /**
     * 调整相机视角以适应材料尺寸
     */
    adjustCameraToMaterial() {
      const center = new THREE.Vector3(this.materialLength / 2, this.materialWidth / 2, 0);

      // 计算正交相机参数
      const maxDim = Math.max(this.materialLength, this.materialWidth);
      const aspect = window.innerWidth / window.innerHeight;

      // 设置正交相机范围
      this.camera.left = (-maxDim * aspect) / 1.5;
      this.camera.right = (maxDim * aspect) / 1.5;
      this.camera.top = maxDim / 1.5;
      this.camera.bottom = -maxDim / 1.5;

      // 调整相机位置
      this.camera.position.set(
        center.x,
        center.y,
        200 // 保持固定高度
      );
      this.camera.zoom = 0.8;
      this.camera.updateProjectionMatrix();

      this.camera.lookAt(center);
      this.controls.target.copy(center);
    },

    /**
     * 添加坐标轴辅助线（X轴红色, Y轴绿色，长度为100）
     */
    addAxesHelper() {
      const axesLength = 100; // 坐标轴长度
      const xAxisGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(axesLength, 0, 0),
      ]);
      const yAxisGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, axesLength, 0),
      ]);

      const xAxisMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 }); // 红色X轴
      const yAxisMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 }); // 绿色Y轴

      this.scene.add(new THREE.Line(xAxisGeometry, xAxisMaterial)); // 添加X轴
      this.scene.add(new THREE.Line(yAxisGeometry, yAxisMaterial)); // 添加Y轴
    },

    /**
     * 更新材料贴图
     */
    updateMaterialTexture() {
      // 如果材料立方体已存在，更新其贴图
      if (this.materialCube) {
        this.updateMaterialCube();
      }
    },

    /**
     * 创建/更新材料立方体
     */
    updateMaterialCube() {
      // 清理旧模型
      if (this.materialCube) {
        this.scene.remove(this.materialCube);
        this.materialCube.geometry.dispose();
        this.materialCube.material.dispose();
      }

      // 创建新立方体（长=materialLength，宽=materialWidth，高=materialThickness）
      const geometry = new THREE.BoxGeometry(
        this.materialLength,
        this.materialWidth,
        this.materialThickness
      );

      // 基础材质属性
      const materialProps = {
        color: 0xffffff, // 白色，以便贴图显示正确颜色
        transparent: true,
        opacity: 0.7, // 设置透明度为30%
        depthWrite: false, // 避免透明渲染问题
      };

      // 如果选择了贴图，则加载贴图
      if (this.selectedTexture) {
        // 创建纹理加载器
        const textureLoader = new THREE.TextureLoader();
        // 加载贴图
        const texturePath = `/material/${this.selectedTexture}`;
        const texture = textureLoader.load(
          texturePath,
          // 加载成功回调
          (loadedTexture) => {
            // 设置纹理重复
            loadedTexture.wrapS = THREE.RepeatWrapping;
            loadedTexture.wrapT = THREE.RepeatWrapping;
            // 根据立方体尺寸调整纹理重复次数
            loadedTexture.repeat.set(1, 1);
            // 更新材质
            if (this.materialCube && this.materialCube.material) {
              this.materialCube.material.map = loadedTexture;
              this.materialCube.material.needsUpdate = true;
            }
          },
          // 加载进度回调
          undefined,
          // 加载错误回调
          (err) => {
            console.error('贴图加载失败:', err);
          }
        );

        // 添加贴图到材质属性
        materialProps.map = texture;
      }

      const material = new THREE.MeshPhongMaterial(materialProps);

      this.materialCube = new THREE.Mesh(geometry, material);
      // 定位立方体中心到材料中心，底部贴合Z=0平面
      this.materialCube.position.set(
        this.materialLength / 2,
        this.materialWidth / 2,
        -this.materialThickness / 2
      );
      this.scene.add(this.materialCube);
    },

    /**
     * 添加当前位置指示器（红色锥体）
     */
    addPositionIndicator() {
      if (!this.currentPosition) {
        const geometry = new THREE.ConeGeometry(2, 5, 16);
        const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
        this.currentPosition = new THREE.Mesh(geometry, material);
        this.currentPosition.rotation.x = -Math.PI / 2; // 使锥体尖端朝前
        this.scene.add(this.currentPosition);
      }
    },

    /**
     * 隐藏当前位置指示器（红色锥体）
     */
    hidePositionIndicator() {
      if (this.currentPosition) {
        this.scene.remove(this.currentPosition);
        this.currentPosition.geometry.dispose();
        this.currentPosition.material.dispose();
        this.currentPosition = null;
      }
    },

    /*
     * 新增初始位置标记方法
     */
    // createStartMarker() {
    //   // const geometry = new THREE.SphereGeometry(3, 16, 16)
    //   // const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 })
    //   // const marker = new THREE.Mesh(geometry, material)
    //   // marker.position.copy(position)
    //   // this.scene.add(marker)
    //   // this.pathLines.push(marker) // 统一管理便于清理
    // },

    /**
     * 加载并解析GCode文件
     */
    async handleGCodeBlob() {
      // console.log('22223333');
      console.log(this.$store.state.webSocket.gCode, 'this.getGCodethis.getGCode');
      const blob = this.$store.state.webSocket.gCode;
      try {
        // 将 Blob 转换为 File 对象
        const file = new File([blob], 'gcode_file.gcode', { type: 'text/plain' });
        await this.loadGCodeFile(file); // 直接调用加载方法
      } catch (error) {
        console.error('Error processing blob:', error);
      }
    },
    async loadGCodeFile(file) {
      console.log(file, 'filefile');
      // const reader = new FileReader();
      // reader.onloadend = function () {
      //   const cleanBase64 = reader.result.replace(/^data:application\/octet-stream;base64,/, ''); // 去除前缀
      //   const decodedString = atob(cleanBase64);
      //   console.log(decodedString, 'decodedStringdecodedString');
      // };

      // reader.readAsDataURL(file);
      // const file = await this.pickFile();
      if (!file) return;
      // 设置加载状态为true
      this.isLoading = true;
      // 重置界面内容
      this.resetUI();
      this.updateMaterialCube(); // 新增立方体创建
      try {
        const content = await this.readFile(file);
        console.log(content, 'contentcontent');
        // 保存原始GCode内容
        this.originalGCodeContent = content;
        // 确保进度条重置为0
        this.progress = 0;

        // 使用setTimeout让UI有机会更新，并避免长时间解析阻塞UI
        setTimeout(() => {
          this.parseGCode(content);
          this.adjustCameraView();
          this.updateGridHelper();

          // 先添加位置指示器，然后再设置位置
          this.addPositionIndicator();
          console.log(this.gcodePoints.length);

          // 修改锥体定位逻辑 - 确保使用第一个点
          if (this.gcodePoints.length > 0) {
            const firstPoint = this.gcodePoints[0];
            // 增加位置偏移避免Z轴穿透
            const zOffset = 2.5;
            this.currentPosition.position.set(firstPoint.x, firstPoint.y, firstPoint.z + zOffset);
            // 添加初始位置标记
            // this.createStartMarker(firstPoint);
          }

          // 解析完成后，设置加载状态为false
          this.isLoading = false;
        }, 100);
      } catch (error) {
        console.error('Loading fail:', error);
        alert('File reading error, please check the file format.');
        // 出错时也要重置加载状态
        this.isLoading = false;
      }
    },

    /**
     * 重置界面内容
     */
    resetUI() {
      this.cleanupPath(); // 清理路径
      this.hidePositionIndicator(); // 隐藏锥体
      this.progress = 0; // 重置进度条
      this.speed = 1; // 重置播放速度
      this.animationRunning = false; // 停止动画
      this.fileLineCount = null; // 清空文件行数
      this.parsedGCodeContent = ''; // 清空解析后的坐标内容
      this.originalGCodeContent = ''; // 清空原始GCode内容
      // this.cleanupMaterialBox(); // 清理材料盒的方法
      // this.addMaterialBox(); // 重新添加新的材料盒
    },

    /**
     * 文件选择器封装
     */
    pickFile() {
      return new Promise((resolve) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.gcode,.gc,.nc'; // 支持 .gcode, .gc, .nc 文件
        input.onchange = (e) => resolve(e.target.files[0]);
        input.click();
      });
    },

    /**
     * 文件读取封装
     */
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = reject;
        reader.readAsText(file);
      });
    },

    /**
     * 解析GCode文件内容 - 优化版本
     */
    parseGCode(content) {
      this.cleanupPath();
      const lines = content.split('\n');
      this.fileLineCount = lines.length;
      let currentPoint = null;
      let lastGCommand = '';
      const parsedPoints = [];

      // 临时存储所有解析出的点和线段
      const tempSegments = [];
      let currentSegment = null;

      // 第一阶段：解析所有点并分段
      lines.forEach((line, index) => {
        const cmdWithoutComment = line.split(';')[0].trim();
        if (!cmdWithoutComment) return;

        if (this.isMovementCommand(cmdWithoutComment)) {
          const result = this.parseGCodeCommand(
            cmdWithoutComment,
            currentPoint || new THREE.Vector3(0, 0, 0),
            lastGCommand
          );

          if (result === null) return;

          const { point: newPoint, command: gCommand } = result;

          if (gCommand) lastGCommand = gCommand;

          if (!currentPoint) {
            currentPoint = newPoint.clone();
            // 开始新段
            currentSegment = {
              points: [currentPoint.clone()],
              isRapidMove: lastGCommand === 'G0',
              lineIndices: [index],
            };
            tempSegments.push(currentSegment);

            parsedPoints.push(
              `Line ${index + 1}: INIT (${currentPoint.x.toFixed(2)}, ${currentPoint.y.toFixed(
                2
              )}, ${currentPoint.z.toFixed(2)})`
            );
          } else if (!newPoint.equals(currentPoint)) {
            const isRapidMove = lastGCommand === 'G0';

            // 如果移动类型改变，开始新段
            if (isRapidMove !== currentSegment.isRapidMove) {
              currentSegment = {
                points: [currentPoint.clone()],
                isRapidMove,
                lineIndices: [],
              };
              tempSegments.push(currentSegment);
            }

            // 添加点到当前段
            currentSegment.points.push(newPoint.clone());
            currentSegment.lineIndices.push(index);

            parsedPoints.push(
              `Line ${index + 1}: MOVE (${newPoint.x.toFixed(2)}, ${newPoint.y.toFixed(
                2
              )}, ${newPoint.z.toFixed(2)})`
            );

            currentPoint = newPoint.clone();
          }
        }
      });

      // 第二阶段：简化路径并创建可视化
      this.gcodePoints = []; // 重置路径点
      this.pathSegments = []; // 重置路径段

      // 确保第一个点被正确记录
      if (tempSegments.length > 0 && tempSegments[0].points.length > 0) {
        // 将第一个点添加到gcodePoints，确保锥体有正确的起始位置
        this.gcodePoints.push(tempSegments[0].points[0].clone());
      }

      tempSegments.forEach((segment) => {
        // 对每个段应用简化算法
        let pointsToRender = segment.points;

        // 只有当启用简化且点数足够多时才简化
        if (this.simplificationEnabled && segment.points.length > 10) {
          pointsToRender = this.simplifyPath(segment.points, this.simplificationTolerance);
          console.log(`路径段简化: ${segment.points.length} 点减少到 ${pointsToRender.length} 点`);
        }

        // 创建可视化路径
        for (let i = 1; i < pointsToRender.length; i++) {
          // 存储路径段信息
          this.pathSegments.push({
            from: pointsToRender[i - 1].clone(),
            to: pointsToRender[i].clone(),
            isRapidMove: segment.isRapidMove,
          });

          // 创建可视化路径
          this.createPathSegment(pointsToRender[i - 1], pointsToRender[i], segment.isRapidMove);
        }
      });

      // 创建已走过路径的可视化
      this.createTraversedPathVisuals();

      // 限制显示的解析点数量
      const maxDisplayPoints = 1000;
      if (parsedPoints.length > maxDisplayPoints) {
        const step = Math.ceil(parsedPoints.length / maxDisplayPoints);
        const sampledPoints = [];
        for (let i = 0; i < parsedPoints.length; i += step) {
          sampledPoints.push(parsedPoints[i]);
        }
        this.parsedGCodeContent = sampledPoints.join('\n');
      } else {
        this.parsedGCodeContent = parsedPoints.join('\n');
      }
    },

    /**
     * 道格拉斯-普克算法实现路径简化
     * @param {Array<THREE.Vector3>} points - 原始点数组
     * @param {Number} tolerance - 简化容差
     * @returns {Array<THREE.Vector3>} 简化后的点数组
     */
    simplifyPath(points, tolerance) {
      if (points.length <= 2) return points;

      // 查找最远点
      let maxDistance = 0;
      let index = 0;
      const end = points.length - 1;

      // 计算每个点到首尾连线的距离
      for (let i = 1; i < end; i++) {
        const distance = this.perpendicularDistance(points[i], points[0], points[end]);
        if (distance > maxDistance) {
          maxDistance = distance;
          index = i;
        }
      }

      // 如果最大距离大于容差，则递归简化
      if (maxDistance > tolerance) {
        // 递归处理两部分
        const firstPart = this.simplifyPath(points.slice(0, index + 1), tolerance);
        const secondPart = this.simplifyPath(points.slice(index), tolerance);

        // 合并结果（去除重复点）
        return [...firstPart.slice(0, -1), ...secondPart];
      }
      // 如果所有点都在容差范围内，则只保留首尾点
      return [points[0], points[end]];
    },

    /**
     * 计算点到线段的垂直距离
     * @param {THREE.Vector3} point - 待测点
     * @param {THREE.Vector3} lineStart - 线段起点
     * @param {THREE.Vector3} lineEnd - 线段终点
     * @returns {Number} 垂直距离
     */
    perpendicularDistance(point, lineStart, lineEnd) {
      // 线段向量
      const line = new THREE.Vector3().subVectors(lineEnd, lineStart);
      const lineLength = line.length();

      // 如果线段长度为0，直接返回点到起点的距离
      if (lineLength === 0) return new THREE.Vector3().subVectors(point, lineStart).length();

      // 计算点到线的投影比例
      const t = Math.max(
        0,
        Math.min(
          1,
          new THREE.Vector3().subVectors(point, lineStart).dot(line) / (lineLength * lineLength)
        )
      );

      // 计算投影点
      const projection = new THREE.Vector3().addVectors(lineStart, line.clone().multiplyScalar(t));

      // 返回点到投影点的距离
      return new THREE.Vector3().subVectors(point, projection).length();
    },

    /**
     * 判断是否为移动指令
     */
    isMovementCommand(line) {
      // 检查是否包含G0/G1命令或任何坐标信息
      return line.match(/G[01]/) || line.match(/[XYZ]-?\d*\.?\d*/);
    },

    /**
     * 解析单行GCode指令
     * @param {string} line - GCode命令行
     * @param {THREE.Vector3} currentPoint - 当前位置
     * @param {string} lastGCommand - 上一个G命令
     * @returns {{point: THREE.Vector3, command: string}} 返回新位置点和G命令
     */
    parseGCodeCommand(line, currentPoint, lastGCommand) {
      let gCommand = lastGCommand;
      const coordinates = {
        X: currentPoint?.x || 0,
        Y: currentPoint?.y || 0,
        Z: currentPoint?.z || 0,
      };

      const gMatch = line.match(/G0|G1|G28|G92/);
      const [matchedGCommand] = gMatch || [];

      // 如果有匹配的命令，则更新 gCommand
      gCommand = matchedGCommand || gCommand;
      let hasCoordinates = false;
      const coordinateRegex = /([XYZ])([-+]?\d*\.?\d*([eE][-+]?\d+)?)/g;
      let match;
      while (true) {
        match = coordinateRegex.exec(line);
        if (!match) break;
        const [, axis, valueStr] = match;
        const value = parseFloat(valueStr);
        if (!Number.isNaN(value)) {
          coordinates[axis] = value;
          hasCoordinates = true;
        }
      }

      if ((gCommand === 'G0' || gCommand === 'G1') && !hasCoordinates) {
        return null;
      }

      if (gCommand === 'G28') {
        coordinates.X = 0;
        coordinates.Y = 0;
        coordinates.Z = 0;
        hasCoordinates = true;
      }

      return {
        point: new THREE.Vector3(coordinates.X, coordinates.Y, coordinates.Z),
        command: gCommand,
      };
    },

    /**
     * 创建已走过路径的可视化
     */
    createTraversedPathVisuals() {
      // 创建已走过的G0快速移动线条
      this.traversedRapidMoveGeometry = new THREE.BufferGeometry();
      const traversedRapidMaterial = new THREE.LineBasicMaterial({ color: 0x999999 }); // 灰色
      this.traversedRapidMoveLine = new THREE.LineSegments(
        this.traversedRapidMoveGeometry,
        traversedRapidMaterial
      );
      this.scene.add(this.traversedRapidMoveLine);
      this.pathLines.push(this.traversedRapidMoveLine);

      // 创建已走过的G1普通移动线条
      this.traversedNormalMoveGeometry = new THREE.BufferGeometry();
      const traversedNormalMaterial = new THREE.LineBasicMaterial({ color: 0x999999 }); // 灰色
      this.traversedNormalMoveLine = new THREE.LineSegments(
        this.traversedNormalMoveGeometry,
        traversedNormalMaterial
      );
      this.scene.add(this.traversedNormalMoveLine);
      this.pathLines.push(this.traversedNormalMoveLine);

      // 初始化几何体
      this.traversedRapidMoveGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(new Float32Array(0), 3)
      );
      this.traversedNormalMoveGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(new Float32Array(0), 3)
      );
    },

    /**
     * 创建路径线段 - 优化版本
     */
    createPathSegment(from, to, isRapidMove) {
      // 存储点用于动画
      this.gcodePoints.push(to.clone());

      // 根据移动类型添加到不同的几何体
      if (isRapidMove) {
        if (!this.rapidMoveGeometry) {
          this.rapidMoveGeometry = new THREE.BufferGeometry();
          const material = new THREE.LineBasicMaterial({ color: 0xff5733 }); // 橙色0xffa500，或红色0xff5733
          this.rapidMoveLine = new THREE.LineSegments(this.rapidMoveGeometry, material);
          this.scene.add(this.rapidMoveLine);
          this.pathLines.push(this.rapidMoveLine);
        }

        // 添加点到几何体
        this.addPointsToGeometry(this.rapidMoveGeometry, from, to);
      } else {
        if (!this.normalMoveGeometry) {
          this.normalMoveGeometry = new THREE.BufferGeometry();
          const material = new THREE.LineBasicMaterial({ color: 0x0000ff }); // 蓝色
          this.normalMoveLine = new THREE.LineSegments(this.normalMoveGeometry, material);
          this.scene.add(this.normalMoveLine);
          this.pathLines.push(this.normalMoveLine);
        }

        // 添加点到几何体
        this.addPointsToGeometry(this.normalMoveGeometry, from, to);
      }
    },

    /**
     * 添加点到几何体
     */
    addPointsToGeometry(geometry, from, to) {
      // 获取当前几何体的顶点
      const positions = geometry.attributes.position;
      const currentCount = positions ? positions.count : 0;

      // 创建新的顶点数组
      const newPositions = new Float32Array((currentCount + 2) * 3);

      // 复制现有顶点
      if (currentCount > 0) {
        newPositions.set(positions.array);
      }

      // 添加新点
      newPositions[currentCount * 3] = from.x;
      newPositions[currentCount * 3 + 1] = from.y;
      newPositions[currentCount * 3 + 2] = from.z;

      newPositions[(currentCount + 1) * 3] = to.x;
      newPositions[(currentCount + 1) * 3 + 1] = to.y;
      newPositions[(currentCount + 1) * 3 + 2] = to.z;

      // 更新几何体
      geometry.setAttribute('position', new THREE.BufferAttribute(newPositions, 3));
      geometry.attributes.position.needsUpdate = true;

      // 更新包围盒
      geometry.computeBoundingSphere();
    },

    /**
     * 调整相机视角以显示完整路径（修改适配正交相机）
     */
    adjustCameraView() {
      if (this.gcodePoints.length === 0) return;
      const box = new THREE.Box3().setFromPoints(this.gcodePoints);
      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());

      // 计算正交相机参数
      const maxDim = Math.max(size.x, size.y);
      const aspect = window.innerWidth / window.innerHeight;

      // 设置正交相机范围
      this.camera.left = (-maxDim * aspect) / 2;
      this.camera.right = (maxDim * aspect) / 2;
      this.camera.top = maxDim / 2;
      this.camera.bottom = -maxDim / 2;

      // 调整相机位置和缩放
      this.camera.position.set(
        center.x,
        center.y,
        200 // 保持固定高度
      );
      this.camera.zoom = 0.8; // 缩放系数
      this.camera.updateProjectionMatrix();

      this.camera.lookAt(center);
      this.controls.target.copy(center);
    },

    /**
     * 处理进度条输入
     */
    handleProgress() {
      const index = Math.floor((this.progress / 100) * (this.gcodePoints.length - 1));
      if (this.gcodePoints[index]) {
        const point = this.gcodePoints[index];
        this.currentPosition.position.set(point.x, point.y, point.z + 2.5); // 锥体底部与点重合

        // 更新已走过/未走过的路径显示
        this.updatePathVisuals(index);
      }
    },

    /**
     * 更新已走过/未走过的路径显示
     * @param {Number} currentIndex - 当前进度对应的点索引
     */
    updatePathVisuals(currentIndex) {
      if (!this.pathSegments.length) return;

      // 计算当前走过的路径段数量
      const traversedSegmentCount = Math.min(currentIndex, this.pathSegments.length);

      // 准备已走过路径的顶点数据
      const traversedRapidVertices = [];
      const traversedNormalVertices = [];

      // 准备未走过路径的顶点数据
      const remainingRapidVertices = [];
      const remainingNormalVertices = [];

      // 分配顶点到已走过/未走过的数组
      for (let i = 0; i < this.pathSegments.length; i++) {
        const segment = this.pathSegments[i];

        if (i < traversedSegmentCount) {
          // 已走过的路径
          if (segment.isRapidMove) {
            traversedRapidVertices.push(
              segment.from.x,
              segment.from.y,
              segment.from.z,
              segment.to.x,
              segment.to.y,
              segment.to.z
            );
          } else {
            traversedNormalVertices.push(
              segment.from.x,
              segment.from.y,
              segment.from.z,
              segment.to.x,
              segment.to.y,
              segment.to.z
            );
          }
        } else if (segment.isRapidMove) {
          // 未走过的路径
          remainingRapidVertices.push(
            segment.from.x,
            segment.from.y,
            segment.from.z,
            segment.to.x,
            segment.to.y,
            segment.to.z
          );
        } else {
          remainingNormalVertices.push(
            segment.from.x,
            segment.from.y,
            segment.from.z,
            segment.to.x,
            segment.to.y,
            segment.to.z
          );
        }
      }

      // 更新已走过的路径几何体
      this.traversedRapidMoveGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(new Float32Array(traversedRapidVertices), 3)
      );
      this.traversedNormalMoveGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(new Float32Array(traversedNormalVertices), 3)
      );

      // 更新未走过的路径几何体
      this.rapidMoveGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(new Float32Array(remainingRapidVertices), 3)
      );
      this.normalMoveGeometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(new Float32Array(remainingNormalVertices), 3)
      );

      // 更新包围盒
      this.traversedRapidMoveGeometry.computeBoundingSphere();
      this.traversedNormalMoveGeometry.computeBoundingSphere();
      this.rapidMoveGeometry.computeBoundingSphere();
      this.normalMoveGeometry.computeBoundingSphere();
    },

    /**
     * 切换动画状态
     */
    toggleAnimation() {
      this.animationRunning = !this.animationRunning;
      if (this.animationRunning) this.animateMovement();
    },

    /**
     * 动画循环
     */
    animateMovement() {
      if (!this.animationRunning) return;
      this.progress = Math.min(this.progress + 0.05 * this.speed, 100); // 减慢播放速度
      this.handleProgress();

      // 检查是否播放完成
      if (this.progress >= 100) {
        this.animationRunning = false; // 停止动画
        // 添加延迟，让用户能看到100%的状态，然后重置到0%
        setTimeout(() => {
          this.progress = 0; // 重置进度到0%
          this.handleProgress(); // 更新位置指示器和路径显示
        }, 500); // 500毫秒延迟
        return; // 退出动画循环
      }

      requestAnimationFrame(this.animateMovement);
    },

    /**
     * 单步后退
     */
    stepBackward() {
      if (this.gcodePoints.length === 0) return;
      this.progress = Math.max(this.progress - 100 / (this.gcodePoints.length - 1), 0);
      this.handleProgress();
    },

    /**
     * 单步前进
     */
    stepForward() {
      if (this.gcodePoints.length === 0) return;
      this.progress = Math.min(this.progress + 100 / (this.gcodePoints.length - 1), 100);
      this.handleProgress();
    },

    /**
     * 增加播放速度
     */
    increaseSpeed() {
      this.speed = Math.min(this.speed + 1, 10);
    },

    /**
     * 减少播放速度
     */
    decreaseSpeed() {
      this.speed = Math.max(this.speed - 1, 1);
    },

    /**
     * 清理路径资源 - 优化版本
     */
    cleanupPath() {
      // 清理路径线
      this.pathLines.forEach((line) => {
        this.scene.remove(line);
        if (line.geometry) line.geometry.dispose();
        if (line.material) line.material.dispose();
      });

      // 重置所有相关变量
      this.pathLines = [];
      this.gcodePoints = [];
      this.pathSegments = []; // 清空路径段信息
      this.rapidMoveGeometry = null;
      this.normalMoveGeometry = null;
      this.rapidMoveLine = null;
      this.normalMoveLine = null;
      this.traversedRapidMoveGeometry = null;
      this.traversedNormalMoveGeometry = null;
      this.traversedRapidMoveLine = null;
      this.traversedNormalMoveLine = null;
      this.progress = 0;
    },

    /**
     * 窗口大小变化处理（修改适配正交相机）
     */
    onWindowResize() {
      const aspect = window.innerWidth / window.innerHeight;

      // 保持原有可见范围比例
      this.camera.left = -this.camera.right * aspect;
      this.camera.right *= aspect;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },

    /**
     * 主渲染循环
     */
    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update(); // 必须更新控制器
      this.renderer.render(this.scene, this.camera);
    },

    /**
     * 组件销毁时清理资源
     */
    cleanupResources() {
      this.renderer.dispose();
      this.controls.dispose();
      this.cleanupPath();
      this.hidePositionIndicator();
    },
  },
};
</script>

<style scoped>
/* 路径简化切换按钮样式 */
.simplification-toggle {
  display: flex;
  align-items: center;
}

/* 网格显示开关样式 */
.grid-toggle {
  margin-left: 10px; /* 与前一个开关保持一定距离 */
  display: flex;
  align-items: center;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.toggle-label input[type='checkbox'] {
  margin-right: 5px;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.toggle-label input[type='checkbox'] {
  margin-right: 5px;
}

/* GCode 文件内容显示区域样式 */
.gcode-viewer {
  width: 100%;
  height: auto;
  background: #f9f9f9;
  border: 1px solid #ddd;
  overflow: auto;
  margin-bottom: 10px;
}
.gcode-viewer textarea {
  width: 100%;
  padding: 10px;
  font-family: monospace;
  font-size: 14px;
  border: none;
  resize: none;
  background: transparent;
}

.control-bar {
  padding: 10px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 15px;
  align-items: center;
}
.control-bar div {
  display: flex;
  align-items: center;
}
.control-bar label {
  margin-right: 5px;
}
.control-bar input[type='number'] {
  width: 60px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button {
  padding: 8px 16px;
  background: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.preview-container {
  width: 100%;
  height: calc(109vh - 300px); /* 调整高度以容纳文本框 */
}
::v-deep .preview-container canvas {
  width: 100% !important;
  height: 100% !important;
}
input[type='range'] {
  flex: 1;
  max-width: 300px;
}
/* 添加样式 */
.control-bar .material-inputs {
  display: flex;
  gap: 15px;
  margin-right: 20px;
}

.control-bar .material-inputs > div {
  display: flex;
  align-items: center;
}

.control-bar .material-inputs input[type='number'] {
  width: 80px; /* 统一输入框宽度 */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  -moz-appearance: textfield; /* Firefox隐藏箭头 */
}

/* Chrome/Safari隐藏箭头 */
.control-bar .material-inputs input[type='number']::-webkit-outer-spin-button,
.control-bar .material-inputs input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 0 auto 15px;
  animation: spin 2s linear infinite;
}

.loading-text {
  font-size: 16px;
  color: #333;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 禁用按钮样式 */
button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.speed-display {
  padding: 0 8px;
  display: inline-block;
  min-width: 30px;
  text-align: center;
}
button {
  margin: 0 5px;
}
</style>
