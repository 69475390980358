// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  progress: 0,
  // 剩余时间
  remainingTime: '00:00:00',
  spentTime: '00:00:00',
  workPosition: '0,0,0',
  homePosition: '0,0,0',
};
const mutations = {
  setProgress(aaa, sss) {
    console.log(sss, 'sssssssssssssss1');

    aaa.progress = sss;
  },
  setRemainingTime(aaa, value) {
    console.log(value, 'sssssssssssssss12');
    aaa.remainingTime = value;
  },
  setSpentTime(aaa, value) {
    console.log(value, 'sssssssssssssss123');
    aaa.spentTime = value;
  },
  setWorkPosition(aaa, value) {
    console.log(value, 'sssssssssssssss1234');
    aaa.workPosition = value;
  },
  setHomePosition(aaa, value) {
    console.log(value, 'sssssssssssssss12345');
    aaa.homePosition = value;
  },
};
const actions = {
  setProgress({ commit }, width) {
    // const socket = new WebSocket(`ws://10.10.10.94:${port}/ws`);
    commit('setProgress', width);
  },
  setRemainingTime({ commit }, height) {
    commit('setRemainingTime', height);
  },
  setSpentTime({ commit }, height) {
    commit('setSpentTime', height);
  },
  setWorkPosition({ commit }, height) {
    commit('setWorkPosition', height);
  },
  setHomePosition({ commit }, height) {
    commit('setHomePosition', height);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
