<template>
  <div class="container">
    <!-- XYZ轴控制面板 -->
    <div class="control-panel">
      <div style="display: flex">
        <div style="margin-right: 10px">
          <div style="padding: 0 10px">Distance</div>
          <el-select
            v-model="moveValue"
            placeholder="Choose"
            @change="selectChange"
            style="margin-bottom: 15px; width: 100%"
          >
            <el-option
              v-for="item in moveOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <div style="padding: 0 10px">Speed</div>
          <el-select
            v-model="speedValue"
            placeholder="Choose"
            style="margin-bottom: 15px; width: 100%"
          >
            <el-option
              v-for="item in speedOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="control-grid">
        <!-- 第一行 - 只有Y+ -->
        <div class="grid-row">
          <div class="grid-cell"></div>
          <div class="grid-cell">
            <el-button @click="yAddMove" :disabled="moveDisable" class="control-button y-btn">
              Y+
            </el-button>
          </div>
          <div class="grid-cell"></div>
          <div class="grid-cell">
            <el-button @click="zRedMove" :disabled="moveDisable" class="control-button z-btn">
              Z-
            </el-button>
          </div>
        </div>

        <!-- 第二行 - X-, Y-, X+ -->
        <div class="grid-row">
          <div class="grid-cell">
            <el-button @click="xRedMove" :disabled="moveDisable" class="control-button x-btn">
              X-
            </el-button>
          </div>
          <div class="grid-cell">
            <el-button @click="yRedMove" :disabled="moveDisable" class="control-button y-btn">
              Y-
            </el-button>
          </div>
          <div class="grid-cell">
            <el-button @click="xAddMove" :disabled="moveDisable" class="control-button x-btn">
              X+
            </el-button>
          </div>
          <div class="grid-cell">
            <el-button @click="zAddMove" :disabled="moveDisable" class="control-button z-btn">
              Z+
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'debug-dialog',
  data() {
    return {
      moveValue: '5.0',
      moveOptions: [
        { label: '0.1mm', value: '0.1' },
        { label: '1.0mm', value: '1.0' },
        { label: '5.0mm', value: '5.0' },
        { label: '10.0mm', value: '10.0' },
      ],
      speedValue: '500',
      speedOptions: [
        { label: '100', value: '100' },
        { label: '200', value: '200' },
        { label: '500', value: '500' },
        { label: '1000', value: '1000' },
      ],
      moveDisable: false,
    };
  },
  computed: {
    // 监听 Vuex 中的 socket 状态
    getSocket() {
      return this.$store.state.webSocket.showLaser;
    },
  },
  watch: {},
  methods: {
    selectChange() {
      this.moveDisable = false;
    },
    // 机器移动
    xAddMove() {
      const data = {
        action: 'jog',
        direction: 'X',
        size: this.moveValue,
        feedRate: this.speedValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    xRedMove() {
      const data = {
        action: 'jog',
        direction: 'X',
        size: -this.moveValue,
        feedRate: this.speedValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    yAddMove() {
      const data = {
        action: 'jog',
        direction: 'Y',
        size: this.moveValue,
        feedRate: this.speedValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    yRedMove() {
      const data = {
        action: 'jog',
        direction: 'Y',
        size: -this.moveValue,
        feedRate: this.speedValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    zAddMove() {
      const data = {
        action: 'jog',
        direction: 'Z',
        size: this.moveValue,
        feedRate: this.speedValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
    zRedMove() {
      const data = {
        action: 'jog',
        direction: 'Z',
        size: -this.moveValue,
        feedRate: this.speedValue,
      };
      this.getSocket.send(JSON.stringify(data));
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  // padding: 0 20px;
}

.control-panel {
  margin-bottom: 20px;
  width: 100%;
}

.control-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.grid-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.grid-cell {
  width: 25%;
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 5px;
}

.control-button {
  width: 100%;
  min-width: 50px;
  height: 40px;
  font-weight: bold;
}

.el-select {
  width: 100%;
}
.el-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
}
control-button:active:not(:disabled) {
  transform: scale(0.95);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.control-button:hover:not(:disabled) {
  background-color: #409eff;
  color: white;
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.x-btn {
  background: linear-gradient(to right, #409eff, #53a8ff);
  color: white;
  border-color: #409eff;
}

.y-btn {
  background: linear-gradient(to right, #67c23a, #85ce61);
  color: white;
  border-color: #67c23a;
}

.z-btn {
  background: linear-gradient(to right, #e6a23c, #f0c78a);
  color: white;
  border-color: #e6a23c;
}
</style>
