// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  maschirWidth: null,
  maschirHeight: null,
  maschirLength: null,
  maschineName: null,
};
const mutations = {
  setMaschirWidth(aaa, sss) {
    aaa.maschirWidth = sss;
  },
  setMaschirHeight(aaa, value) {
    aaa.maschirHeight = value;
  },
  setMaschirLength(aaa, value) {
    aaa.maschirLength = value;
  },
  setMaschineName(aaa, value) {
    aaa.maschineName = value;
  },
};
const actions = {
  setMaschirWidth({ commit }, width) {
    // const socket = new WebSocket(`ws://10.10.10.94:${port}/ws`);
    commit('setMaschirWidth', width);
  },
  setMaschirHeight({ commit }, height) {
    commit('setMaschirHeight', height);
  },
  setMaschirLength({ commit }, height) {
    commit('setMaschirLength', height);
  },
  setMaschineName({ commit }, name) {
    commit('setMaschineName', name);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
