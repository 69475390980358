<template>
  <div class="container">
    <div style="width: 100%; margin-bottom: 20px">
      <el-steps :active="active" finish-status="success">
        <el-step title="Step 1"></el-step>
        <el-step title="Step 2"></el-step>
        <el-step title="Step 3"></el-step>
      </el-steps>
    </div>
    <div class="grid-container">
      <!-- step1 -->
      <div class="content-item" v-show="active === 0">
        <!-- 是否连接机器 -->
        <div>
          <span class="title">1.Confirm machine connection</span>

          <div style="padding: 0 20px">
            <div>
              <span>Machine Name:</span>
              <span>{{ maschineName ? maschineName : 'none' }}</span>
            </div>
            <div>
              <span>Connect:</span>
              <span>{{ connectionStatus }}</span>
            </div>
          </div>
          <div class="demo-image">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('@/assets/carft/p1.png')"
              :fit="fit"
            ></el-image>
          </div>
        </div>
        <div class="board">
          <span class="title">2.Confirm material dimensions</span>
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="Width(X)">
              <el-input-number
                v-model="form.x"
                :controls="false"
                :min="1"
                :max="maschirWidth"
                :step="10"
                :disabled="true"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Length(Y)">
              <el-input-number
                v-model="form.y"
                :controls="false"
                :min="1"
                :max="maschirHeight"
                :step="10"
                :disabled="true"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Thickness(Z)">
              <el-input-number
                v-model="form.z"
                :controls="false"
                :disabled="true"
              ></el-input-number>
            </el-form-item>
          </el-form>
        </div>
        <div class="board">
          <span class="title">3.Please fix the material</span>
          <div>Make sure the material is fixed before continue</div>
          <div class="demo-image">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('@/assets/carft/p2.png')"
              :fit="fit"
            ></el-image>
          </div>
        </div>
      </div>
      <!-- step2 -->
      <div class="content-item" v-show="active === 1">
        <div class="steps2">
          <span class="title">4.Secure the bit</span>
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="First bit" style="margin-top: 10px">
              <el-select v-model="form.firBitName" disabled placeholder="确认刀头">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <div style="margin: 10px 0">
              <span>Diameter:{{ firBitDiameter }}</span>
              <span v-if="firvAngle !== 0">Angle:{{ firvAngle }}</span>
            </div>
            <el-form-item label="Second bit" v-show="secBitDiameter !== 0">
              <el-select v-model="form.secBitName" disabled placeholder="确认刀头">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <div v-show="secBitDiameter !== 0" style="margin: 10px 0">
              <span>Diameter:{{ secBitDiameter }}</span>
              <span v-if="secvAngle !== 0">Angle:{{ secvAngle }}</span>
            </div>
          </el-form>
          <div class="demo-image">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('@/assets/carft/p3.png')"
              :fit="fit"
            ></el-image>
          </div>
        </div>
        <div>
          <span class="title">5.Start position</span>
          <Debugdialog></Debugdialog>
          <div class="button-container" style="display: flex">
            <el-button type="primary" @click="goHome" class="equal-button">Home</el-button>
            <el-button type="primary" @click="hold" class="equal-button">Hold</el-button>
            <el-button type="primary" @click="unlock" class="equal-button">Unlock</el-button>
          </div>
          <div class="button-container" style="display: flex; margin: 10px 0">
            <el-button type="primary" @click="reset" class="equal-button">Reset</el-button>
            <el-button
              type="primary"
              @mousedown.native="handleMouseDown"
              @mouseup.native="handleMouseUp"
              class="equal-button"
            >
              Spindle
            </el-button>
          </div>
        </div>
        <div>
          <div>
            <div class="title">6.Auto probing</div>

            <!-- <el-button type="primary" @click="reset">ResetZero</el-button> -->
            <el-button type="primary" style="margin-left: 5px" @click="setBit">Probing</el-button>
            <div>
              <div>
                <span>Work Pos:</span>
                <span>{{ getWorkPosition }}</span>
              </div>
              <div>
                <span>Machine Pos:</span>
                <span>{{ getHomePosition }}</span>
              </div>
            </div>
          </div>
          <div class="demo-image">
            <el-image
              style="width: 100%; height: 100%"
              :src="require('@/assets/carft/p4.png')"
              :fit="fit"
            ></el-image>
          </div>
        </div>
      </div>

      <div style="text-align: center" v-show="active === 2">
        <div class="title">7.Start carving</div>
        <el-button type="primary" @click="goFrame">Frame</el-button>
        <el-button type="primary" @click="startCarving">Run</el-button>
        <div class="demo-image">
          <el-image
            style="width: 300px; height: 200px"
            :src="require('@/assets/carft/p5.png')"
            :fit="fit"
          ></el-image>
        </div>
      </div>
      <!-- <div style="text-align: center">
        <span class="title">6.Tool setting</span>
        <div>
          <div>1.Plug the lead into the carriage.</div>
          <div>2.Attach the clip to the collet.</div>
          <div>3.Touch the plate to the bit to check connectivity.</div>
          <div>4.Place the touch plate on the material, under the bit.</div>
          <el-button type="primary" @click="toolSet">Probing</el-button>
        </div>
      </div> -->
    </div>
    <el-button style="margin-top: 12px" @click="up">Previous</el-button>
    <el-button style="margin-top: 12px" @click="next">Next</el-button>
  </div>
</template>

<script>
// import EventBus from '@/utils/eventBus';
import { bitList } from '@/api/materialbit';
import Debugdialog from '../Debugdialog/index.vue';

export default {
  components: {
    Debugdialog,
  },
  name: 'on-Engrave',
  computed: {
    // 监听 Vuex 中的 socket 状态
    getSocket() {
      console.log(this.$store.state.webSocket.showLaser, '333333333333333333333333');
      return this.$store.state.webSocket.showLaser;
    },
    getWidth() {
      return this.$store.state.material.width;
    },
    getHeight() {
      return this.$store.state.material.height;
    },
    getDept() {
      return this.$store.state.material.dept;
    },
    getFirBitName() {
      console.log(
        this.$store.state.materialBits.firBitName,
        'this.$store.state.materialBits.firBitName;'
      );

      return this.$store.state.materialBits.firBitName;
    },
    getSecBitName() {
      return this.$store.state.materialBits.secBitName;
    },
    getMaschineName() {
      return this.$store.state.machineSize.maschineName;
    },
    getJoin() {
      // console.log(this.$store.state.webSocket.sendJoin, 'getJoingetJoingetJoingetJoin');
      return this.$store.state.webSocket.sendJoin;
    },

    getFirBitDiameter() {
      return this.$store.state.materialBits.firBit;
    },
    getSecBitDiameter() {
      return this.$store.state.materialBits.secBit;
    },
    getFirvAngle() {
      return this.$store.state.materialBits.firvAngle;
    },
    getSecvAngle() {
      return this.$store.state.materialBits.secvAngle;
    },
    getWorkPosition() {
      // console.log(this.$store.state.webSocket.sendCom, 'getComgetComgetComgetCom');
      return this.$store.state.progress.workPosition;
    },
    getHomePosition() {
      // console.log(this.$store.state.webSocket.sendCom, 'getComgetComgetComgetCom');
      return this.$store.state.progress.homePosition;
    },
  },
  watch: {
    // 监听 Vuex 中的刀头数据变化
    getFirBitName: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.form.firBitName = newVal;
        }
      },
    },
    getSecBitName: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.form.secBitName = newVal;
        }
      },
    },
    // 监听材料尺寸变化
    getWidth: {
      immediate: true,
      handler(newVal) {
        this.form.x = newVal;
      },
    },
    getHeight: {
      immediate: true,
      handler(newVal) {
        this.form.y = newVal;
      },
    },
    getDept: {
      immediate: true,
      handler(newVal) {
        this.form.z = newVal;
      },
    },
    getJoin: {
      immediate: true, // 在组件创建时立即执行一次
      handler(newValue) {
        this.connectionStatus = newValue ? 'Connected' : 'Disconnected';
      },
    },
    getFirBitDiameter: {
      immediate: true,
      handler(newVal) {
        console.log(newVal, 'newVal');

        this.firBitDiameter = newVal;
      },
    },
    getSecBitDiameter: {
      immediate: true,
      handler(newVal) {
        console.log(newVal, 'newVal');
        this.secBitDiameter = newVal;
      },
    },
    getFirvAngle: {
      immediate: true,
      handler(newVal) {
        console.log(newVal, 'newVal');

        this.firvAngle = newVal;
      },
    },
    getSecvAngle: {
      immediate: true,
      handler(newVal) {
        console.log(newVal, 'newVal');

        this.secvAngle = newVal;
      },
    },
  },
  data() {
    return {
      url: require('@/assets/showMaterial/Bamboo.jpg'),
      connectionStatus: '',
      firBitDiameter: 0,
      secBitDiameter: 0,
      firvAngle: 0,
      secvAngle: 0,
      maschineName: null,
      active: 0,
      form: {
        x: 0,
        y: 0,
        z: 0,
        firBitName: '',
        secBitName: '',
      },
      maschirWidth: null,
      maschirHeight: null,
      options: [],
    };
  },
  created() {
    console.log(this.getSecBitDiameter, 'this.getSecBitDiameterthis.getSecBitDiameter');
    this.getBitList();
    // // 初始化材料尺寸
    // this.form.x = this.getWidth;
    // this.form.y = this.getHeight;
    // this.form.z = this.getDept;
    // // 初始化刀头选择
    // this.form.firBitName = this.getFirBitName;
    // this.form.secBitName = this.getSecBitName;
    // this.maschineName = this.getMaschineName;
    // this.maschirWidth = this.$store.state.machineSize.maschirWidth;
    // this.maschirHeight = this.$store.state.machineSize.maschirHeight;

    // this.firBitDiameter = this.getFirBitDiameter;
    // this.secBitDiameter = this.getSecBitDiameter;
    // console.log(this.secBitDiameter, 'secBitDiametersecBitDiametersecBitDiametersecBitDiameter');
    // this.firvAngle = this.getFirvAngle;
  },

  mounted() {},
  methods: {
    refreshData() {
      console.log('刷新 Engrave 组件数据');

      // 更新材料尺寸
      this.form.x = this.getWidth;
      this.form.y = this.getHeight;
      this.form.z = this.getDept;

      // 更新刀头信息
      this.form.firBitName = this.getFirBitName;
      this.form.secBitName = this.getSecBitName;
      this.firBitDiameter = this.getFirBitDiameter;
      this.secBitDiameter = this.getSecBitDiameter;
      this.firvAngle = this.getFirvAngle;
      this.secvAngle = this.getSecvAngle;
      console.log(this.secBitDiameter, 'secBitDiametersecBitDiametersecBitDiametersecBitDiameter');

      this.maschirWidth = this.$store.state.machineSize.maschirWidth;
      this.maschirHeight = this.$store.state.machineSize.maschirHeight;
      // 更新机器名称和连接状态
      this.maschineName = this.getMaschineName;
      this.connectionStatus = this.getJoin ? 'Connected' : 'Disconnected';
      // 如果有其他需要更新的数据，也在这里更新

      // 触发视图更新
      this.$forceUpdate();
    },

    next() {
      if (this.active < 2) {
        this.active++;
      }
    },
    up() {
      if (this.active > 0) {
        this.active--;
      }
    },
    // 获取刀头下拉
    getBitList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      bitList(data).then((res) => {
        console.log(res.data, 'data');
        if (res.code === 200) {
          const array = [];
          Object.entries(res.data).forEach(([type, items]) => {
            // 为每个工具添加type属性并推入allBitsData数组
            items.forEach((item) => {
              item.type = type;
              array.push(item);
            });
          });
          console.log(array, 'arrayarrayarray');
          this.options = array.map((item) => ({
            label: item.name,
            vale: item.name,
          }));

          console.log(array, 'array');
        }
      });
    },
    // 巡边
    goFrame() {
      this.action = 'frame';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    // 开始雕刻
    startCarving() {
      this.action = 'carve';
      const userId = localStorage.getItem('id');
      const data = { action: this.action, userId };
      this.getSocket.send(JSON.stringify(data));
      this.$emit('start-carving');
    },
    // 设置原点
    // reset() {
    //   this.action = 'reset';
    //   const data = { action: this.action };
    //   this.getSocket.send(JSON.stringify(data));
    // },
    // 对刀
    setBit() {
      // this.displaySet = true;
    },
    // toolSet() {
    //   this.action = 'adjustedTool';
    //   const data = { action: this.action };
    //   this.getSocket.send(JSON.stringify(data));
    // },
    // 回到原点
    goHome() {
      this.action = 'home';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    hold() {
      this.action = 'hold';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },

    unlock() {
      this.action = 'unlock';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    reset() {
      this.action = 'reset';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    handleMouseDown() {
      console.log(1);
      this.action = 'spindle';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
    handleMouseUp() {
      console.log(2);
      this.action = 'unSpindle';
      const data = { action: this.action };
      this.getSocket.send(JSON.stringify(data));
    },
  },
};
</script>

<style lang="less" scoped>
.content-item {
  height: 350px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 创建三列，每列占据相等的空间 */
  gap: 10px; /* 可选：设置网格项之间的间距 */
}
.el-steps {
  width: 100%;
}
.grid-item {
  background-color: lightblue; /* 设置背景颜色以便可视化 */
  padding: 20px; /* 设置内边距 */
  // text-align: center; /* 居中文本 */
  border: 1px solid #ccc; /* 添加边框以便更清晰地看到边界 */
}
::v-deep.board .el-input__inner {
  width: 100px;
}
.title {
  font-size: 20px;
  margin-bottom: 10px;
  margin-bottom: 10px;
}
::v-deep .el-form-item__label {
  padding: 0;
  text-align: left;
}
.steps2 .el-form-item {
  margin-bottom: 0;
}
.grid-container {
  height: 350px;
}
</style>
