import EventEmitter from 'events';
import { fabric } from 'fabric';
import { v4 as uuid } from 'uuid';

// 对齐辅助线
import initAligningGuidelines from '@/core/initAligningGuidelines';
import initControlsRotate from '@/core/initControlsRotate';
import InitCenterAlign from '@/core/initCenterAlign';
import initHotkeys from '@/core/initHotKeys';
import initControls from '@/core/initControls';

class Editor extends EventEmitter {
  constructor(canvas) {
    super();

    this.canvas = canvas;
    this.editorWorkspace = null;

    initAligningGuidelines(canvas);
    initHotkeys(canvas);
    initControls(canvas);
    initControlsRotate(canvas);
    this.centerAlign = new InitCenterAlign(canvas);
  }

  clone() {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject.length === 0) return;
    activeObject.clone((cloned) => {
      this.canvas.discardActiveObject();
      // 间距设置
      const grid = 10;
      cloned.set({
        left: cloned.left + grid,
        top: cloned.top + grid,
        id: uuid(),
      });
      this.canvas.add(cloned);
      this.canvas.setActiveObject(cloned);
      this.canvas.requestRenderAll();
    });
  }

  // 拆分组
  unGroup() {
    // 先获取当前选中的对象，然后打散
    this.canvas.getActiveObject().toActiveSelection();
    this.canvas
      .getActiveObject()
      .getObjects()
      .forEach((item) => {
        item.set('id', uuid());
      });
    this.canvas.discardActiveObject().renderAll();
  }

  group() {
    const activeObj = this.canvas.getActiveObject();
    if (!activeObj || activeObj.type !== 'activeSelection') return;
    // 保存原始对象和属性
    const originalObjects = activeObj.getObjects().map((obj) => ({
      original: obj,
      state: obj.toObject(['*']), // 包含所有属性
    }));

    // 创建新组时保留原始对象
    const newgroup = new fabric.Group([], {
      id: uuid(),
      subTargetCheck: true,
      interactive: true,
    });

    // 深度克隆并保留属性
    originalObjects.forEach(({ original, state }) => {
      original.clone(
        (cloned) => {
          // 恢复所有属性
          cloned.set({
            ...state,
            // 保持原始变换状态
            left: original.left,
            top: original.top,
            scaleX: original.scaleX,
            scaleY: original.scaleY,
            angle: original.angle,
            skewX: original.skewX,
            skewY: original.skewY,
          });
          if (cloned.stroke === 'blue') {
            cloned.set({
              stroke: null, // 关键修改：清除边框颜色
              strokeWidth: 0,
            });
          }
          newgroup.addWithUpdate(cloned);
        },
        ['dept', 'cutType', 'zIndex', 'isLock', 'pass', 'power', 'speed', 'interval']
      ); // 克隆所有属性
    });

    // 设置组位置
    newgroup
      .set({
        left: activeObj.left,
        top: activeObj.top,
      })
      .setCoords();
    // 移除旧对象
    this.canvas.remove(activeObj);
    originalObjects.forEach(({ original }) => {
      this.canvas.remove(original);
    });

    // 添加新组
    this.canvas.add(newgroup);
    this.canvas.setActiveObject(newgroup);
    this.canvas.requestRenderAll();
  }

  up() {
    const actives = this.canvas.getActiveObjects();
    if (actives && actives.length === 1) {
      const activeObject = this.canvas.getActiveObjects()[0];
      activeObject && activeObject.bringForward();
      this.canvas.renderAll();
      this._workspaceSendToBack();
    }
  }

  upTop() {
    const actives = this.canvas.getActiveObjects();
    if (actives && actives.length === 1) {
      const activeObject = this.canvas.getActiveObjects()[0];
      activeObject && activeObject.bringToFront();
      this.canvas.renderAll();
      this._workspaceSendToBack();
    }
  }

  down() {
    const actives = this.canvas.getActiveObjects();
    if (actives && actives.length === 1) {
      const activeObject = this.canvas.getActiveObjects()[0];
      activeObject && activeObject.sendBackwards();
      this.canvas.renderAll();
      this._workspaceSendToBack();
    }
  }

  downTop() {
    const actives = this.canvas.getActiveObjects();
    if (actives && actives.length === 1) {
      const activeObject = this.canvas.getActiveObjects()[0];
      activeObject && activeObject.sendToBack();
      this.canvas.renderAll();
      this._workspaceSendToBack();
    }
  }

  getWorkspace() {
    const workspace = this.canvas.getObjects().find((item) => item.id === 'workspace');

    return workspace;
  }

  getCoordinate() {
    const coordinate = this.canvas.getObjects().filter((obj) => obj.id === 'coordinate');
    return coordinate;
  }

  _workspaceSendToBack() {
    const workspace = this.getWorkspace();
    const coordinate = this.getCoordinate();
    coordinate.forEach((coordText) => {
      coordText.sendToBack();
    });
    workspace && workspace.sendToBack();
  }

  getJson() {
    return this.canvas.toJSON(['id', 'gradientAngle', 'selectable', 'hasControls']);
  }

  /**
   * @description: 拖拽添加到画布
   * @param {Event} event
   * @param {Object} item
   */
  dragAddItem(event, item) {
    const { left, top } = this.canvas.getSelectionElement().getBoundingClientRect();
    if (event.x < left || event.y < top) return;

    const point = {
      x: event.x - left,
      y: event.y - top,
    };
    const pointerVpt = this.canvas.restorePointerVpt(point);
    item.left = pointerVpt.x - item.width / 2;
    item.top = pointerVpt.y;
    this.canvas.add(item);
    this.canvas.requestRenderAll();
  }
}

export default Editor;
