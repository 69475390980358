<template>
  <div class="container" style="position: relative; height: 800px">
    <el-tabs v-model="activeTab" type="card" @tab-click="tabClick">
      <el-tab-pane label="Bits" name="Bits">
        <el-table :data="bitsData" border style="width: 100%">
          <el-table-column
            v-for="(column, index) in bitsColumns"
            :key="index"
            :prop="column.prop"
            :label="column.label"
            :width="column.width || null"
          >
            <template slot-scope="scope">
              <el-image
                v-if="index === 4 && scope.row[column.prop]"
                :src="scope.row[column.prop]"
                style="max-width: 100%; max-height: 100%"
                fit="contain"
              />
              <!-- 如果不是第五列，则显示默认内容 -->
              <span v-else>
                {{ scope.row[column.prop] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Manage" width="100">
            <template slot-scope="scope" v-if="scope.row.userId !== 1">
              <el-button
                @click="bitHandleClick(scope.row)"
                type="text"
                size="small"
                style="margin-right: 5px"
              >
                Edit
              </el-button>
              <!-- <el-popconfirm title="这是一段内容确定删除吗？"> -->
              <el-popconfirm
                title="Are you sure you want to delete it?"
                icon="el-icon-info"
                iconColor="red"
                @confirm="bitDelet(scope.row)"
              >
                <el-button type="text" size="small" slot="reference">Delete</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Materials" name="Materials">
        <el-table :data="matData" border style="width: 100%">
          <el-table-column
            v-for="(column, index) in matColumns"
            :key="index"
            :prop="column.prop"
            :label="column.label"
            :width="column.width || null"
          >
            <template slot-scope="scope">
              <el-image
                v-if="index === 3 && scope.row[column.prop]"
                :src="scope.row[column.prop]"
                style="max-width: 100%; max-height: 100%"
                fit="contain"
              />
              <!-- 如果不是第五列，则显示默认内容 -->
              <span v-else>
                {{ scope.row[column.prop] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Manage" width="100">
            <template slot-scope="scope" v-if="scope.row.userId !== 1">
              <el-button
                @click="materialHandleClick(scope.row)"
                type="text"
                size="small"
                style="margin-right: 5px"
              >
                Edit
              </el-button>
              <!-- <el-popconfirm title="这是一段内容确定删除吗？"> -->
              <el-popconfirm
                title="Are you sure you want to delete it?"
                icon="el-icon-info"
                iconColor="red"
                @confirm="materDelet(scope.row)"
              >
                <el-button type="text" size="small" slot="reference">Delete</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- <el-tab-pane label="Cut settings" name="Cutsettings">
        <el-table :data="cutData" border style="width: 100%">
          <el-table-column
            v-for="(column, index) in cutColumns"
            :key="index"
            :fixed="column.fixed || null"
            :prop="column.prop"
            :label="column.label"
            :width="column.width || null"
          ></el-table-column>
          <el-table-column fixed="right" label="Manage" width="100">
            <template slot-scope="scope" v-if="scope.row.userId !== 1">
              <el-button
                @click="bitHandleClick(scope.row)"
                type="text"
                size="small"
                style="margin-right: 5px"
              >
                Edit
              </el-button>
              <el-popconfirm
                title="Are you sure you want to delete it?"
                icon="el-icon-info"
                iconColor="red"
                @confirm="materDelet(scope.row)"
              >
                <el-button type="text" size="small" slot="reference">Delete</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane> -->
      <el-tab-pane label="Machines" name="Machines">
        <el-table :data="machinesData" border style="width: 100%">
          <el-table-column
            v-for="(column, index) in machinesColumns"
            :key="index"
            :fixed="column.fixed || null"
            :prop="column.prop"
            :label="column.label"
            :width="column.width || null"
          ></el-table-column>
          <el-table-column fixed="right" label="Manage">
            <template slot-scope="scope">
              <el-button
                :disabled="scope.row.machineType === 'grbl'"
                @click="machineHandleClick(scope.row)"
                type="text"
                size="small"
              >
                Edit
              </el-button>
              <!-- <el-popconfirm title="这是一段内容确定删除吗？"> -->
              <el-popconfirm
                title="Are you sure you want to delete it?"
                icon="el-icon-info"
                iconColor="red"
                @confirm="machineDelet(scope.row)"
              >
                <el-button type="text" size="small" slot="reference" style="margin: 0 5px">
                  Delete
                </el-button>
              </el-popconfirm>
              <el-button
                :disabled="scope.row.isDefault === 1"
                @click="machineSetDefault(scope.row)"
                type="text"
                size="small"
              >
                SetDefault
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <div class="ssss">
      <el-popover
        v-model="bitVisible"
        placement="right"
        :title="'Add ' + activeTab"
        width="10%"
        trigger="click"
        class="bitdiv"
      >
        <!-- 刀头 -->
        <el-form
          ref="bitForm"
          :model="bitForm"
          label-width="90px"
          v-if="activeTab === 'Bits'"
          :rules="bitRules"
        >
          <el-form-item label="Type" prop="type">
            <el-select v-model="bitForm.type" placeholder="Please select type" @change="typeChange">
              <el-option label="End Mills" value="End Mills"></el-option>
              <el-option label="V Bits" value="V Bits"></el-option>
              <el-option label="Laser" value="Laser"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Name" prop="name">
            <el-input v-model="bitForm.name"></el-input>
          </el-form-item>
          <el-form-item
            label="Diameter"
            v-if="bitForm.type === 'End Mills' || bitForm.type === 'V Bits'"
            prop="diameter"
          >
            <el-input v-model.number="bitForm.diameter" :min="0.1" :max="33"></el-input>
          </el-form-item>
          <el-form-item
            label="Angle"
            v-if="bitForm.type === 'End Mills' || bitForm.type === 'V Bits'"
            prop="angle"
          >
            <el-input v-model="bitForm.angle"></el-input>
          </el-form-item>
          <el-form-item label="Power" v-if="bitForm.type === 'Laser'" prop="power">
            <el-input v-model="bitForm.power"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveBit">Save</el-button>
            <el-button @click="cancelBit">Cancel</el-button>
          </el-form-item>
        </el-form>
        <!-- 材料 -->
        <el-form
          ref="materialForm"
          :model="materialForm"
          label-width="110px"
          v-if="activeTab === 'Materials'"
          :rules="bitRules"
        >
          <el-form-item label="Type" prop="type">
            <el-select v-model="materialForm.type" placeholder="Please select type">
              <el-option label="Wood" value="Wood"></el-option>
              <el-option label="Plastic" value="Plastic"></el-option>
              <el-option label="Metal" value="Metal"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Name" prop="name">
            <el-input v-model="materialForm.name"></el-input>
          </el-form-item>
          <el-form-item label="Width(X)" prop="x">
            <InputNumber v-model.number="materialForm.x" :max="maxX" show-input></InputNumber>
          </el-form-item>
          <el-form-item label="Length(Y)" prop="y">
            <InputNumber v-model.number="materialForm.y" :max="maxY" show-input></InputNumber>
          </el-form-item>
          <el-form-item label="Thickness(Z)" prop="z">
            <InputNumber v-model.number="materialForm.z"></InputNumber>
          </el-form-item>
          <el-form-item class="dsds">
            <el-button type="primary" @click="saveMaterial">Save</el-button>
            <el-button @click="cancelBit">Cancel</el-button>
          </el-form-item>
        </el-form>
        <el-form
          ref="machineForm"
          :model="machineForm"
          :rules="bitRules"
          label-width="120px"
          label-position="left"
          v-if="activeTab === 'Machines'"
        >
          <el-form-item label="Name" prop="name">
            <el-input v-model="machineForm.name"></el-input>
          </el-form-item>
          <el-form-item label="MachineType" prop="type">
            <el-select v-model="machineForm.type" placeholder="Select machine type">
              <el-option label="CNC" value="cnc">CNC</el-option>
              <el-option label="Layer" value="layer">Layer</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="X-Distance" prop="x">
            <el-input v-model.number="machineForm.x"></el-input>
          </el-form-item>
          <el-form-item label="Y-Distance" prop="y">
            <el-input v-model.number="machineForm.y"></el-input>
          </el-form-item>
          <el-form-item label="Speed" prop="speed" v-if="machineForm.type === 'cnc'">
            <el-input v-model.number="machineForm.speed"></el-input>
          </el-form-item>
          <el-form-item label="Power" prop="power" v-else>
            <el-input v-model.number="machineForm.power"></el-input>
          </el-form-item>
          <el-form-item class="dsds">
            <el-button type="primary" @click="saveMachine">Save</el-button>
            <el-button @click="cancelBit">Cancel</el-button>
          </el-form-item>
        </el-form>
      </el-popover>
      <el-button type="warning" size="mini" slot="reference" @click="addNew('add')">
        Add&nbsp;{{ activeTab }}
      </el-button>
    </div>
  </div>
</template>

<script>
// import EventBus from '@/utils/eventBus';
import {
  bitList,
  updatebit,
  deletebit,
  materList,
  updateMaterial,
  deleteMaterial,
} from '@/api/materialbit';
import { machineList, updateMachine, deleteMachine, setDefaultMachine } from '@/api/maschine';
import { millimeterToPx } from '@/utils/pixelConversion';
import EditorWorkspace from '@/core/EditorWorkspace';

export default {
  name: 'user-dialog',
  props: {},
  inject: ['canvas', 'fabric'],
  computed: {},
  data() {
    return {
      maxX: 0,
      minY: 0,
      bitVisible: false,
      activeTab: 'Bits',
      // 刀头数据
      bitsColumns: [
        { prop: 'type', label: 'Type', width: '150', fixed: true },
        { prop: 'name', label: 'Bit Name', width: '150', fixed: true },
        { prop: 'diameter', label: 'Diameter', width: '200' },
        { prop: 'angle', label: 'Angle' },
        { prop: 'image', label: 'Image', width: '200' },
      ],
      bitsData: [],

      // 材料数据
      matColumns: [
        { prop: 'type', label: 'Material Type', width: '150', fixed: true },
        { prop: 'name', label: 'Material Name', width: '150', fixed: true },
        { prop: 'dimension', label: 'Size(X , Y , Z)' },
        { prop: 'image', label: 'Image', width: '200' },
      ],
      matData: [],
      // 制作数据
      cutColumns: [
        { prop: 'machine', label: 'Machine', width: '150', fixed: true },
        { prop: 'material', label: 'Material', width: '150', fixed: true },
        { prop: 'bit', label: 'Bit/Laser', width: '200' },
        { prop: 'configuration', label: 'Configuration' },
        { prop: 'note', label: 'Note' },
      ],
      cutData: [
        { machine: '1', material: '刀1', bit: '3', configuration: '60', note: '60' },
        { machine: '1', material: '刀2', bit: '6', configuration: '60', note: '60' },
        { machine: '2', material: '刀3', bit: '8', configuration: '60', note: '60' },
        { machine: '2', material: '刀4', bit: '7', configuration: '60', note: '60' },
      ],
      // 机器数据
      machinesColumns: [
        { prop: 'MachineName', label: 'Machine Name', fixed: true, width: '150' },
        { prop: 'updateTime', label: 'Update Time', fixed: true, width: '250' },
        { prop: 'size', label: 'Size', width: '400' },
        // { prop: 'setting', width: '150', label: 'Save Cut Settings' },
        // { prop: 'hours', width: '150', label: 'Working Hours' },
        // { prop: 'projects', label: 'Cut Projects' },
      ],
      machinesData: [],
      bitRules: {
        type: [{ required: true, message: 'Please select type', trigger: 'change' }],
        name: [{ required: true, message: 'Please enter name', trigger: 'blur' }],
        diameter: [
          { required: true, message: 'Please enter diameter', trigger: 'blur' },
          { type: 'number', message: 'Length must be a number', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value < 0.1 || value > 33) {
                callback(new Error('0.1mm ~ 33mm'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        angle: [{ required: true, message: 'Please enter angle', trigger: 'blur' }],
        power: [{ required: true, message: 'Please input power', trigger: 'blur' }],
        x: [
          { required: true, message: 'Please enter Width', trigger: 'blur' },
          { pattern: /^[+-]?(\d*\.?\d+|\d+\.\d*)$/, message: 'Please enter name', trigger: 'blur' },
        ],
        y: [
          { required: true, message: 'Please enter Length', trigger: 'blur' },
          { pattern: /^[+-]?(\d*\.?\d+|\d+\.\d*)$/, message: 'Please enter name', trigger: 'blur' },
        ],
        z: [
          { required: true, message: 'Please enter Thickness', trigger: 'blur' },
          { pattern: /^[+-]?(\d*\.?\d+|\d+\.\d*)$/, message: 'Please enter name', trigger: 'blur' },
        ],
        speed: [{ required: true, message: 'Please input power', trigger: 'blur' }],
      },

      bitForm: {
        name: '',
        type: '',
        power: 0,
        diameter: 0,
        angle: '',
        id: null,
      },
      materialForm: {
        name: '',
        type: '',
        x: 0,
        y: 0,
        z: 0,
        id: null,
      },
      machineForm: {
        name: '',
        type: '',
        x: null,
        y: null,
        speed: null,
        power: null,
      },
      show: false,
      controls: '',
    };
  },
  watch: {},
  created() {
    this.getBitList();
    this.getMaterList();
    this.getMachineList();
  },
  mounted() {},
  methods: {
    tabClick() {
      this.bitVisible = false;
      // console.log(tab.label, 'eee');
    },
    // getImagePath(name) {
    //   // console.log(name, 'name');

    //   // 如果图片名与name相同，并且扩展名为.png，可以这样构造路径：
    //   return require(`@/assets/images/${name}.png`);
    // },
    // objectSpanMethod({ rowIndex, columnIndex }) {
    //   if (columnIndex === 0) {
    //     if (rowIndex % 2 === 0) {
    //       return {
    //         rowspan: 2,
    //         colspan: 1,
    //       };
    //     }
    //     return {
    //       rowspan: 0,
    //       colspan: 0,
    //     };
    //   }
    // },
    // 获取刀头列表
    getBitList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      const images = {
        '1/4 in Ball Cutter': require('@/assets/Bits/1 4 in Ball Cutter.jpg'),
        '1/4 in Flat Cutter': require('@/assets/Bits/1 4 in Flat Cutter.jpg'),
        '1/8 in Ball Cutter': require('@/assets/Bits/1 8 in Ball Cutter.jpg'),
        '1/8 in Flat Cutter': require('@/assets/Bits/1 8 in Flat Cutter.jpg'),
        '30 Deg 1/8 V-Bit': require('@/assets/Bits/30 Deg 1 8 V-Bit.jpg'),
        '60 Deg 1/8 V-Bit': require('@/assets/Bits/60 Deg 1 8 V-Bit.jpg'),
        'End Mills': require('@/assets/Bits/End Mills.jpg'),
        'V Bits': require('@/assets/Bits/V Bit.jpg'),
      };
      bitList(data).then((res) => {
        // console.log(res.data, 'data');

        if (res.code === 200) {
          const array = [];
          Object.entries(res.data).forEach(([type, items]) => {
            // 为每个工具添加type属性并推入allBitsData数组
            items.forEach((item) => {
              item.type = type;
              array.push(item);
            });
          });
          this.bitsData = array.map((item) => {
            const standardizedName = item.name;
            // 判断是否是 'End Mills' 类型，并且 name 不匹配预定义图片路径中的任何一个
            let imagePath;
            if (item.type === 'End Mills' && !(standardizedName in images)) {
              imagePath = images['End Mills'];
            } else if (item.type === 'V Bits' && !(standardizedName in images)) {
              imagePath = images['V Bits'];
            } else {
              imagePath = images[standardizedName] || null;
            }

            return {
              type: item.type,
              name: item.name,
              diameter: item.info.diameter,
              angle: item.info.angle,
              id: item.id,
              userId: item.userId,
              image: imagePath, // 添加图片路径到返回的对象中
            };
          });
          // this.bitsData = res.data.map;
        }
      });
    },
    // 获取材料
    getMaterList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      const images = {
        Bamboo: require('@/assets/Material/Bamboo.jpg'),
        Beech: require('@/assets/Material/Beech.jpg'),
        Cardboard: require('@/assets/Material/Cardboard.jpg'),
        Ceramics: require('@/assets/Material/Ceramics.jpg'),
        Cotton: require('@/assets/Material/Cotton.jpg'),
        Denim: require('@/assets/Material/Denim.jpg'),
        KraftPaper: require('@/assets/Material/KraftPaper.jpg'),
        Leather: require('@/assets/Material/Leather.jpg'),
        MDF: require('@/assets/Material/MDF.jpg'),
        Oak: require('@/assets/Material/Oak.jpg'),
        Paulownia: require('@/assets/Material/Paulownia.jpg'),
        Peach: require('@/assets/Material/Peach.jpg'),
        Pine: require('@/assets/Material/Pine.jpg'),
        Plywood: require('@/assets/Material/Plywood.jpg'),
        PVC: require('@/assets/Material/PVC.jpg'),
        Rosewood: require('@/assets/Material/Rosewood.jpg'),
      };
      materList(data).then((res) => {
        if (res.code === 200) {
          this.matData = res.data.map((item) => ({
            // 获取图片路径，如果 item.name 对应的键存在于 images 中则使用它，否则设置为 null 或者其他默认值。
            id: item.id,
            dimension: Object.entries(JSON.parse(item.dimension.replace(/'/g, '"')))
              .map(([key, value]) => `${key}:${value}`)
              .join(','),
            name: item.name,
            type: item.type,
            userId: item.userId,
            image: images[item.name] || null, // 添加图片路径到返回的对象中
          }));

          // console.log(this.matData, 'this.matData');
        }

        // console.log(res, 'ressssss');
      });
    },
    typeChange(e) {
      // console.log(e, 'ee');
      this.resetForm();
      this.bitForm.type = e;
    },
    addNew(e) {
      if (this.activeTab === 'Machines') {
        this.$router.push({ path: '/newmaschine' });
      } else {
        this.resetForm();
        this.bitVisible = true;
        this.controls = e;
      }
    },
    // 新增编辑刀头
    saveBit() {
      this.$refs.bitForm.validate((valid) => {
        if (valid) {
          const data = {
            id: this.bitForm.id,
            userId: localStorage.getItem('id'),
            name: this.bitForm.name,
            type: this.bitForm.type,
            info: {
              power: this.bitForm.power,
              diameter: this.bitForm.diameter,
              angle: this.bitForm.angle,
            },
          };
          updatebit(data).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
              this.getBitList();
              this.bitVisible = false;

              if (this.controls === 'add') {
                this.resetForm();
              }
            }
            // console.log(res, 'Res');
          });
        } else {
          return false;
        }
      });
    },
    cancelBit() {
      this.bitVisible = false;
    },
    // 编辑刀头
    bitHandleClick(e) {
      this.bitVisible = true;
      this.controls = 'edit';
      this.bitForm.type = e.type;
      this.bitForm.id = e.id;
      this.bitForm = { ...e };
      // console.log(e, 'eee');
    },
    // 删除刀头
    bitDelet(item) {
      const data = [item.id];
      deletebit(data).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getBitList();
        }
      });
    },
    // 清空bitform
    resetForm() {
      // 重置表单字段为默认值
      this.bitForm = {
        id: null,
        name: '',
        type: '',
        power: '',
        diameter: '',
        angle: '',
      };
      this.materialForm = {
        name: '',
        type: '',
        x: '',
        y: '',
        z: '',
        id: null,
      };
    },
    // 新增编辑材料
    saveMaterial() {
      this.$refs.materialForm.validate((valid) => {
        if (valid) {
          const dimension = JSON.stringify({
            x: this.materialForm.x,
            y: this.materialForm.y,
            z: this.materialForm.z,
          });
          const data = {
            id: this.materialForm.id,
            userId: localStorage.getItem('id'),
            name: this.materialForm.name,
            type: this.materialForm.type,
            dimension: dimension.replace(/"/g, "'"),
          };
          updateMaterial(data).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
              this.bitVisible = false;

              this.getMaterList();
              if (this.controls === 'add') {
                this.resetForm();
              }
            }
            // console.log(res, 'Res');
          });
        } else {
          return false;
        }
      });
    },
    // 编辑材料
    materialHandleClick(e) {
      const dimensions = Object.fromEntries(e.dimension.split(',').map((pair) => pair.split(':')));
      this.bitVisible = true;
      this.controls = 'edit';
      this.materialForm.id = e.id;
      this.materialForm.name = e.name;
      this.materialForm.type = e.type;
      this.materialForm.x = dimensions.x;
      this.materialForm.y = dimensions.y;
      this.materialForm.z = dimensions.z;
    },
    // 删除材料
    materDelet(item) {
      const data = [item.id];
      deleteMaterial(data).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getMaterList();
        }
      });
    },
    // 获取设备列表
    getMachineList() {
      const data = {
        userId: localStorage.getItem('id'),
      };
      machineList(data).then((res) => {
        // { machine: '1', added: '刀1', size: '3', setting: '60', hours: '60', projects: '60' },
        if (res.code === 200) {
          this.machinesData = res.data.map((item) => ({
            MachineName: item.machineName,
            size: Object.entries(JSON.parse(item.extInfo.replace(/'/g, '"')))
              .map(([key, value]) => `${key}:${value}`)
              .join(','),
            id: item.id,
            userId: item.userId,
            isDefault: item.isDefault,
            updateTime: item.updateTime,
            machineType: item.machineType,
          }));
        }
        const defaultMachine = this.machinesData.find((item) => item.isDefault === 1);
        const a = Object.fromEntries(defaultMachine.size.split(',').map((pair) => pair.split(':')));
        this.maxX = Number(a.x);
        this.maxY = Number(a.y);
      });
    },
    // 编辑机器
    machineHandleClick(e) {
      // console.log(e, 'eeee');
      const dimensions = Object.fromEntries(e.size.split(',').map((pair) => pair.split(':')));
      // console.log(dimensions, 'dimensionsdimensions');
      this.bitVisible = true;
      this.controls = 'edit';
      this.machineForm.id = e.id;
      this.machineForm.name = e.MachineName;
      this.machineForm.type = e.machineType;
      this.machineForm.x = dimensions.x;
      this.machineForm.y = dimensions.y;
      this.machineForm.power = dimensions.power;
      this.machineForm.speed = dimensions.speed;
    },
    saveMachine() {
      this.$refs.machineForm.validate((valid) => {
        if (valid) {
          const data = {
            id: this.machineForm.id,
            userId: localStorage.getItem('id'),
            machineName: this.machineForm.name,
            machineType: this.machineForm.type,
          };
          if (this.machineForm.type === 'cnc') {
            data.extInfo = JSON.stringify({
              x: this.machineForm.x,
              y: this.machineForm.y,
              speed: this.machineForm.speed,
            });
          } else {
            data.extInfo = JSON.stringify({
              x: this.machineForm.x,
              y: this.machineForm.y,
              power: this.machineForm.power,
            });
          }
          updateMachine(data).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message);
              this.getMachineList();
              this.bitVisible = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 删除机器
    machineDelet(item) {
      const data = [item.id];
      deleteMachine(data).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getMachineList();
        }
      });
    },
    // Set Default
    machineSetDefault(item) {
      // console.log(item, 'item');
      const obj = item.size.split(',').reduce((acc, pair) => {
        const [key, value] = pair.split(':');
        acc[key] = Number(value); // 直接转换为数字
        return acc;
      }, {});
      // console.log(obj.x, 'obj.xobj.x');
      // console.log(obj.y, 'obj.yyy.y');
      this.$store.dispatch('machineSize/setMaschirWidth', obj.x);
      this.$store.dispatch('machineSize/setMaschirHeight', obj.y);
      this.$store.dispatch('material/setWidth', obj.x);
      this.$store.dispatch('material/setHeight', obj.y);
      // localStorage.setItem('maschirWidth', obj.x);
      // localStorage.setItem('maschirHeight', obj.y);
      const data = {
        id: item.id,
        userId: item.userId,
      };
      setDefaultMachine(data).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          const objects = this.canvas.c
            .getObjects()
            .filter((e) => e.id === 'workspace' || e.id === 'coordinate');
          // 移除所有找到的对象
          objects.forEach((object) => {
            this.canvas.c.remove(object);
          });
          const x = this.$store.state.machineSize.maschirWidth;
          const y = this.$store.state.machineSize.maschirHeight;
          this.canvas.editor.editorWorkspace = new EditorWorkspace(this.canvas.c, {
            machineWidth: millimeterToPx(x),
            machineHeight: millimeterToPx(y),
          });
          this.getMachineList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ssss {
  position: absolute;
  right: 8px;
  top: 10px;
  color: #e6a23c;
  font-weight: 600;
  font-size: 14px;
}
::v-deep .el-dialog__body {
  padding-top: 10px;
}
::v-deep .is-active {
  background: #488cc5;
  color: white !important;
}
::v-deep .el-dialog {
  height: 72%;
  margin-top: 5vh !important;
}
::v-deep .el-dialog__body {
  height: calc(100% - 60px); /* 假设顶部有60px高的标题栏 */
  display: flex;
  flex-direction: column;
}
::v-deep .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
::v-deep .el-tabs__content {
  flex: 1;
  overflow-y: auto; /* 当内容超出时显示滚动条 */
  height: 100%;
}
::v-deep .projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
}
::v-deep .el-card {
  flex: 0 0 22.5%;
  box-sizing: border-box;
  margin: 10px;
  height: 220px;
}
::v-deep .bottom {
  display: flex;
  justify-content: space-between;
}
::v-deep .bitdiv .el-popper {
  width: 300px;
  top: 0;
  left: 104px;
}
::v-deep .dsds .el-form-item__content {
  margin-left: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
